import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { withTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "./index.scss";

const ResetPassword = ({ onSubmit, isResetPasswordSuccessful, response, t }) => {
    const forgotPasswordSchema = yup.object().shape({
        password: yup.string()
            .required()
            .min("8", t("AUTH.REGISTER.LENGTH_ERR"))
            .max("128", t("AUTH.REGISTER.LENGTH_ERR"))
            .matches(/^(?=.*[!@#$%^&*])/, t("AUTH.REGISTER.SPECIAL_CHAR_ERR"))
            .matches(/^(?=.*[a-z])/, t("AUTH.REGISTER.LOWER_CASE_ERR"))
            .matches(/^(?=.*[A-Z])/, t("AUTH.REGISTER.UPPER_CASE_ERR"))
            .matches(/^(?=.*[0-9])/, t("AUTH.REGISTER.NUMERIC_ERR")),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
        token: yup.string().required()
    });

    const {register: resetPassword, handleSubmit, errors} = useForm({
        resolver: yupResolver(forgotPasswordSchema)
    });

    const { token } = useParams();

    return (
        <form id={"auth-form"} className={'auth-form'} onSubmit={handleSubmit(onSubmit)}>
            <img src="/images/diversio-logo.png" className="logo" alt="Diversio Global"/>
            
            <TextField
                className={"connect-form__text-field"}
                inputRef={resetPassword}
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ''}
                name={'password'}
                label="Password"
                margin="normal"
                variant="outlined"
                type={"password"}
            />

            <TextField
                className={"connect-form__text-field"}
                inputRef={resetPassword}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                name={'confirmPassword'}
                label="Confirm Password"
                margin="normal"
                variant="outlined"
                type={"password"}
            />

            <TextField
                className={"connect-form__text-field hidden"}
                inputRef={resetPassword}
                error={!!errors.token}
                helperText={errors.token ? errors.token.message : ''}
                name={'token'}
                label="Token"
                margin="normal"
                variant="outlined"
                type={"password"}
                value={token}
            />

            <Button className={"login__btn"} type={"submit"} variant="contained" color="primary">
                {t("AUTH.FORGOT_PASSWORD.RESET_PASSWORD")}
            </Button>

            <div className="forgot-password-button text-center">
                {
                    isResetPasswordSuccessful === true
                    && <div className={"success-message" }>
                        <Trans i18nKey={`AUTH.RESET_PASSWORD.${response.data.message}`} components={{anchor: <Link />}}></Trans>
                    </div>
                }
                {
                    isResetPasswordSuccessful === false
                    && <div className={"error-message"}>
                        <Trans i18nKey={`AUTH.RESET_PASSWORD.${response.error.message}`} components={{anchor: <Link />}}></Trans>
                    </div>
                }
            </div>

        </form>
    );
};

export default withTranslation()(ResetPassword);
