import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import {Trans, withTranslation} from "react-i18next";
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';
import {
    getOfficeRegionLabel,
} from "../../../../utils/labels";
import _ from 'underscore';
import moment from "moment";
import { paginate } from '../Pagination';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import './index.scss';

const INSUFFICIENT_DATA = ["INSUFFICIENT_DATA", "N/A"];
const HeatmapSnapshot = ({ data, prevData, title, overall, previousOverall, companyName, t }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageData, setPageData] = useState(null);
    const pageSize = 8;

    const transformTitle = (title) => {
        if (!title) { 
            return null; 
        }

        if (title.toLowerCase() === "office") {
            return getOfficeRegionLabel(companyName)
        }

        else return mapT(m.FILTER_LABELS, title)
    }

    const handlePageChange = (delta) => {
        if (delta === 1) {
            if ((currentPage + 1) <= totalPages) {
                setCurrentPage(currentPage + 1);
            }
        }
        else if (delta === -1) {
            if ((currentPage - 1) > 0) {
                setCurrentPage(currentPage - 1);
            }
        }
    }

    const getPageData = () => {
        const paginationData = paginate(data, currentPage, pageSize);
        setTotalPages(Math.ceil(data.length / pageSize)); 
        setPageData(paginationData);
    }

    useEffect(() => {
        getPageData();
    }, [data, currentPage]);
    

    if (title === "overview") {
        if (_.isEmpty(overall)) {
            return null;
        }

        const companyDiff = overall.company - previousOverall.company;
        const industryDiff = overall.company - overall.industry;

        return (
            <>
                <Row className={"heatmap-snapshot"}>
                    <Col xs={3} className={"heatmap-snapshot__titles"}>
                        <h6 className={"highlight"}>{t("ANALYZE.INCLUSION_BREAKDOWN.INCLUSION_SCORE")}</h6>
                        <h6 className={"title"}>{transformTitle(title)}</h6>
                    </Col>
                    <Col xs={5} className={"heatmap-snapshot__scores"}>
                        <Row>
                            <Col xs={12} className={'heatmap-snapshot__overview-container'}>
                                <Col xs={6} className={"flex-centered"}>
                                    <div className={'heatmap-snapshot__score'}>
                                        {
                                            _.isEmpty(overall) || _.contains(INSUFFICIENT_DATA, overall.company)
                                                ? <span className={'insufficient-data'}></span>
                                                : <h5 className={'heatmap-snapshot__score--current'}>{ overall.company }</h5>
                                        }
                                        <p>{ companyName }</p>
                                    </div>
                                </Col>
                                <Col xs={6} className={"flex-centered"}>
                                    <div className={'heatmap-snapshot__score'}>
                                        {
                                            _.isEmpty(overall) || _.contains(INSUFFICIENT_DATA, overall.industry)
                                                ? <span className={'insufficient-data'}></span>
                                                : <h5 className={'heatmap-snapshot__score--current'}>{ overall.industry }</h5>
                                        }
                                        <p>{t("ANALYZE.INCLUSION_BREAKDOWN.INDUSTRY_AVERAGE")}</p>
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4} className={"flex-centered heatmap-snapshot__diff"}>
                        {
                            companyDiff ? (companyDiff !== 0
                                ? <p className={companyDiff >= 0 ? `higher` : `lower`}>
                                    <Trans i18nKey={'ANALYZE.INCLUSION_BREAKDOWN.SCORE_DIFF'} components={{
                                        span: <span />,
                                        score: Math.abs(companyDiff).toFixed ? Math.abs(companyDiff).toFixed(2) : "-",
                                        tick: companyDiff >= 0 ? `higher` : `lower`,
                                        date: moment(previousOverall.date).format("YYYY-MM-DD")
                                    }} />
                                </p>
                                : <p>
                                    {t("ANALYZE.INCLUSION_BREAKDOWN.SCORE_NO_CHANGE", {
                                        date: previousOverall.date
                                    })}
                                </p>)
                                : null
                        }
                        {
                            industryDiff ? (industryDiff !== 0
                                ? <p className={industryDiff >= 0 ? `higher` : `lower`}>
                                    <Trans i18nKey={'ANALYZE.INCLUSION_BREAKDOWN.INDUSTRY_DIFF'} components={{
                                        span: <span />,
                                        score: Math.abs(industryDiff).toFixed ? Math.abs(industryDiff).toFixed(2) : "-",
                                        tick: industryDiff >= 0 ? `higher` : `lower`
                                    }} />
                                </p>
                                : <p>{t("ANALYZE.INCLUSION_BREAKDOWN.INDUSTRY_NO_CHANGE")}</p>)
                                : null
                        }
                    </Col>
                </Row>
                <hr/>
            </>
        )
    }
    return (
        <>
        <Row className={"heatmap-snapshot"}>
            <Col xs={3} className={"heatmap-snapshot__titles"}>
                <h6 className={"highlight"}>Inclusion Score</h6>
                <h6 className={"title"}>{
                        transformTitle(title)
                    }</h6>
            </Col>
            <Col>
                <Row>
                <Col className={"heatmap-snapshot__prev-page"} xs={1}>
                    <div className={`heatmap-snapshot__next-page-icon ${
                            totalPages > 1 ? '' : 'heatmap-snapshot__icon-hidden'}`
                        }>
                        <NavigateBeforeIcon className={`${currentPage === 1 ? "heatmap-snapshot__icon-disabled" : null}`} fontSize="large" onClick={() => handlePageChange(-1)}/>
                    </div>
                </Col>
                <Col xs={9} className={"heatmap-snapshot__scores"}>
                    <Row>
                    {
                        pageData.map((metric, i) => {
                            if (!metric.overall_score) { return null; }
                            const scoreContainer = <Col xs={3} className={'heatmap-snapshot__score-container'}>
                                <p>{ metric.title }</p>
                                <div className={'heatmap-snapshot__score'}>
                                    {
                                        _.isEmpty(metric) || _.contains(INSUFFICIENT_DATA, metric.overall_score)
                                            ? <span className={'insufficient-data'}></span>
                                            : <h5 className={'heatmap-snapshot__score--current'}>{ metric.overall_score }</h5>
                                    }
                                    {
                                        (_.isEmpty(prevData) || _.isEmpty(prevData[i])) || _.contains(INSUFFICIENT_DATA, prevData[i].overall_score)
                                            ? null
                                            : <h5 className={'heatmap-snapshot__score--previous'}>{`(${ prevData[i].overall_score })`}</h5>
                                    }
                                </div>
                            </Col>
                            if (i > 0 && !(i % 4)) {
                                return [<hr/>, scoreContainer]
                            } else {
                                return scoreContainer
                            }
                        })
                    }
                    </Row>
                    </Col>
                    <Col className={"heatmap-snapshot__next-page"} xs={1}>
                        <div className={`heatmap-snapshot__next-page-icon ${
                            totalPages > 1 ? '' : 'heatmap-snapshot__icon-hidden'}`
                        }>
                            <NavigateNextIcon 
                            className={`${currentPage === totalPages ? "heatmap-snapshot__icon-disabled" : null}`}
                            fontSize="large" onClick={() => handlePageChange(1)}/>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <hr/>
        </>
    );
};

export default withTranslation()(HeatmapSnapshot);
