import React from 'react';
import DOMPurify from 'dompurify';
import './index.scss';

const ExecutiveSummary = ( { data } ) => {
    

    if (!data || !data.summary_text) {
        return null;
    }
    const sanitizedHTML = DOMPurify.sanitize(data.summary_text)

    return (
        <div className="executive-summary">
            <div className="executive-summary__logo-title-col">
                <h1 className="executive-summary__title">Executive Summary</h1>
                <div className="executive-summary__logo">
                    {data.logo_url && <img src={data.logo_url} className="executive-summary__logo-img"/>}
                </div>
            </div>
            <div className="executive-summary__text-col"
            dangerouslySetInnerHTML={{__html: sanitizedHTML}}
            />
        </div>

    );

};

export default ExecutiveSummary;
