import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Actions } from '../actions';
import { connect } from 'react-redux';
import NavBar from '../components/NavBar';
import './App.scss';
import Loader from "../components/utils/Loader";
import DashboardRoutes from '../dashboardRoutes';
import TopNavBar from "../components/TopNavBar";
import IdleTimer from 'react-idle-timer';

import _ from "underscore";
import mixpanel from "mixpanel-browser";
import { safeMixpanelTrack } from '../components/Analytics/utils/utils';

const INACTIVE_EXPIRE_TIME = 15; // minutes
const TOKEN_REFRESH_TIME = 0.5; // minutes
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
    }

    componentWillMount () {
        if (this.props.isAuthenticated) {
            this.props.getCompanyInformation();
        }
    }

    componentDidUpdate (prevProps) {

        // Email takes some time to come through on props via redux;
        // Check that it's been updated and do Mixpanel identity setup

        const { loggedinUserId, username, email } = this.props;

        if (email !== prevProps.email) {

            if(!_.isEmpty(email)) {
                // prevent crash if env var is not set and mixpanel did not initialize
                if (mixpanel?.people && !mixpanel.people.$email) { 
    
                    mixpanel.identify(loggedinUserId);
                    mixpanel.people.set({
                    "$name": username, $email: email});
                }
            }

        }
    }

    handleOnAction () {
        const { accessToken, refreshToken } = this.props;
        this.props.doRefreshToken({ accessToken, refreshToken });
    }

    handleOnIdle () {
        this.props.doSignout();
    }

    render() {
        const { company_permission: permissions = null } = this.props.companyInformation;

        if (!this.props.isAuthenticated) {
            return <Redirect to={"/login"}></Redirect>
        }

        if (!permissions) {
            return <Loader />;
        }

        return (
            <div className="navBar">
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * INACTIVE_EXPIRE_TIME}
                    onIdle={this.handleOnIdle.bind(this)}
                    onAction={this.handleOnAction.bind(this)}
                    throttle={1000 * 60 * TOKEN_REFRESH_TIME}
                />
                <TopNavBar reportInaccuracy={this.props.doReportInaccuracy}
                           resetInaccuracy={this.props.resetInaccuracyState}
                           isInaccuracySubmitting={this.props.analytics.isInaccuracySubmitting}
                           isInaccuracySubmitted={this.props.analytics.isInaccuracySubmitted}
                           isILN={permissions.iln_dashboard_home}/>
                <NavBar { ...this.props }/>
                <DashboardRoutes permissions={permissions}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.auth,
        ...state.home,
        profile: state.profile,
        analytics: state.analytics
    }
};

export default connect(mapStateToProps, Actions)(Dashboard);
