import React from 'react';
import {Column, Row} from "../utils/FlexBox";
import HeatMap from "./HeatMap";
import InclusionBreakdown from "./InclusionBreakdown";
import CompareSegments from "./CompareSegments";
import InclusionTracker from "./InclusionTracker";
import Feedback from "./Feedback";
import { isMember, HIDE_FREETEXT_COMPANY_NAMES } from "../../utils/companyChecks";
import TimeSeriesGate from "../utils/TimeSeriesGate";
import './index.scss'

const Inclusion = (props) => {
    return (
        <div>
            <Row>
                <Column width="100%">
                    <HeatMap data={props.analyze.inclusionMetrics}
                             prevData={props.analyze.previousInclusionMetrics}
                             overall={props.analyze.overall}
                             previousOverall={props.analyze.previousOverall}
                             companyName={props.home.companyInformation.name}
                             getInclusionMetricsData={props.getInclusionMetricsData}
                             getNonOverviewMetricsData={props.getNonOverviewMetricsData}
                             allFilters={props.analyze.filters}
                             selected={props.selected}
                             selectTab={props.select}
                             metadata={props.analyze.metadata}
                    />
                </Column>
            </Row>
            <Row>
                <Column grow={1}>
                    <InclusionBreakdown data={props.analyze.inclusionBreakdown}
                                        companyName={props.home.companyInformation.name}
                                        groups={props.extendedCategories} filters={props.analyze.filters}
                                        handleApplyFilters={data => props.getInclusionBreakdownData({ data })}
                                        colors={props.colors}
                                        showMessage={props.showMessage}/>
                </Column>
            </Row>
            <TimeSeriesGate series={props.analyze.inclusionTracker}>
                <Row>
                <Column grow={1}>
                    <InclusionTracker groups={props.extendedCategories}
                                      metrics={props.analyze.inclusionTracker}
                                      kpimap={props.kpimap}
                                      filters={props.analyze.filters}
                                      handleApplyFilters={data => props.getInclusionTrackerData({ data })}
                                      allFilters={props.allFilters}
                                      companyName={props.home.companyInformation.name}/>
                    </Column>
                </Row>
            </TimeSeriesGate>
            <Row>
                { isMember(HIDE_FREETEXT_COMPANY_NAMES, props.home.companyInformation.name)
                ? null
                : <Feedback colors={props.colors}
                          freeText={props.analyze.freeText}
                          companyName={props.home.companyInformation.name}/> }
            </Row>
        </div>
    );
};

export default Inclusion;
