import React, { useState } from 'react';
import Progress from "./Progress";
import { withTranslation } from 'react-i18next';
import {Button, Row, Col, Nav} from "react-bootstrap";
import Card from "../utils/Card";
import classNames from "classnames";
import {copyToClipboard} from "../../utils";

const Live = ({ savedSurveys, t }) => {
    const lastSavedSurvey = savedSurveys[0];
    const [language, setLanguage] = useState("en");
    if (!lastSavedSurvey) { return null; }
    const { surveys } = lastSavedSurvey;

    return (
        <div className={"survey-live"}>
            <Progress selected={"live"}/>

            <div id={"survey-live-content"}>
                <Row>
                    <Col xs={8}>
                        <Card>
                            <Row>
                            <Col xs={6}>
                                <h5>Your Survey is Live!</h5>
                                <p>For maximum engagement from employees, we recommend sending out reminders to complete the survey every 4 days. The average duration for a survey is 3 weeks.</p>
                                <p>Send your employees your link to get started. If you created survey in multiple languages, choose the language you want to send to your employees.</p>
                            </Col>
                            <Col xs={6}>
                                <p>When you are ready to close your survey and generate results please let us know by clicking the "Close Survey" button.</p>
                                <Nav variant="tabs" defaultActiveKey={language}>
                                    {
                                        surveys.map(survey => {
                                            return <Nav.Item>
                                                <Nav.Link onClick={() => setLanguage(survey.supported_language)} eventKey={ survey.supported_language }>{t(`SURVEY.${survey.supported_language.toUpperCase()}`)}</Nav.Link>
                                            </Nav.Item>
                                        })
                                    }
                                </Nav>
                                {
                                    surveys.map(survey => {
                                        return <div className={classNames("copy-link-container", {"hidden": survey.supported_language !== language})}>
                                                <input className={"collector-link"} type="text" value={survey.collector_link}/>
                                                <div className={"copy"} onClick={e => {
                                                    copyToClipboard(e.target.previousSibling);
                                                    const target = e.target;
                                                    target.firstChild.innerText = "Copied";
                                                    setTimeout(() => {
                                                        target.firstChild.innerText = "Copy";
                                                    }, 5000);
                                                }}>
                                                    <p>Copy</p>
                                                </div>
                                            </div>
                                    })
                                }

                            </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={4}>
                        <Card className={"submitted-response-card"}>
                            <h5>Submitted Responses</h5>
                            <p>Updated January 7, 2021</p>
                            <div className={"responses-highlight"}>
                                22
                            </div>
                        </Card>
                    </Col>
                </Row>

            </div>
        </div>
    );
};

export default withTranslation()(Live);
