import React, { useEffect, useState } from 'react';
import './index.scss';

import { withTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';

import * as m from '../../../translations/mapping';
import { mapT } from '../../../translations/utils';
import { _makeCell, _getSideHeaderClass } from '../../Home/HeatMap/utils';

import classNames from 'classnames';
import { DashboardTooltip } from '../../utils/DashboardTooltip';
import { industryOrRegional, mapChoiceLabel, mapMetaKpiLabels, } from '../../../utils/labels';
import Legend from "./Legend";

import {Bar, BarChart, ReferenceLine, CartesianGrid, LabelList, Cell, ResponsiveContainer, XAxis, YAxis, Text} from "recharts";
import _ from 'underscore';
import mixpanel from "mixpanel-browser";
import { safeMixpanelTrack } from '../utils/utils';

const COLOR1 = "#6221EA";
const COLOR2 = "#D0BEF7";
const COLOR3 = "#f6f6f6";
const NA_DASH = "#b1abbd"
const BAR_LABEL = "#322352";


const reshapePainPointData = (data, iData, painPointMap) => {
    const reshaped_list = []

    for (const ppData of Object.keys(painPointMap)) {
        const obj = {
            "challenge": painPointMap[ppData].string,
            "kpi": painPointMap[ppData].kpi,
            "company": data[ppData],
            "industry": iData[ppData],
        }
        reshaped_list.push(obj);
    }

    return reshaped_list;
}

const getPath = (x, y, width, height) => 
    `M${x},${y + height}
          L${x }, ${y}
          L${x + width}, ${y}
          L${x + width}, ${y + height}
          Z`;

export const ConditionalRectangleBar = ({ value, fill, x, y, width, height, payload, dataKey, ...rest }) => {
    // Annoyingly complex, but necessary due to
    // https://github.com/recharts/recharts/issues/1265

    let widthToSet = 0;
    let xToSet = x;

    let stroke = "none";
    let strokeDasharray = null;

    if (["company_na", "industry_na"].includes(dataKey)) {
        stroke = NA_DASH;
        strokeDasharray = "4 1";
    }
    return (
        <>
        <path d={getPath(xToSet, y, width, height)} stroke={stroke} strokeDasharray={strokeDasharray} fill={fill} />
        </>
        );
  };

const getFinalMax = (_dataMax) => {
    const dataMax = parseFloat(_dataMax);

        if (dataMax >= 0 && dataMax < 5) { return 5 };
        if (dataMax >= 5 && dataMax < 10) { return 10};
        if (dataMax >= 10 && dataMax < 15) {return 15};
        if (dataMax >= 15 && dataMax < 20) { return 20};
        if (dataMax >= 20 && dataMax < 30) { return 30};
        if (dataMax >= 30 && dataMax < 40) { return 40};
        if (dataMax >= 40 && dataMax < 50) { return 50};
        if (dataMax >= 50 && dataMax < 60) { return 60};
        if (dataMax >= 60 && dataMax < 75) { return 75};
            
        return 100;
}

const ChallengeBarChart = ({ data, activeKpi, ...props}) => {

    if (!data) {
        return null;
    }

    // get active KPI only, fill in dummy data for nulls
    let activeData = data.filter(d => {return d.kpi === activeKpi}).map(d => 
        {
        let filled = d;
        if (!d.company) {
            filled = {...filled, "company_na": 5}
        }
        if (!d.industry) {
            filled = {...filled, "industry_na": 5}
        }
        return filled;
    })

    // find max for domain
    let max = 0;
    for (const d of activeData) {
        if (d.company) {
            max = (parseFloat(d.company) > parseFloat(max)) ? parseFloat(d.company) : parseFloat(max);
        }
        if (d.industry) {
            max = (parseFloat(d.industry) > parseFloat(max)) ? parseFloat(d.industry) : parseFloat(max);
        }
    }

    // const finalMax = getFinalMax(max);
    const finalMax = 10;

 return (
    <div>
    <ResponsiveContainer height={300} width="100%">
        <BarChart barGap={24} barCategoryGap={24} data={activeData}>
            <CartesianGrid stroke="#DBD9DB" vertical={false} />
            <XAxis
                dataKey="challenge"
                xAxisId={0}
                 height={40}
                 interval={0}
                 fontSize={11}
                 fontFamily="Lato"
                 tickSize={0}
                 axisLine={true}
                 tickLine={true}
                 tickMargin={15}/>
            <XAxis
                dataKey="challenge"
                xAxisId={1}
                 hide/>
             <YAxis 
             width={30}
            tickCount={6} 
            domain={[0, finalMax]}
            fontSize={11}
            fontFamily="Lato"
            orientation='left'
            tick={{fill: "#939EAB"}}
            axisLine={false}
            tickLine={false}
            tickMargin={5}
            padding={{ top: 40 }}

             />
            <Bar xAxisId={0} dataKey="company" fill={COLOR2}
            isAnimationActive={false} 
            >
                    {data.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLOR2} />
                    ))}
                    <LabelList fill={BAR_LABEL} dataKey="company" position="top" content={(c) => `${c.value}`}/>
            </Bar>
            <Bar xAxisId={1} dataKey="company_na" fill={COLOR3}
            isAnimationActive={false} 
                >
                    {data.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLOR3} 
                        stroke={NA_DASH}
                        strokeDasharray="4 1"

                        />
                    ))}
            </Bar>
            <ReferenceLine y={5} label={null} stroke={COLOR1} strokeDasharray="4 3" strokeWidth="1.5" />
            
         </BarChart>
     </ResponsiveContainer>
 </div>
 );
}

const Frequency = ( { selectedKey, kpis, painPoints, iPainPoints, painPointScores, kpiMap, companyName, t, ...props} ) => {
    const [ activeKpiIndex, setActiveKpiIndex ] = useState(0);

    if (_.isEmpty(kpis)) {
        return null;
    }

    let painPointData;
    if ((!_.isEmpty(painPointScores) && !_.isEmpty(iPainPoints)) && !_.isEmpty(kpiMap)) {
        painPointData = reshapePainPointData(painPointScores, iPainPoints, kpiMap);
    } else {
        return null;
    }

    const handleKpiClick = (index) => {

        safeMixpanelTrack("Set pain point KPI", {
            "kpi": kpiIndexMap[index]
        })

        setActiveKpiIndex(index);
    }
    
    // heatmap
    const metadata = [
        {"name":"Inclusive culture"},
        {"name":"Unbiased feedback & reviews"},
        {"name":"Access to networks"},
        {"name":"Flexible working options"},
        {"name":"Safe work environment"},
        {"name":"Recruiting & Hiring"}
    ];

    // temp, dummy input
    let data = 
        {"inclusive_culture_average":7.6,
        "review_feedback_average":7.2,
        "access_to_network_average":6.9,
        "flexibility_weight_average":7.5,
        "safety_average":8.6,
        "recruiting_and_hiring_average":7.3,
        "title":"Your Company"
    };

    if (kpis) {
        data["inclusive_culture_average"] = kpis.inclusive || null;
        data["review_feedback_average"] = kpis.management || null;
        data["access_to_network_average"] = kpis.career || null;
        data["flexibility_weight_average"] = kpis.work_life || null;
        data["safety_average"] = kpis.safe || null;
        data["recruiting_and_hiring_average"] = kpis.recruiting || null;
        data["title"] = companyName || null;
    }

    const prevData = null;

    /* Table columns in desired order of appearance */
    const COLUMN_KEYS = [
    'inclusive_culture_average',
    'review_feedback_average',
    'access_to_network_average',
    'flexibility_weight_average',
    'safety_average',
    'recruiting_and_hiring_average'
    ];

    // TEMP to filter data
    const kpiIndexMap = {
        0: "Inclusive Culture",
        1: "Fair Management",
        2: "Career Development",
        3: "Work-Life Balance",
        4: "Safe Work Environment",
        5: "Recruiting & Hiring"
    }
        
        let heatmapMetricData = [ null, null ];

        if (data) {
            /* Contains strengths and weaknesses */
            heatmapMetricData = [
                null, // industry
                data  // your score
            ];
        }

        let prevMetricData = [ null, null ];
        
        if (prevData) {
            prevMetricData = [
                prevData[0],
                prevData[1]
            ]
        }

        let table;
        if (!data) {
            table = null;
        } else {
            table = (
                <Table bordered className="home-heat-map analytics__heat_map">
                    <thead>
                        <tr>
                            <th width="16%" className="analytics__header"/>
                            {
                                metadata.map((meta, index) => 
                                <th
                                key={index}
                                onClick={() => { handleKpiClick(index) }}
                                width="14%"
                                 className={`
                                 home-heat-map__header 
                                 home-heat-map__cell 
                                 analytics__clickable_header
                                 ${index === activeKpiIndex ? `analytics__active_kpi` : null }`} key={index}>
                                        <span className="analytics__clickable_header">
                                            {mapT(m.METRIC, mapMetaKpiLabels(companyName, meta.name))}
                                        </span>
                                    
                                    </th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {heatmapMetricData.map((metric, id) => {
                        return !metric ? null : (
                            <tr key={id}>
                                <td className={classNames(["home-heat-map__cell", "home-heat-map__side_header"])}>
                                    
                                        <span>
                                        {mapT(m.HEATMAP_SIDEBAR, 
                                        // TODO hook this up
                                        mapChoiceLabel(industryOrRegional(metric.title, "Opencare")))}
                                        </span>
                                    
                                </td>
                                {COLUMN_KEYS.map(key => (_makeCell(metric, prevMetricData[id], key, id, true, "analytics__cell")))}
                            </tr>
                        )
                    })}
                        </tbody>
                </Table>
            );
        }
    
    
    // TODO: dynamic chart click
    return (
        <div className="org-div__wrapper">
            <div className="analytics__table_wrapper">
                {table}
            </div>

            <div className="org-div__charts_wrapper">
                <div className="org-div__chart">
                    <ChallengeBarChart activeKpi={kpiIndexMap[activeKpiIndex]} data={painPointData} />
                </div>
            </div>

            <div className="analytics__legend_wrapper">
                <Legend 
                // TODO: don't display legend if chart not displayed
                visible={!_.isEmpty(painPointData)} 
                activeCompanyName={selectedKey}/>
            </div>
        </div>

        
    );
}

export default Frequency;
