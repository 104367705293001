import React from 'react';
import { withTranslation } from 'react-i18next';
import './index.scss';

const Legend = ({t, activeCompanyName, ...props}) => {

    return (
        <div className="analyticslegend">
            {/* <div className="analyticslegend__company">{activeCompanyName}</div> */}
            {/* <div className="analyticslegend__industry-average">Industry Average</div> */}
            <div className="analyticslegend__na">{t("ANALYTICS.DATA_NA")}</div>
        </div>
    );
}

export default withTranslation()(Legend);
