import React, { useRef, useState } from 'react';
import {Navbar, Nav, Modal, Button} from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import './index.scss';
import ReportInaccuracy from "./ReportInaccuracy";

const TopNavBar = ({ isInaccuracySubmitted, isInaccuracySubmitting, reportInaccuracy, resetInaccuracy }) => {
    const [ showReportDialog, setShowReportDialog ] = useState(false);

    return (
        <div className={"bootstrap-override"} id={"topbar-container"}>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand as={Link} to={"/home"}>
                    <img className="icon icon-diversio-logo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <a className="report-inaccuracy" onClick={() => setShowReportDialog(true)}><img src="/images/exclamation.svg" alt="Report"/>Report Inaccuracy</a>
                </Navbar.Collapse>
            </Navbar>
            <Modal className="report-inaccuracy-modal"
                   show={showReportDialog}
                   animation={false}
                   onHide={() => setShowReportDialog(false)}>
                <Modal.Body>
                    <ReportInaccuracy reportInaccuracy={reportInaccuracy}
                                      resetInaccuracy={resetInaccuracy}
                                      handleClose={() => setShowReportDialog(false)}
                                      isInaccuracySubmitted={isInaccuracySubmitted}
                                      isInaccuracySubmitting={isInaccuracySubmitting}/>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default withTranslation()(TopNavBar);
