import React, { Component } from 'react';
import './index.scss';
import { Row, Column } from '../../utils/FlexBox';
import FeedbackCard from './FeedbackCard';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import { getTranslation } from '../../../translations/utils';
import MainCard from "../../utils/MainCard";
import { Bar, BarChart, CartesianGrid, LabelList, Cell, ResponsiveContainer, XAxis, YAxis, Text } from "recharts";
import { kpiFormatter } from '../../../utils';
import { isPortfolioCompany } from "../../../utils/companyChecks";

const groups = {
    "gender": ["Men", "Women"],
    "ethnicity": ["White", "Ethnic minority"],
    "sexual_orientation": ["Non LGBTQ+", "LGBTQ+"],
    "disability": ["No mental health challenge", "Mental health challenge"],
}

class CustomizedAxisTick extends Component {
    render() {
        const { x, y, payload, groups } = this.props;
        const spacing = 32;
        return (
            <g transform={`translate(${x},${y})`}>
                <Text
                    style={
                        {
                            fontSize: 12,
                            fill: '#939EAB',
                        }
                    }
                    width={60}
                    x={0}
                    y={0}
                    dx={-spacing}
                    dy={4}
                    verticalAnchor="start"
                    textAnchor="middle"
                >
                    {groups[payload.value][0]}
                </Text>
                <Text
                    style={
                        {
                            fontSize: 12,
                            fill: '#939EAB',
                        }
                    }
                    width={60}
                    x={0}
                    y={0}
                    dx={spacing}
                    dy={4}
                    verticalAnchor="start"
                    textAnchor="middle"
                >
                    {groups[payload.value][1]}
                </Text>
            </g>
        );
    }
}


class Feedback extends Component {
    state = {
        category: 'All'
    };

    categoryToButton(category) {
        return (
            <button
                key={category}
                className={`employees-feedback__categories__category-button ${this.state.category === category ? 'selected' : ''}`}
                onClick={() => { this.setState({ category }) }}
            >
                {category}
            </button>
        )
    }

    feedbackToCard({ text, title, translated_text }, index) {
        return (
            <FeedbackCard key={index} label={title} feedback={translated_text ? translated_text : text} />
        )
    }

    fillMissingTitles(freetext) {
        if (freetext.title === undefined || freetext.title === '') {
            freetext.title = freetext.type;
        }
        return freetext
    }

    getPpDesc(category) {
        if (category === 'All') {
            return null;
        }
        return this.props.freeText[category]['description'];
    }

    getPpKpi(category) {
        if (category === 'All') {
            return null;
        }

        const kpiValue = this.props.freeText[category]['kpi'];
        return kpiFormatter(kpiValue);
    }

    getTitle = () => {
        const t = this.props.t;
        if (isPortfolioCompany(this.props.companyName)) {
            return t("ANALYZE.CARD_HEADERS.EMPLOYEE_FEEDBACK.title_p");
        }

        return getTranslation("ANALYZE.CARD_HEADERS.EMPLOYEE_FEEDBACK.title", this.props.companyName);
    }

    getNlpSubtitle = () => {
        const t = this.props.t;
        if (isPortfolioCompany(this.props.companyName)) {
            return t("ANALYZE.FEEDBACK.NLP_SUBTITLE_p")
        }

        return getTranslation("ANALYZE.FEEDBACK.NLP_SUBTITLE", this.props.companyName);
    }

    formatBarGraphData = (freeText, category) => {
        // Commit that has the logic for displaying "All" in the barchart can be found here
        // https://github.com/DiversioTeam/Diversio-Frontend/blob/22abe4f1543d64feb70fbb67bbe1cae94e940aa5/src/components/Analyze/Feedback/index.js#L142
        if (_.isEmpty(freeText)) {
            return [];
        }

        let freeTextData
        let result = []
        const percent = (value) => {
            return parseFloat(value).toFixed(2) + "%"
        }
        if (category !== 'All') {
            freeTextData = freeText[category] && freeText[category]['demographics']

            for (var i in freeTextData) {
                let demographicA, demographicB, demoPercentA, demoPercentB;
                const demographics = freeTextData[i]['demographics']

                for (var j in demographics) {
                    if (j.toLowerCase() === 'prefer not to answer') continue
                    demographicA ? demographicB = demographics[j] : demographicA = demographics[j]
                    demoPercentA ? demoPercentB= percent(demographics[j]) : demoPercentA = percent(demographics[j])
                }

                let obj = {
                    name: freeTextData[i]['dimension'],
                    demographic_a: demographicA,
                    demographic_b: demographicB,
                    demo_percent_a: demoPercentA,
                    demo_percent_b: demoPercentB
                }
                result.push(obj)
            }
        }
        return result
    }

    render() {
        const { t } = this.props;
        let colorIndex = 0;
        let secondaryColorIndex = 0;
        const colors = [
            "#6221EA",
            "#0725E8",
            "#FF2455",
            "#FF8A00"
        ]
        const secondaryColors = [
            "#CFBCF8",
            "#109CF1",
            "#FEBECD",
            "#FBC02D"
        ]
        const { category } = this.state;
        const freeText = this.props.freeText;

        if (_.isEmpty(freeText)) {
            return null;
        }

        const freeTextCategories = Object.keys(freeText).sort((a, b) => {
            if (freeText[a]['free_texts'].length > freeText[b]['free_texts'].length) {
                return -1;
            }
            return 1;
        });

        const categories = ['All', ...freeTextCategories];
        const categoryButtons = categories.map(this.categoryToButton.bind(this));

        const allFeedback = [];

        for (const cat of freeTextCategories) {
            allFeedback.push(...freeText[cat]['free_texts'])
        }

        let activeFeedback;

        if (category === 'All') {
            activeFeedback = allFeedback;
        } else {
            activeFeedback = freeText[category]['free_texts'];
        }

        const feedbackCards = activeFeedback.map(this.feedbackToCard.bind(this));

        const barGraphData = this.formatBarGraphData(freeText, category)
        const showGraph = barGraphData.length > 0
        const isAllCategory = category === 'All'
        return (
            <MainCard title={this.getTitle()}
                toolTipContent={t("ANALYZE.CARD_HEADERS.EMPLOYEE_FEEDBACK.tooltip")}>

                <div id="feedback" className={"employees-feedback"}>
                    <h2 className="employees-feedback__subtitle">{this.getNlpSubtitle()}</h2>
                    <div className="employees-feedback__container">
                        <Row>
                            <Column width="100%">
                                <h3 className={"employees-feedback__painpoint-label"}>{t("ANALYZE.FEEDBACK.TOP_PAINPOINTS_LABEL")}</h3>
                            </Column>
                        </Row>
                        <div className="employees-feedback__categories">
                            {categoryButtons}
                        </div>
                        <hr />
                        <h2 className="employees-feedback__subtitle">{this.getPpDesc(category)}</h2>
                        {showGraph && !isAllCategory && <div className="employees-feedback__barchart">
                            <div className="employees-feedback__barchart_header">
                                <span className={"employees-feedback__barchart__label"}>{t("ANALYZE.FEEDBACK.PAINPOINT_BY_DEMOGRAPHIC")} <span>{category}</span></span>
                                <span className={"employees-feedback__barchart__label employees-feedback__barchart__kpi"}>{
                                    this.getPpKpi(category) ?
                                        (<>Relevant KPI: <span>{this.getPpKpi(category)}</span></>)
                                        : null
                                }
                                </span>
                            </div>
                            <div className={"employees-feedback__barchart__container"}>
                                <ResponsiveContainer height={300} width="100%">
                                    <BarChart data={barGraphData} barSize={45} barGap={20}>
                                        <CartesianGrid stroke="#DBD9DB" vertical={false} />
                                        <XAxis
                                            height={80}
                                            interval={0}
                                            fontSize={11}
                                            fontFamily="Lato"
                                            tick={
                                                <CustomizedAxisTick
                                                    activeIndex={null}
                                                    groups={groups}
                                                />
                                            }
                                            dataKey="name"
                                            tickSize={0}
                                            axisLine={false}
                                            tickLine={false}
                                            tickMargin={15} />
                                        <YAxis tickCount={7} fontSize={11} fontFamily="Lato" orientation='right' tick={{ fill: "#939EAB" }} axisLine={false} tickLine={false} tickMargin={5} />
                                        <Bar dataKey="demographic_a" >
                                            {
                                                barGraphData.map((entry, index) => (
                                                    <Cell key={`cell-${index}`}
                                                        fill={colors[colorIndex++]} />
                                                ))
                                            }
                                            <LabelList dataKey="demo_percent_a" position="top" />
                                        </Bar>
                                        <Bar dataKey="demographic_b" >
                                            {
                                                barGraphData.map((entry, index) => (
                                                    <Cell key={`cell-${index}`}
                                                        fill={secondaryColors[secondaryColorIndex++]} />
                                                ))
                                            }

                                            <LabelList dataKey="demo_percent_b" position="top" />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>}
                        {showGraph && !isAllCategory && <hr />}
                        <div className="employees-feedback__comments">
                            {/*<p className={"employees-feedback__comments__label"}>{t("ANALYZE.FEEDBACK.SAMPLE_COMMENTS")} <span>{category}</span></p>*/}
                            <div className={"employees-feedback__comments__container"}>
                                {feedbackCards}
                            </div>
                        </div>
                    </div>
                </div>
            </MainCard>
        );
    }
}

export default withTranslation()(Feedback);
