export const demographicMap = {
    "Men": {
        "gender": ['Male'],
        "ethnicity": [],
        "sexual_orientation": [],
        "disability": []
    },
    "Women+": {
        "gender": ['Female', 'Intersex', 'Two-Spirit'],
        "ethnicity": [],
        "sexual_orientation": [],
        "disability": []
    },
    "White": {
        "ethnicity": ['White'],
        "gender": [],
        "sexual_orientation": [],
        "disability": []
    },
    "P.O.C.": {
        "ethnicity": ['Asian', 'Black', 'Indigenous', 'Latin / Hispanic', 'Middle Eastern', 'Jewish'],
        "gender": [],
        "sexual_orientation": [],
        "disability": []
    },
    "Person with Disability": {
        "disability": ['Yes'],
        "gender": [],
        "ethnicity": [],
        "sexual_orientation": []
    },
    "Person without Disability": {
        "disability": ['No'],
        "gender": [],
        "ethnicity": [],
        "sexual_orientation": []
    },
    "Heterosexual": {
        "sexual_orientation": ['Heterosexual'],
        "gender": [],
        "ethnicity": [],
        "disability": []
    },
    "LGBTQ+": {
        "sexual_orientation": ['Gay', 'Lesbian', 'Asexual', 'Bisexual', 'Pansexual', 'Queer'],
        "gender": [],
        "ethnicity": [],
        "disability": []
    },
    "All":{
        "sexual_orientation": [],
        "gender": [],
        "ethnicity": [],
        "disability": []
    }
}

export function demographicToName(demographic) {
    if (!demographic) {
        return null
    }
    if(demographic.name && demographic.name !== ''){
        return demographic.name;
    }

    const name = Object.keys(demographicMap).find(demographicName => {
        const d = demographicMap[demographicName];
        return (
            arrayEquals(demographic.gender, d.gender) &&
            arrayEquals(demographic.ethnicity, d.ethnicity) &&
            arrayEquals(demographic.sexual_orientation, d.sexual_orientation) &&
            arrayEquals(demographic.disability, d.disability)
        )
    });

    if (name === undefined) {
        return demographicToString(demographic)
    }

    return name
}


function demographicToString({gender, sexual_orientation, ethnicity, disability}) {
    return [
        genderToString(gender),
        sexualOrientationToString(sexual_orientation),
        ethnicityToString(ethnicity),
        disabilityToString(disability)
    ].filter(x=>x!=='').join(" | ")
}


function genderToString(genders) {
    return genders.filter(x => x !== "Prefer not to answer").join(", ")
}

function sexualOrientationToString(sexualOrientations) {
    return sexualOrientations.filter(x => x !== "Prefer not to answer").join(", ")
}

function ethnicityToString(ethnicities) {
    return ethnicities.filter(x => x !== "Prefer not to answer").join(", ")
}

function disabilityToString(disabilities) {
    if (disabilities === ["Yes"]) {
        return "Person with Disability"
    } else if (disabilities === ['No']) {
        return ''
    }
    return ''
}

function arrayEquals(a, b) {
    if (a.length !== b.length) {
        return false
    }

    for(let i =0; i<a.length; i++){
        if(!b.includes(a[i])){
            return false
        }
    }
    return true
}
