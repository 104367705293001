import React, {Component} from 'react';
import {Dropdown} from "react-bootstrap";
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import './navbar.scss';
import {urlMap} from "../../dashboardRoutes";
import history from "../../history";
import {nameToInitials} from "../../utils";

const linkPropsMap = {
    home: {path: urlMap.home, icon: 'home', label: 'NAVBAR.HOME'},
    analyze: {path: urlMap.analyze, icon: 'analyze', label: 'NAVBAR.ANALYZE'},
    analytics: {path: urlMap.analytics, icon: 'analytics', label: 'NAVBAR.ANALYTICS'},
    improve: {path: urlMap.improve, icon: 'improve', label: 'NAVBAR.IMPROVE'},
    diversity_vc: {path: urlMap.diversity_vc, icon: 'improve', label: 'NAVBAR.VCDNISTANDARD'},
    connect: {path: urlMap.connect, icon: 'connect', label: 'NAVBAR.CONNECT'},
    lso: {path: urlMap.lso, icon: 'home', label: 'NAVBAR.HOME'},
    voyager: {path: urlMap.voyager, icon: 'communication', label: 'NAVBAR.COMMUNICATION'},
    survey: {path: urlMap.survey, icon: 'survey', label: 'NAVBAR.SURVEY'},
    iln_dashboard_home: {path: urlMap.iln_dashboard_home, icon: 'home', label: 'NAVBAR.HOME'},
    iln_dashboard_impact: {path: urlMap.iln_dashboard_impact, icon: 'iln-impact', label: 'NAVBAR.IMPACT'}
};

class NavBar extends Component {
    constructor(props) {
        super(props);
    }

    _permissionToListItem(permission) {
        const path = window.location.pathname;
        const props = linkPropsMap[permission];
        const selected = props.path === '/' ? props.path === path : (path.indexOf(props.path) > -1);
        const isDiversity = props.path===urlMap.diversity_vc;
        const ilnArr = ['/iln-dashboard/home', '/iln-dashboard/impact']
        const isILN = ilnArr.includes(props.path);
        const {t} = this.props;

        return (
            <li key={permission} className={isILN ? classNames({"selected": selected, "iln-dashboard": isILN}) : classNames({"selected": selected,[props.path.slice(1)]:isDiversity})}>
                <Link to={props.path}>
                    <img className={classNames("icon", `icon-${props.icon}`)}/>
                    <span>{t(props.label)}</span>
                </Link>
            </li>
        )
    }

    _adminLink() {
        return (
            <li key={'admin'} className={classNames(window.location.pathname.indexOf('/admin') > -1 && "selected")}>
                <Link to="/admin/upload">Admin</Link>
                <ul>
                    <li>
                        <Link to="/admin/upload"
                              className={classNames(window.location.pathname === '/admin/upload' && "selected")}>
                            Upload CSV
                        </Link>
                    </li>
                </ul>
            </li>
        )
    }

    _onSignout = (props) => {
        props.doClearCompanyInformation()
        return props.doSignout()
    }

    render() {
        const permissions = this.props.companyInformation.company_permission;
        if (permissions === undefined) {
            return null;
        }
        const links = [
            ...Object.keys(linkPropsMap).filter(x => permissions[x]).map(this._permissionToListItem.bind(this)),
            this.props.isAdmin ? this._adminLink.call(this) : null,
        ];
        const {t, profile} = this.props;

        const nameInitials = nameToInitials(profile['full_name']);
        const isILN = permissions.iln_dashboard_home
        return (
            <div className="NavBar bootstrap-override" id="navbar">
                <ul className="navbar_list">
                    {links}
                </ul>

                <div className={isILN ? "navbar_profile-container iln-border" : "navbar_profile-container"}>
                    <Dropdown>
                        <Dropdown.Toggle as={Link}>
                            <img className="icon icon-three-dots"/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu flip={false}>
                            {/* <Dropdown.Item as={Link} className={classNames({ selected: window.location.pathname === '/account/profile' })} to="/account/profile">{t("NAVBAR.EDIT_PROFILE")}</Dropdown.Item> */}
                            <Dropdown.Item as={Link} className={classNames({ selected: window.location.pathname === '/account/password' })} to="/account/password">{t("NAVBAR.EDIT_PASSWORD")}</Dropdown.Item>
                            {/* <Dropdown.Item as={Link} className={classNames({ selected: window.location.pathname === '/account/tokens' })} to="/account/tokens">{t("NAVBAR.TOKENS")}</Dropdown.Item> */}
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => this._onSignout(this.props)}>{t("NAVBAR.SIGNOUT")}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {nameInitials
                    ? <div className={"profile-initials"}>{nameInitials}</div>
                    : null}
                </div>
            </div>
        );
    }
}

export default withTranslation()(NavBar);
