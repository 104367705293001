import {parseNumber} from "../../../utils";
import classNames from "classnames";
import React from "react";

/* Strength / weakness indicator at right */
function _getSWColor (metric, key) {
    if (metric.strengths && metric.strengths.indexOf(key) > -1) {
        return 'green'
    } else if (metric.weaknesses && metric.weaknesses.indexOf(key) > -1) {
        return 'red'
    }
}

/* Gray dash in center if data not present */
function _getColor (metric, key) {
    if (['N/A', 'INSUFFICIENT_DATA'].indexOf(metric[key]) > -1) {
        return 'gray'
    }
}

function _getRowClass (id) {
    return (id === 1) ? "home-heat-map__your_kpi" : null;
}

function _getSideHeaderClass (id) {
    return (id === 1) ? "home-heat-map__your_kpi_side_header" : null;
}

function _formatPrevNumber(metric, key) {
    const parsed = (metric && metric[key]) ? parseNumber(metric[key], 1) : null;

    if (parsed) {
        return `(${parsed})`;
    }
    return null;
}

function _makeCell (metric, prevMetric, key, id, disableMyKpi=false, extraClass=null) {
    /*
    metric: score data
    prevMetric: previous period data
    key: average type (i.e. inclusive_culture_average)
    id: index from map()
    disableMyKpi: if true, disables purple highlight on line 2 (index 1),
    extraClass: string. extra class to inject at td level
    */

    return (
        
            <td key={`${key}_${id}_inner`} className={classNames([
                _getSWColor(metric, key),
                `home-heat-map__cell ${extraClass || null}`])}>
                <div className="home-heat-map__cell_container">
                    <div className="home-heat-map__number">
                        <div className="home-heat-map__cell_side" />
                        <div className={classNames([
                            "home-heat-map__cell_main",
                            _getColor(metric, key)
                        ])}>
                        <span className={classNames(
                            [
                                disableMyKpi ? 
                                null:
                                _getRowClass(id)])}>{parseNumber(metric[key], 1)}</span>
                        </div>
                        <div className={classNames(_getSWColor(metric, key),
                            "home-heat-map__cell_side")} />
                    </div>

                    {(prevMetric && (
                        <div className="home-heat-map__prev_number">
                            <div className="home-heat-map__cell_side" />
                            <div className="home-heat-map__cell_main">
                            <span>{_formatPrevNumber(
                                prevMetric, key)}</span>
                            </div>
                            <div className="home-heat-map__cell_side" />
                        </div>
                    ))}
                </div>
            </td>
    );
}

export {
    _makeCell,
    _getSideHeaderClass
};
