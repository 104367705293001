import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import {Redirect, Route} from 'react-router-dom';
import AllPost from './AllPost';
import MyPost from './MyPost';
import Bookmark from './Bookmark';
import Topics from './Topics';
import Discover from './Discover';
import Answer from './Answer';
import Resources from './Resources';
import Experts from './Experts';
import Pilots from './Pilots';
import Improve from './Improve';
import Discussion from './DiscussionContainer';
import Profile from '../components/Connect/Profile';
import Activity from '../components/Connect/Activity';
import Trending from '../components/Connect/Trending';
import Filter from '../components/Connect/Filter';
import CreatePost from '../components/Connect/CreatePost';
import SpecificPost from '../components/Connect/SpecificPost';

class Connect extends Component {
    componentWillMount () {
        // this.props.getProfile();
        this.props.getAllPosts();
        this.props.getBookmark();
        this.props.getCompanyInformation();
        this.props.getAllKpis();
    }

    // componentDidUpdate(prevProps) {
    //     let updatedPost = {};
    //     if (prevProps.posts !== this.props.posts) {
    //         prevProps.posts.map((post, id) => {
    //             if (post.title !== this.props.posts[id].title ||
    //                 post.description !== this.props.posts[id].description ||
    //                 post.kpi.name !== this.props.posts[id].kpi.name) {
    //                     updatedPost = this.props.posts[id];
    //                 }
    //         })
    //     }
    // }

    _updateProfile (data) {
        this.props.doUpdateProfile(data);
    }

    _uploadProfileImage (data) {
        this.props.doUploadProfileImage(data);
    }

    _createPost (data) {
        this.props.doCreatePost(data);
    }

    _editPost (data) {
        this.props.doEditPost(data);
    }

    _deletePost (data) {
        this.props.doDeletePost(data);
    }

    _likePost (data) {
        this.props.doLikePost(data);
    }

    _getComments(data) {
        this.props.getComments(data);
    }

    _createComment (data) {
        this.props.doCreateComment(data);
    }

    _editComment (data) {
        this.props.doEditComment(data);
    }

    _deleteComment (data) {
        this.props.doDeleteComment(data);
    }

    _likeComment (data) {
        this.props.doLikeComment(data);
    }

    _updateBookmark (data) {
        this.props.doUpdateBookmark(data);
    }

    mapKPI = (kpi) => {
        if (kpi === 1) {
            return 'Culture';
        } else if (kpi === 2) {
            return 'Feedback';
        } else if (kpi === 3) {
            return 'Networks';
        } else if (kpi === 4) {
            return 'Flexible Work';
        } else if (kpi === 5) {
            return 'Safe Work';
        } else if (kpi === 6) {
            return 'Recruiting & Hiring';
        } else if (kpi === 0) {
            return 'All'
        }
        return null
    } 

    _handleFilter (kpi) {
        let filteredPosts = [];
        const kpiName = this.mapKPI(kpi);
        
        if (kpiName === 'All') {
            filteredPosts = this.props.posts;
        } else {
            this.props.posts.filter(post => {
                if (post.kpi.name === kpiName) {
                    filteredPosts.push(post)
                }
            })
        }
        
        this.props.getFilteredPosts({
            filteredPosts: filteredPosts,
            isFiltered: true
        })
    }

    render() {
        return (
            <div id={window.location.pathname === "/connect/all-post" ? "all-post-container" : "personal-post-container"}>
                <div className="profile-container">
                    <Profile handleUpdateProfile={this._updateProfile.bind(this)} 
                             profileInfo={this.props.profile}
                             handleUploadProfileImage={this._uploadProfileImage.bind(this)} />
                </div>
                
                <div className="filter-container">
                    <Filter handleFilter={this._handleFilter.bind(this)}
                            filteredPosts={this.props.filteredPosts} />
                </div>
                <div className="create-post-container">
                    <CreatePost handleCreatePost={this._createPost.bind(this)} profileName={this.props.profile.full_name} kpis={this.props.kpis} />
                </div>
                
                <div className="post-container">
                    <Route exact path='/connect/all-post' render={
                        (props) => 
                            <AllPost {...props} 
                                allPosts={this.props.posts} 
                                profileInfo={this.props.profile}
                                handleCreatePost={this._createPost.bind(this)} 
                                handleLikePost={this._likePost.bind(this)}
                                handleEditPost={this._editPost.bind(this)} 
                                handleDeletePost={this._deletePost.bind(this)} 
                                bookmark={this.props.bookmark}
                                handleUpdateBookmark={this._updateBookmark.bind(this)}
                                isFiltered={this.props.isFiltered}
                                filteredPosts={this.props.filteredPosts}
                                profileName={this.props.profile.full_name}
                                kpis={this.props.kpis}
                            /> 
                    }/>
                </div>

                <div className="personal-container">
                    <Route exact path='/connect/my-post' render={
                        (props) => 
                            <MyPost {...props} 
                                myPosts={this.props.posts} 
                                profileInfo={this.props.profile}
                                handleEditPost={this._editPost.bind(this)}
                                handleDeletePost={this._deletePost.bind(this)} 
                                handleLikePost={this._likePost.bind(this)}
                                bookmark={this.props.bookmark}
                                handleUpdateBookmark={this._updateBookmark.bind(this)}
                                kpis={this.props.kpis}
                            /> 
                    }/> 

                    <Route exact path='/connect/bookmark' render={
                        (props) =>
                            <Bookmark {...props}
                                handleEditPost={this._editPost.bind(this)}
                                handleDeletePost={this._deletePost.bind(this)}
                                handleLikePost={this._likePost.bind(this)}
                                handleUpdateBookmark={this._updateBookmark.bind(this)}
                                bookmark={this.props.bookmark}
                                profileInfo={this.props.profile}
                                kpis={this.props.kpis}
                            />
                    }/>

                    <Route path='/connect/post-id' render={
                        (props) => 
                            <SpecificPost {...props}
                                post={this.props.posts}
                                specificPost={this.props.location.state.post}
                                updatedPost={this.updatedPost}
                                profile={this.props.profile}
                                handleGetComments={this._getComments.bind(this)}
                                handleCreateComment={this._createComment.bind(this)} 
                                handleEditPost={this._editPost.bind(this)}
                                handleDeletePost={this._deletePost.bind(this)}
                                handleLikePost={this._likePost.bind(this)}
                                handleLikeComment={this._likeComment.bind(this)}
                                handleEditComment={this._editComment.bind(this)}
                                handleDeleteComment={this._deleteComment.bind(this)}
                                bookmark={this.props.bookmark}
                                handleUpdateBookmark={this._updateBookmark.bind(this)}
                                kpis={this.props.kpis}
                            />
                    }/>
                </div>
    
                <div className="activity-container">
                    <Activity />
                </div>
                
                {/* <div className="trending-container">
                    <Trending />
                </div> */}

                <Route exact path={'/connect'} component={()=> <Redirect to={{pathname:'/connect/all-post'}}/>}/>
    
                {/* <Route exact path='/connect/topics' component={ Topics }/>
                <Route exact path='/connect/topics/discover' component={ Discover }/>
                <Route exact path='/connect/topics/answer' component={ Answer }/>
                <Route exact path='/connect/pilots' component={ Pilots }/>
                <Route exact path='/connect/resources' component={ Resources }/>
                <Route exact path='/connect/experts' component={ Experts }/>
                <Route exact path='/connect/discussion' component={ Discussion }/> */}
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        ...state.connect,
        profile: state.profile
    };
};

export default connect(mapStateToProps, Actions)(Connect);
