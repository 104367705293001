import { ActionTypes } from '../actions';

const defaultState = {
    currentSelected: "init",
    clonedSurveys: [],
    coreSurveys: [],
    savedSurveys: [],
    isLoaded: false
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case 'TEST':
                return defaultState;

            case ActionTypes.DOING_SAVE_SURVEY_INFO:
                return {
                    ...state
                };

            case ActionTypes.DONE_SAVE_SURVEY_INFO:
                return {
                    ...state,
                    currentSelected: "create",
                    selectedSurvey: action.payload
                };

            case ActionTypes.LOADING_CORE_SURVEYS:
                return {
                    ...state
                };

            case ActionTypes.LOADED_CORE_SURVEYS:
                return {
                    ...state,
                    coreSurveys: [ ...action.payload ],
                    selectedSurvey: {
                        ...state.selectedSurvey,
                        surveys: [ ...action.payload ]
                    }
                };

            case ActionTypes.UPDATE_SURVEY_QUESTIONS:
                const question = {...action.payload};

                return {
                    ...state,
                    selectedSurvey: {
                        ...state.selectedSurvey,
                        surveys: state.selectedSurvey.surveys.map(survey => {
                            if (survey.id == question.survey) {
                                survey.questions = survey.questions.map(q => {
                                    return question.id == q.id ? { ...question } : q;
                                })
                            }
                            return survey;
                        })
                    }
                };

            case ActionTypes.DONE_SAVE_SURVEYS:
                let savedSurveys = [
                    ...state.savedSurveys
                ];
                savedSurveys[0] = {
                    ...savedSurveys[0],
                    status: action.payload.isDraft ? "DRF" : "REV",
                    surveys: {
                        ...action.payload.savedSurveys
                    }
                };
                return {
                    ...state,
                    savedSurveys: [
                        ...savedSurveys
                    ],
                    clonedSurveys: [
                        ...savedSurveys
                    ],
                    currentSelected: action.payload.isDraft ? "draft" : "review"

                };

            case ActionTypes.LOADED_ALL_SURVEYS:
                return {
                    ...state,
                    savedSurveys: [ ...action.payload ],
                    isLoaded: true
                };

            case ActionTypes.SET_SELECTED_SURVEY:
                return {
                    ...state,
                    selectedSurvey: action.payload
                };

            case ActionTypes.HANDLE_SURVEY_RESET:
                return {
                    ...state,
                    selectedSurvey: {},
                    currentSelected: "init"
                };

            case ActionTypes.SET_CURRENT_SELECTED_SURVEY_PAGE:
                return {
                    ...state,
                    currentSelected: action.payload
                };

            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in surveyState reducer: ${ error.message || error.code || error }`, error);
    }
};
