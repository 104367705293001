import React from 'react';
import { Provider } from 'react-redux';
import Routes from './routes';
import GlobalMessage from "./containers/GlobalMessage";

import mixpanel from 'mixpanel-browser';
import {initializeUnderscoreMixins} from "./utils";

if (process.env.REACT_APP_MIXPANEL) {
    // prevent crash if env var is not set
    mixpanel.init(process.env.REACT_APP_MIXPANEL);
} else {
    console.log("process.env.REACT_APP_MIXPANEL is not set.")
}

initializeUnderscoreMixins();
export default function App ({ store }) {
    return (
        <Provider store = { store }>
            <React.Fragment>
                <Routes />
                <GlobalMessage />
            </React.Fragment>
        </Provider>
    );
}
