import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import {saveSurveyInfo, loadCoreSurveys, saveSurveys, editSurveys, draftSurveys, loadAllSurveys} from "./network";
import {stringifyQueryParams} from "../utils";

export default [
    [ ActionTypes.DO_SAVE_SURVEY_INFO, doSaveSurveyInfo ],
    [ ActionTypes.DO_SAVE_SURVEYS, doSaveSurveys ],
    [ ActionTypes.DO_EDIT_SURVEYS, doEditSurveys ],
    [ ActionTypes.DO_DRAFT_SURVEYS, doDraftSurveys ],
    [ ActionTypes.GET_CORE_SURVEYS, getCoreSurveys ],
    [ ActionTypes.GET_ALL_SURVEYS, getAllSurveys ]
];

function * doSaveSurveyInfo ({ payload }) {
    yield put(Actions.doingSaveSurveyInfo());

    const response = yield call(saveSurveyInfo, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneSaveSurveyInfo(response.data));
        yield put(Actions.getCoreSurveys(response.data.supported_languages))
    }
}

function * doSaveSurveys ({ payload }) {
    yield put(Actions.doingSaveSurveys());

    const response = yield call(saveSurveys, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneSaveSurveys({savedSurveys: response.data, isDraft: payload.draft}));
    }
}

function * doEditSurveys ({ payload }) {
    yield put(Actions.doingEditSurveys());

    const response = yield call(editSurveys, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneEditSurveys(response.data));
    }
}

function * doDraftSurveys ({ payload }) {
    yield put(Actions.doingDraftSurveys());

    const response = yield call(draftSurveys, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneDraftSurveys(response.data));
    }
}

function * getCoreSurveys ({ payload: languages }) {
    yield put(Actions.loadingCoreSurveys());

    const payload = {
        languages: languages
    };
    const response = yield call(loadCoreSurveys, stringifyQueryParams(payload));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedCoreSurveys(response.data));
    }
}

function * getAllSurveys ({ payload }) {
    yield put(Actions.loadingAllSurveys());

    const response = yield call(loadAllSurveys, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedAllSurveys(response.data));
    }
}
