import React from 'react';
import './index.scss';
import classNames from 'classnames';
import { Row, Column } from '../../utils/FlexBox';
import Card from '../../utils/Card';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ReactGA from 'react-ga';

// todo once we figure out where certification is in the backend
const CERTIFIED = false;

function URL(url) {
    window.open(url);
}

const NotCertifiedCard = ({t}) => (
    <Column width="100%">
                    <h3 className="certification-detail__card-title">{t('HOME.CERTIFICATION.TITLE')}</h3>
                    <div className="certification-detail__award-container">
                        <div className="certification-detail__award-img">
                            <img src="images/seal.png"/>
                        </div>
                        <div className="certification-detail__signal-text">Signal your commitment to Diversity & Inclusion with Diversio Certification.</div>
                        <div className="certification-detail__buttons">
                            <div className="certification-detail__contact-us">
                                <Button onClick={() => { 
                                    ReactGA.event({
                                        category: 'User',
                                        action: 'Button Click',
                                        label: 'Certification - Contact Us'
                                    });
                                    URL('mailto:info@diversio.com?subject=Diversio Certification (dashboard link)') 
                                    }}>Contact Us</Button>
                            </div>
                            <div className="certification-detail__learn-more">
                                <Button onClick={() => { 
                                    ReactGA.event({
                                        category: 'User',
                                        action: 'Button Click',
                                        label: 'Certification - Learn More'
                                    });
                                    URL('https://diversio.com/certification')}}>Learn More</Button>
                            </div>
                        </div>
                    </div>
                </Column>
);

const CertifiedCard = ({t}) => (
        <Column width="100%">
                        <h3 className="certification-detail__card-title">{t('HOME.CERTIFICATION.TITLE')}</h3>
                        <div className="certification-detail__award-container">
                            <div className="certification-detail__award-img-cert">
                                <img src="images/seal.png"/>
                            </div>
                            <div className="certification-detail__download-media">
                                <Button onClick={() => { URL('/downloads/diversio_certification_2021.zip')}}>Download Media Files</Button>
                            </div>
                        </div>
                    </Column>
    );

const Certification = ( {t} ) => {

    return (
        <Card className="certification-card">
        <div className="certification-detail">
            <Row>
                { CERTIFIED ? <CertifiedCard t={t}/> : <NotCertifiedCard t={t}/>}
            </Row>
       </div>
       </Card>
    );
};

export default withTranslation()(Certification);
