import { ActionTypes } from '../actions';

const defaultState = {
    roadMapChecklist: [],
    isLoading: false
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADED_ROAD_MAP_CHECKLIST:
                return {
                    ...state,
                    roadMapChecklist: action.payload
                };
            case ActionTypes.DOING_UPDATE_ROAD_MAP_CHECKLIST:
                return {
                    ...state,
                    isLoading: true
                };
            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }
};
