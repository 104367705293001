import React, {Component} from 'react';
import moment from 'moment';
import Survey from "../components/Survey";
import {connect} from "react-redux";
import {Actions} from "../actions";

class SurveyContainer extends Component {
    handleSetupNext (values) {
        const payload = {
            deploying_for: values.deployingWith,
            supported_languages: values.language,
            name: `Survey -- ${moment().format("DD-MMM-YYYY")}`
        };
        this.props.doSaveSurveyInfo(payload);
    }

    handleSetupBack (values) {

    }

    saveSurvey (options) {
        const { selectedSurvey } = this.props;
        const payload = {
            ...selectedSurvey,
            ...options
        };

        payload.surveys = [
            ...payload.surveys.map(survey => {
                return {
                    ...survey,
                    questions: [...survey.questions.map(question => {
                        switch (question.category) {
                            case 'Experience':
                                question.category = 1;
                                break;
                            case 'Workforce':
                                question.category = 2;
                                break;
                            case 'Demographic':
                                question.category = 3;
                                break;
                        }
                       return question;
                    })]
                }
            })
        ]

        // To be implemented in future if required
        // if (selectedSurvey.editMode) {
        //     this.props.doEditSurveys(payload);
        // } else {
        this.props.doSaveSurveys(payload);
        // }
    }

    handleCreateNext () {
        this.saveSurvey({ draft: false })
    }

    handleCreateBack () {

    }

    handleDraftSurveys() {
        this.saveSurvey({ draft: true })
    }

    render() {
        return <Survey
                handleSetupBack={this.handleSetupBack.bind(this)}
                handleSetupNext={this.handleSetupNext.bind(this)}
                handleCreateNext={this.handleCreateNext.bind(this)}
                handleCreateBack={this.handleCreateBack.bind(this)}
                handleReset={this.props.handleSurveyReset}
                draftSurveys={this.handleDraftSurveys.bind(this)}
                { ...this.props }/>
    }
}

const mapStateToProps = state => {
    return {
        ...state.survey,
        ...state.profile
    }
};

export default connect(mapStateToProps, Actions)(SurveyContainer);
