import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import { 
    InclusionScore,
    InclusionScoreBVP,
    EmployeesSurveyed,
    MetricOverview,
    HeatMap,
    FocusAreas,
    Certification,
    BusinessKeyPerformanceIndicator,
    ExecutiveSummary,
    DiversityComposition
} from '../components/Home/';
import { Row, Column } from '../components/utils/FlexBox';
import PaymentModal from '../components/utils/Modal/PaymentModal';
import './App.scss';

/* Data for BusinessKeyPerformanceIndicator. Delete once backend has this */
const BKPIdata = [
    {
        "description": "Employee Attrition Rate",
        "rates": [
            {
                "month": "March 2019",
                "rate": "40"
            },
            {
                "month": "April 2019",
                "rate": "30"
            },
            {
                "month": "May 2019",
                "rate": "20",
                "difference": "10"
            },
        ]
    },
    {
        "description": "Offer Acceptance Rate",
        "rates": [
            {
                "month": "March 2019",
                "rate": "75"
            },
            {
                "month": "April 2019",
                "rate": "80"
            },
            {
                "month": "May 2019",
                "rate": "88",
                "difference": "8"
            },
        ]
    },
    {
        "cost": 0,
        "description": "Spent on discrimination litigation"
    }
]

class Home extends Component {
    componentWillMount () {
        this.props.getEmployeeOverviewData();
        this.props.getInclusionMetricsData({ data: { year: "", level: "", office: "", department: "" } });
        this.props.getInclusionTrackerData({ data: { year: "", level: "", office: "", department: "" } });
        this.props.getDiversityCompositionData(this.props.token);
        this.props.getMetricOverviewData(this.props.token);
        this.props.getFocusAreaData(this.props.token);
        this.props.getInclusionScoreData(this.props.token);
        this.props.getExecSummaryData(this.props.token);
        this.props.getCompanyInformation();
        this.props.getCompanyFilters();
    }

    modalSwitch(oneElevenCompany, loggedInCompany) {
        let modal = 'none';

        for (let company = 0; company < oneElevenCompany.length; company++) {
            if (loggedInCompany === oneElevenCompany[company]) {
                modal = 'oneEleven';
                return modal;
            }
        }
        return modal;
    }
    
    render() {
        const xLabels = ['Inclusive culture', 'Unbiased feedback', 'Access to networks', 'Flexible work', 'Safe work'];

        const oneElevenCompanyModal = ['Altus Assessments', 'Blue J Legal', 'd1g1t Inc.', 'GrowthGenius', 'Hockeystick', 'Humi', 'Nudge.ai', 'OneElevenCommunity'];

        const modal = this.modalSwitch(oneElevenCompanyModal, this.props.companyInformation.name);

        let oneElevenCompany = false;
        if (modal === 'oneEleven') {
            oneElevenCompany = true;
        }

        /* DEMO DASHBOARD */
        let isDemo = false;
        if (this.props.companyInformation.name === 'DEMO') {
            isDemo = true;
        }

        /* HIDE CARDS FOR BVP PORTFOLIO and do special card on top left */
        let isBVPBP = false;
        if (this.props.companyInformation.name === 'Bessemer Venture Partners Brief Portfolio') {
            isBVPBP = true;
        }

        return (
            <div className="App">
                <Row>
                    <Column width="30%">
                        { isBVPBP ? 
                        <InclusionScoreBVP/> : 
                        <InclusionScore data={this.props.inclusionScore} isDeploySurveyProcessing={this.props.isDeploySurveyProcessing} deploySurvey={this.props.doDeploySurvey} companyName={this.props.companyInformation.name} />
                        }   
                    </Column>
                    <Column width="40%">
                        <EmployeesSurveyed data={this.props.analyze.employeeOverview} colors={this.props.colors}/>
                    </Column>
                    <Column width="30%">
                        <Certification inclusionScore={this.props.inclusionScore}/>
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <ExecutiveSummary data={this.props.execSummary}/>
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <DiversityComposition
                        breakdown={this.props.diversityCompositionBreakdown}
                        data={this.props.diversityComposition}
                        ndg={this.props.analyze.filters.non_dominant_group}
                        colors={this.props.colors}
                        industryData={this.props.industryDiversityComposition}
                        companyName={this.props.companyInformation.name}
                        profile={this.props.profile}
                        />
                    </Column>
                </Row>
                { isBVPBP ? null : (
                    <>
                    <Row>
                        <Column grow={1}>
                            <HeatMap data={this.props.analyze.inclusionMetrics} 
                            prevData={this.props.analyze.previousInclusionMetrics}
                            metadata={this.props.analyze.metadata}
                            companyName={this.props.companyInformation.name}
                            years={this.props.analyze.filters.years}
                            />
                        </Column>
                    </Row>
                    <Row>
                        <Column width="100%">
                            <FocusAreas data={this.props.focusArea} companyName={this.props.companyInformation.name}/>
                        </Column>
                    </Row>
                    </>
                ) }
                {(this.props.metricOverview.length > 1) && <Row>
                    <Column width="100%">
                        <MetricOverview data={this.props.metricOverview} BKPIdata={BKPIdata} isDemo={isDemo} companyName={this.props.companyInformation.name} />
                    </Column>
                </Row>}
                {
                    isDemo
                    ? <Row>
                        <Column grow={1}>
                            {/* data will pass through once backend is setup */}
                            <h2 className="transparent-header">Business Key Performance Indicators</h2>
                            <BusinessKeyPerformanceIndicator BKPIdata={BKPIdata} />
                        </Column>
                    </Row>
                    : <div></div>
                }
                {
                    oneElevenCompany
                    ? <Row>
                        <Column grow={1}>
                            <PaymentModal isInformingPurchase={this.props.isInformingPurchase} informPurchase={this.props.doInformPurchase} companyPurchasedStatus={this.props.companyInformation.purchased} />
                        </Column>
                    </Row>
                    : <div></div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.home,
        ...state.auth,
        analyze: state.analyze,
        colors: state.colors,
        profile: state.profile,
    };
};

export default connect(mapStateToProps, Actions)(Home);
