import React, {Component} from 'react';
import Header from "./Header";
import MySolution from "../../Improve/MySolution";
import TopSolution from "../../Improve/TopSolution";
import ContactCard from "../../utils/ContactCard";
import Goals from "../../../containers/DiversityVC/Goals";
import NextSteps from "./NextSteps";
import './index.scss'

const pages = {
    Overview: 'Overview',
    About: 'About'
};

class Overview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: pages.Overview
        }
    }

    componentDidMount() {
        this.props.getDiversityvcBenchmark();
        this.props.getDiversityvcScore();
        this.props.getCompanyFilters();
        this.props.getFilterMap();
        this.props.getAllSolutions();
        this.props.getCompanyInformation();
    }


    _deleteSolution(data) {
        this.props.doDeleteSolution(data);
    }

    _createNewSolution(data) {
        this.props.doCreateNewSolution(data);
    }

    _createNewCompanySolution(data) {
        this.props.doCreateNewCompanySolution(data);
    }

    render() {
        const hasLoaded = this.props.analyze !== undefined;
        if (!hasLoaded) {
            return null;
        }

        const mySolutions = this.props.improve.solutions.allSolutions.filter(x => x.is_my_solution);
        const recommendations = this.props.improve.solutions.allSolutions.filter(x => x.is_recommendation);
        const domain = '@diversio.com';
        const { diversityvc: { score, benchmark } = {} } = this.props;

        return (
            <div id={'overview'}>
                <Header 
                    score={score}
                    benchmark={benchmark}
                    companyName={this.props.home.companyInformation.name}
                />
                {
                    Object.keys(this.props.home.companyInformation).length && recommendations.length
                        ? <TopSolution topSolutions={recommendations}
                                       filters={this.props.home.companyInformation.recommendation_filters}
                                       doUpdateCompanySolutions={this.props.doUpdateCompanySolutions}
                                       showMessage={this.props.showMessage}
                                       isDiversityVC
                        />
                        : null
                }
                <NextSteps
                    company_solutions={this.props.improve.solutions.allSolutions.filter(x => x.is_next_steps)}
                    doUpdateCompanySolutions={this.props.doUpdateCompanySolutions}
                />
                <Goals/>
                <MySolution chosenSolutions={mySolutions}
                            filters={this.props.analyze.filters}
                            handleDeleteSolution={this._deleteSolution.bind(this)}
                            handleUpdateCompanySolutions={this.props.doUpdateCompanySolutions}
                            handleCreateNewSolution={this._createNewSolution.bind(this)}
                            handleCreateNewCompanySolution={this._createNewCompanySolution.bind(this)}
                            filterMap={this.props.analyze.filterMap}
                            newSolution={this.props.improve.solutions.newSolutions}
                            isDiversityVC
                />
                <div className="contact-container">
                    <ContactCard
                        title="Deploy a Survey"
                        imgSrc="/images/DiversityVC/DeploySurvey.png"
                        detail="Analyze the diversity & inclusion in your workforce, identify and unlock barriers to advancements for women and minorities."
                        link={`info${domain}`}
                        linkText="Get Started"
                    />
                    <ContactCard
                        title="Start Your Portfolio Assessment"
                        imgSrc="/images/LSO/Update/update.png"
                        detail="Assess the diversity & inclusion of your portfolio. Understand which of your companies are vulnerable to risks such as toxic culture and internal discrimination."
                        link={`info${domain}`}
                        linkText="Assess Your Portfolio"
                    />
                </div>
            </div>
        );
    }
}

export default Overview;
