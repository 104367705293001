import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';

import MainCard from '../../utils/MainCard';
import * as m from '../../../translations/mapping';
import {getTranslation, mapT} from '../../../translations/utils';
import { _makeCell, _getSideHeaderClass } from './utils';

import classNames from 'classnames';
import "./index.scss";
import { DashboardTooltip } from '../../utils/DashboardTooltip';
import { industryOrRegional, mapChoiceLabel, mapMetaKpiLabels, } from '../../../utils/labels';
import { isPortfolioCompany } from '../../../utils/companyChecks';
import Legend from './Legend';

/* Table columns in desired order of appearance */
const COLUMN_KEYS = [
    'inclusive_culture_average',
    'review_feedback_average',
    'access_to_network_average',
    'flexibility_weight_average',
    'safety_average',
    'recruiting_and_hiring_average'
];

class HeatMap extends Component {
    
    getSubheader = () => {
        const {t} = this.props;
        
        if (isPortfolioCompany(this.props.companyName)) {
            return t("HOME.CARD_HEADERS.INCLUSION_SCORECARD.subheader_p");
        }

        return getTranslation("HOME.CARD_HEADERS.INCLUSION_SCORECARD.subheader", this.props.companyName)
    }

    render() {
        const { metadata = [], companyName, t, data, prevData } = this.props;
        
        let heatmapMetricData = [ null, null ];

        if (data) {
            /* Contains strengths and weaknesses */
            heatmapMetricData = [
                data[0], // industry
                data[1]  // your score
            ];
        }

        let prevMetricData = [ null, null ];
        
        if (prevData) {
            prevMetricData = [
                prevData[0],
                prevData[1]
            ]
        }

        let table;
        if (!(data.length)) {
            table = null;
        } else {
            table = (
                <Table bordered className="home-heat-map">
                    <thead className="home-heat-map__thead">
                        <tr>
                            <th width="16%" />
                            {
                                metadata.map((meta, index) => 
                                <th
                                width="14%"
                                 className="home-heat-map__header home-heat-map__cell" key={index}>
                                    <DashboardTooltip content={mapT(m.HEATMAP_TOOLTIPS, meta.helperText, companyName)}>
                                        <span>
                                            {mapT(m.METRIC, mapMetaKpiLabels(companyName, meta.name), companyName)}
                                        </span>
                                    </DashboardTooltip>
                                    </th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {heatmapMetricData.map((metric, id) => {
                        return (
                            <tr key={id}>
                                <td className={classNames(["home-heat-map__cell", "home-heat-map__side_header", _getSideHeaderClass(id)])}>
                                    <DashboardTooltip content={mapT(m.HEATMAP_SIDE_TOOLTIPS, metric.help_text)}>
                                        <span>
                                        {mapT(m.HEATMAP_SIDEBAR, 
                                        mapChoiceLabel(industryOrRegional(metric.title, this.props.companyName)))}
                                        </span>
                                    </DashboardTooltip>
                                </td>
                                {COLUMN_KEYS.map(key => (_makeCell(metric, prevMetricData[id], key, id)))}
                            </tr>
                        )
                    })}
                        </tbody>
                </Table>
            );
        }

        return (
            <MainCard title={t("HOME.CARD_HEADERS.INCLUSION_SCORECARD.title")}
            subheader={this.getSubheader()}
            toolTipContent={getTranslation("HOME.CARD_HEADERS.INCLUSION_SCORECARD.tooltip", this.props.companyName)}>
            <div className="home-heat-map">
                {table}
            </div>
            <Legend/>
            </MainCard>
        );
    }
};

export default withTranslation()(HeatMap);
