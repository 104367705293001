import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import './index.scss';
import { DashboardTooltip } from '../DashboardTooltip';

const MainCard = (props) => {
    const { title, subheader, toolTipContent, customRighthand } = props;

    const actionContent = (toolTipContent 
        ? 
    <DashboardTooltip content={toolTipContent}>
        <img className="icon-question-mark"/>
    </DashboardTooltip> 
    : (
        <span>{customRighthand}</span> || null 
    ))

    return (
        <div className="custom-card">
            <Card className={"custom-card__container"}>
                <CardHeader className="custom-card__card-header"
                title={<span className="custom-card__title">{title}</span>}
                subheader={<span className="custom-card__subheader">{subheader}</span>}
                action={actionContent}>
                </CardHeader>
                <CardContent className="main-card__card-content">
                    {props.children}
                </CardContent>
            </Card>
        </div>
    )
};

export default MainCard;
