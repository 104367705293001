const DEMO_COMPANIES = [
    "diversio demo"
]

const HIDE_LONGITUDINAL_COMPANY_NAMES = [
    "biocanrx", "sheertex", "infrastructure ontario"
];

export const HIDE_FREETEXT_COMPANY_NAMES = [
    "hggc",
]

const PORTFOLIO_COMPANIES = [
    "amazing investor",
    "mcrock",
    "mcrock portfolio(without offices)",
    "bdc",
    "omers portfolio",
    "interac",
    "bessemer venture partners brief portfolio"
]

const ORGANIZATION_COMPANIES = [
    "hrpa",
    "canadian institute of actuaries",
    "equalby30",
]

const OFFICE_IS_COUNTRY_COMPANIES = [
    "equalby30",
]

// if a company name check happens more than once in the code,
// create a is<CompanyName>() function below using nameMatches()
// so we don't have to specify the comparison string more than once
export const nameMatches = (companyName, match_str) => {
    if (typeof (companyName) === 'string' && match_str.toLowerCase() === companyName.toLowerCase()) {
        return true
    }
    return false;
};

export const isDemoCompany = (companyName) => {
    if (typeof (companyName) === 'string' && DEMO_COMPANIES.includes(companyName.toLowerCase())) {
        return true
    }
    return false;
}

export const isOrganizationCompany = (companyName) => {
    if (typeof (companyName) === 'string' && ORGANIZATION_COMPANIES.includes(companyName.toLowerCase())) {
        return true
    }
    return false;
}

export const officeIsCountry = (companyName) => {
    if (typeof (companyName) === 'string' && OFFICE_IS_COUNTRY_COMPANIES.includes(companyName.toLowerCase())) {
        return true
    }
    return false;
}

export const isPortfolioCompany = (companyName) => {
    if (typeof (companyName) === 'string' && PORTFOLIO_COMPANIES.includes(companyName.toLowerCase())) {
        return true
    }
    return false;
}

export const isMcrockDueDiligence = (companyName) => {
    if (typeof (companyName) === 'string' && companyName.toLowerCase() === "mcrock due diligence") {
        return true
    }
    return false;
};

export const isHondaHTC = (companyName) => {
    return nameMatches(companyName, "honda htc");
};

export const isHRPA = (companyName) => {
    return nameMatches(companyName, "hrpa");
};

export const isCia = (companyName) => {
    return nameMatches(companyName, "canadian institute of actuaries");
};

export const isBessemerBriefPortfolio = (companyName) => {
    return nameMatches(companyName, "bessemer venture partners brief portfolio");
};

export const hideLongitudinal = (companyName) => {
    if (typeof (companyName) === 'string' && (HIDE_LONGITUDINAL_COMPANY_NAMES.includes(companyName.toLowerCase()))) {
        return true;
    }
    return false;
}

export const isMember = (listName, companyName) => {
    if (typeof (companyName) === 'string' && (listName.includes(companyName.toLowerCase()))) {
        return true;
    }
    return false;
}
