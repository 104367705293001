import React, { Fragment } from 'react';
import classNames from 'classnames';
import './index.scss';
import Card from '../../utils/Card';
import {getIndustryCompType, isDecimal} from "../../../utils";
import { withTranslation } from 'react-i18next';

const InclusionScore = ({data, companyName, t}) => {
    if (!data) {
        return null;
    }

    const {
        current_inclusion_score: current = 0,
        last_survey_kpi: last ,
        industry_kpi: industry,
        last_survey_date: lastDate } = data;

    const lastKpiDifference = last ? current - last : null;
    const isPositive_KPI = lastKpiDifference > 0;
    const kpiLowerHigher = isPositive_KPI
        ? "HOME.COMPARISON.HIGHER"
        : "HOME.COMPARISON.LOWER";
    const deltaKpi = Math.abs(lastKpiDifference).toFixed(1);
    const kpiCompString = t("HOME.KPI_COMP_STRING", 
    {   lowerHigher: t(kpiLowerHigher),
        date: lastDate, 
    });

    const industryDifference = current - industry;
    const isPositive_INDUSTRY = industryDifference > 0;
    const indLowerHigher = isPositive_INDUSTRY
        ? "HOME.COMPARISON.HIGHER"
        : "HOME.COMPARISON.LOWER";
    const deltaIndustry = Math.abs(industryDifference).toFixed(1);
    const industryCompString = t("HOME.INDUSTRY_COMP_STRING", 
    {   lowerHigher: t(indLowerHigher),
        compType: t(getIndustryCompType(companyName)) 
    });
    
    return (
        <Card>
            <div className="inclusion-score">
                <h3 className="inclusion-score__card-title">Inclusion Score</h3>
                <div className="inclusion-score__container">
                    <div className="inclusion-score__circle">
                        <h2 className="inclusion-score__score-text">{ isDecimal(current) ? current.toFixed(1) : current }</h2>
                    </div> 
                    {
                        (lastKpiDifference || industryDifference) ? (
                            <div className="inclusion-score__comparisons-trends-container">
                            {
                                lastKpiDifference ?
                                    <Fragment>
                                        <div className={ classNames("trend", isPositive_KPI ? "positive" : "negative") }>
                                        </div>
                                        <div className="inclusion-score__trend-text">
                                            <span className="inclusion-score__score-number">{deltaKpi}</span><span>{kpiCompString}</span>
                                        </div>
                                    </Fragment> :
                                    null
                            }

                            <div className={ classNames("comparison", isPositive_INDUSTRY ? "positive" : "negative") }>
                            </div>
                            {(industryDifference ? <div className="inclusion-score__comparison-text">
                                <span className="inclusion-score__score-number">{deltaIndustry}</span><span>{industryCompString}</span>
                            </div> : null )}
                            
                    </div>
                        ) :
                        null
                    }
                </div>
            </div>
        </Card>
    )
};

export default withTranslation()(InclusionScore);
