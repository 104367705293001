import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { handleLogin, handleRegistration, refreshToken, changePassword, requestResetPassword, resetPassword } from './network';
import history from "../history";
import {decode} from "jsonwebtoken";

import mixpanel from "mixpanel-browser";
import { safeMixpanelTrack } from "../components/Analytics/utils/utils";

// All data sagas to add to middleware.
export default [
    [ActionTypes.DO_LOGIN, doLogin],
    [ActionTypes.DO_REGISTRATION, doRegistration],
    [ActionTypes.DO_REFRESH_TOKEN, doRefreshToken],
    [ActionTypes.DO_SIGNOUT, doSignout],
    [ActionTypes.DO_CHANGE_PASSWORD, doChangePassword],
    [ActionTypes.DO_REQUEST_RESET_PASSWORD, doRequestResetPassword],
    [ActionTypes.DO_RESET_PASSWORD, doResetPassword]
];

const handleAuthCreds = (response) => {
    const { access_token , refresh_token} = response.data;
    const user = decode(access_token)
    const responseObject = {
        "access_token": access_token,
        "refresh_token": refresh_token,
        "user": user
    }
    if (access_token && refresh_token) {
        window.localStorage.setItem('auth_access_token', access_token);
        window.localStorage.setItem('auth_refresh_token', refresh_token);
    }

    return responseObject;
}

function * doLogin ({ payload }) {
    yield put(Actions.doingLogin());
    const response = yield call(handleLogin, payload);
    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: response.data?.detail}));
        yield put(Actions.resetAuth());
    } else {
        yield put(Actions.doneLogin(handleAuthCreds(response)));
    }
}

function * doRegistration ({ payload }) {
    yield put(Actions.doingRegistration());

    const response = yield call(handleRegistration, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: response.data.error.message}));
        yield put(Actions.resetAuth());
    } else {

        mixpanel.alias(response.data.data.user_id);

        safeMixpanelTrack("Sign Up", {
            $email: payload.email,
            $name: payload.username,
            user_id: response.data.data.user_id
        });

        yield put(Actions.doneRegistration(response.data));
    }
}

function * doRefreshToken ({ payload }) {
    yield put(Actions.doingRefreshToken());
    const requestPayload = {refresh: payload.refreshToken}

    const response = yield call(refreshToken, requestPayload)

    if (!response.successful) {
        yield put(Actions.resetAuth());
    } else {
        yield put(Actions.doneRefreshToken(handleAuthCreds(response)));
    }
}

export function * doSignout () {
    yield put(Actions.doingSignout());
    localStorage.removeItem("auth_access_token");
    localStorage.removeItem("auth_refresh_token");
    yield put(Actions.doneSignout());
    yield put(Actions.resetAuth());
}

function * doChangePassword ({ payload }) {
    yield put(Actions.doingChangePassword());

    const response = yield call(changePassword, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: response.data.error.message}));
    } else {
        yield put(Actions.doneChangePassword(response.data));
    }
}

function * doRequestResetPassword ({ payload }) {
    yield put(Actions.doingRequestResetPassword());
    
    const response = yield call(requestResetPassword, payload);

    if (!response.successful) {
        yield put(Actions.requestResetPasswordFail(response.data));
    } else {
        yield put(Actions.doneRequestResetPassword(response.data));
    }
}

function * doResetPassword ({ payload }) {
    yield put(Actions.doingResetPassword());

    const response = yield call(resetPassword, payload);

    if (!response.successful) {
        yield put(Actions.resetPasswordFail(response.data));
    } else {
        yield put(Actions.doneResetPassword(response.data));
    }
}
