import React from 'react';
import './index.scss';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell} from "recharts";
import _ from 'underscore';
import {prototype} from 'react-transition-group/CSSTransition';
import {industryOrRegional, mapChoiceLabel} from "../../../utils/labels";
import { 
    isPortfolioCompany,
    isOrganizationCompany,
    isMcrockDueDiligence
} from "../../../utils/companyChecks";

const minorityMap = {
    "Gender": {
        majority: "Men",
        minority: "Women"
    },
    "Ethnicity": {
        majority: "White",
        minority: "People of Color"
    },
    "Sexual orientation": {
        majority: "Heterosexual",
        minority: "LGBTQ+"
    },
    "Disability": {
        majority: "No disability/impairment",
        minority: "Disability/impairment"
    }
};

const DiversityCopositionTooltip = ({company, categories, majority, minority, companyName}) => {
    let {
        group_0 = 'N/A',
        group_1 = 'N/A',
        group_2 = 'N/A',
        value_0 = 0,
        value_1 = 0,
        value_2 = 0,
        industry_value_0 = 0,
        industry_value_1 = 0,
        industry_value_2 = 0,
        industry_group_0 = 'N/A',
        industry_group_1 = 'N/A',
        industry_group_2 = 'N/A'
    } = categories;

    group_2 = mapChoiceLabel(group_2 === 'N/A' ? industry_group_2 : group_2);
    group_1 = mapChoiceLabel(group_1 === 'N/A' ? industry_group_1 : group_1);
    group_0 = mapChoiceLabel(group_0 === 'N/A' ? industry_group_0 : group_0);

    const industryStr = industryOrRegional('Industry', companyName);
    var companyStr = "Your company";

    if (isPortfolioCompany(companyName)) {
        companyStr = "Your portfolio";
    } else if (isOrganizationCompany(companyName)) {
        companyStr = "Your organization";
    }
    else if (isMcrockDueDiligence(companyName)) {
        companyStr = "Prospect Company";
    }

    return (
        <div className="custom-tooltip">
            <p className="label">{group_2}</p>
            <p className="label">{companyStr} : <span
                className="PNTS-value">{value_2}%</span></p>
            <p className="label">{industryStr} average : <span
                className="PNTS-industry-value">{industry_value_2 ? `${industry_value_2}%` : 'N/A'}</span>
            </p>
            <hr/>
            <p className="label">{group_1}</p>
            <p className="label">{companyStr} : <span
                className="majority-value">{value_1}%</span></p>
            <p className="label">{industryStr} average : <span
                className="majority-industry-value">{industry_value_1 ? `${industry_value_1}%` : 'N/A'}</span>
            </p>
            <hr/>
            <p className="label">{group_0}</p>
            <p className="label">{companyStr} : <span
                className="minority-value">{value_0}%</span></p>
            <p className="label">{industryStr} average : <span
                className="minority-industry-value">{industry_value_0 ? `${industry_value_0}%` : 'N/A'}</span>
            </p>
        </div>
    )
};

const TooltipContainer = ({active, payload, label, companyName}) => {
    return active && payload && payload.length ?
        <DiversityCopositionTooltip company={payload} categories={payload[0].payload} {...minorityMap[label]}
                                    companyName={companyName}/> :
        null;
};

function _parseData(groups, industryData) {
    return groups.map((group, index) => {
        const demographic = Object.keys(group)[0];
        const demographicLabel = demographic === "Ethnicity" ? "Race and Ethnicity" : demographic
        if (group[demographic].length === 0) {
            return {
                demographic: demographicLabel,
                ["no_data"]: "Threshold not met",
                ["no_data"]: 100,
                ...industryData[demographic].reduce((allCategories, category, id) => {
                    return {
                        ...allCategories,
                        [`industry_group_${id}`]: category.name,
                        [`industry_value_${id}`]: category.value
                    }
                }, {})
            }
        } else {
            return {
                demographic: demographicLabel,
                ...group[demographic].reduce((allCategories, category, id) => {
                    const industryAverage = industryData ? _.findWhere(industryData[demographic], {name: category.name}) : null;
                    return {
                        ...allCategories,
                        [`group_${id}`]: category.name,
                        [`value_${id}`]: category.value,
                        [`industry_value_${id}`]: industryAverage && industryAverage.value
                    }
                }, {})
            }
        }

    })
}

const NABar = (props) => {
    const {x, y, width, height} = props;
    return <rect x={x} y={y} width={width} height={height} fill="rgb(237, 237, 238, 0.5)" strokeWidth="2px"
                 strokeDasharray="10 5" stroke="rgba(0, 0, 0, 0.2)"/>;
};

const DiversityComposition = ({data, industryData, companyName}) => {
    if (industryData.length < 1) {
        return null;
    }
    const parsedData = _parseData(data, industryData);
    const industryStr = industryOrRegional('Industry', companyName);

    var subtitle = "Self-identified demographic traits of your employees";
    if (isPortfolioCompany(companyName)) {
        subtitle = "Self-identified demographic traits of your portfolio's employees";
    } else if (isOrganizationCompany(companyName)) {
        subtitle = "Self-identified demographic traits of your organization's members";
    }
    else if (isMcrockDueDiligence(companyName)) {
        subtitle = "Self-identified demographic traits of the prospect company's employees";
    }

    var legendCompanyStr = "Your company";
    if (isPortfolioCompany(companyName)){
        legendCompanyStr = "Your portfolio";
    }
    else if (isOrganizationCompany(companyName)) {
        legendCompanyStr = "Your organization";
    }
    else if (isMcrockDueDiligence(companyName)){
        legendCompanyStr = "Prospect Company";
    }
    return (
        <div id="diversity-composition">
            <div className="overview_title">Diversity composition</div>
            <div className="description">{subtitle}</div>
            <div className="legend">
                <div className="company">{legendCompanyStr}</div>
                <div className="industry-average">{industryStr} average</div>
                <div className="na">Minimum threshold not met</div>
            </div>
            <ResponsiveContainer height={250} width={800}>
                <BarChart
                    data={parsedData}
                    barGap={15}
                    barSize={50}
                    barCategoryGap={50}
                >
                    <CartesianGrid stroke="#DBD9DB" vertical={false}/>
                    <XAxis dataKey="demographic" axisLine={true} tickLine={false}/>
                    <YAxis axisLine={false} tickLine={false} domain={[0, 100.0]} allowDataOverflow={true}/>
                    <Tooltip content={<TooltipContainer companyName={companyName}/>} offset={100}/>

                    <Bar dataKey="value_0" stackId="demographicGroup" fill="#6221EA"/>
                    <Bar dataKey="value_1" stackId="demographicGroup" fill="#9169ED"/>
                    <Bar dataKey="value_2" stackId="demographicGroup" fill="#D0BEF7"/>
                    <Bar dataKey="no_data" stackId="demographicGroup" shape={<NABar/>}/>

                    <Bar dataKey="industry_value_0" stackId="industry" fill="#1032E4"/>
                    <Bar dataKey="industry_value_1" stackId="industry" fill="#536BEC"/>
                    <Bar dataKey="industry_value_2" stackId="industry" fill="#B6BFF6"/>

                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DiversityComposition;
