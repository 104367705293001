import React from 'react';
import { withTranslation } from 'react-i18next';
import './index.scss';

const Legend = ({t, activeCompanyName, visible=true, ...props}) => {

    if (!visible) {
        return null;
    }
    
    return (
        <div className="analyticslegend analytics-painpoint-legend">
            <div className="analyticslegend__company">{activeCompanyName}</div>
            <div className="analyticslegend__industry-average">Industry Median (5.0)</div>
            <div className="analyticslegend__na">{t("ANALYTICS.DATA_NA")}</div>
        </div>
    );
}

export default withTranslation()(Legend);
