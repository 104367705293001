import React from 'react';
import classNames from 'classnames';
import './index.scss'

const SingleChoice = ({ question, openText, handleChange }) => {
    const { title, required, type, placeholder, editable, choices } = question;

    const _handleChoiceChange = (val, idx) => {
        handleChange({
            ...question,
            choices: choices.map((choice, id) => {
                if (idx != id) {
                    return choice;
                } else {
                    return {
                        ...choice,
                        label: val
                    }
                }
            })
        })
    };

    return (
        <div className={classNames("question", "sc-question", { "required": required })}>
            <p className={"question-title"}>{title}<span className={"asterisk"}>*</span></p>
            <div className={"sc-question__choice-container"}>
                <ul>
                    {
                        choices.map((choice, i) => {
                            return <li>
                                <img className={classNames("icon icon-cross", { invisible: !editable })}/>
                                <input type="radio" disabled/>
                                <label contentEditable={editable} onInput={e => _handleChoiceChange(e.target.innerText, i)}>{ choice.value }</label>
                            </li>
                        })
                    }
                    {
                        openText && <li className={"others"}>
                            <img className={"icon icon-cross invisible"}/>
                            <input type="radio" disabled/>
                            <label>{ "Other" }</label>
                        </li>
                    }
                </ul>
            </div>
        </div>
    );
};

export default SingleChoice;
