import React, {Component} from 'react';
import { Actions } from '../../actions';
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import Login from '../../components/Auth/login';

class LoginContainer extends Component {
    _handleOnSubmit (values) {
        this.props.doLogin(values)
    }

    render() {
        const { from, message } = this.props.location.state || { from: { pathname: '/' } };
        const { company_permission: permissions = null } = this.props.companyInformation;

        if (this.props.isAuthenticated) this.props.getCompanyInformation();

        if (this.props.isAuthenticated && permissions) {
            // if(permissions.iln_dashboard_home) return <Redirect to="iln-dashboard/home" />
            // if(permissions.diversity_vc) return <Redirect to="diversity_vc/about" />
            if(permissions.analytics) return <Redirect to="analytics/search" />
            if(permissions.home) return <Redirect to="home" />
            
            return <Redirect to={from} />
        }

        return <Login message={message} onSubmit={this._handleOnSubmit.bind(this)}/>;
    }
}

const mapStateToProps = state => {
    return {
        ...state.auth,
        ...state.home
    }
};

export default connect(mapStateToProps, Actions)(LoginContainer);
