import * as m from '../translations/mapping';
import { mapT } from '../translations/utils';
import { isMcrockDueDiligence, isPortfolioCompany, 
    isOrganizationCompany, officeIsCountry, nameMatches, isCia, isHondaHTC, isHRPA, isBessemerBriefPortfolio } from "./companyChecks";

// WARNING this is not an efficient solution, should not be used for large strings
export function replaceAll(str, _find, replace) {
    const find = Array.isArray(_find) ? _find : [_find];

    let alteredStr = str;
    for (const f of find) {
        alteredStr = alteredStr.split(f).join(replace)
    }

    return alteredStr;
}

// WARNING this is not an efficient solution, should not be used for large strings
export function replaceMaleAndFemale(str) {
    return replaceAll(replaceAll(str, 'Female', 'Women'), 'Male', 'Men');
}

const CIA_META_KPI_MAP = {
    "Unbiased feedback & reviews": "Unbiased Community",
    "Flexible working options": "Flexibility",
    "Safe work environment": "Safe Environment",
    "Recruiting & Hiring": "Member onboarding",
}

export const mapMetaKpiLabels = (companyName, metaName) => {
    if (isCia(companyName)) {
        return (CIA_META_KPI_MAP[metaName] || metaName);
    } else {
        return metaName;
    }
}

// NOTE that order matters on this ladder!
export const getOfficeRegionLabel = (companyName) => {
    if (isBessemerBriefPortfolio(companyName)) {
        return "Location"
    }
    if (isPortfolioCompany(companyName)) {
        return "Company";
    } else if (officeIsCountry(companyName)) {
        return "Country";
    } else if (nameMatches(companyName, "accenture")) {
        return "Practice";
    } else if (isCia(companyName)) {
        return "Status";
    } else if (isHRPA(companyName)) {
        return "Industry";
    } else if (isOrganizationCompany(companyName)) {
        return "Organization";
    }
    return mapT(m.FILTER_LABELS, "Office / Region");
}

export function industryOrRegional(str, companyName, replaceWith = 'regional') {
    const _replace = (capitalizedStr) => {
        const lowercase = capitalizedStr.toLowerCase();
        const titleCase = lowercase[0].toUpperCase() + lowercase.slice(1);
        return replaceAll(replaceAll(str, 'industry', lowercase), 'Industry', titleCase);
    }
    if (companyName && companyName.startsWith('OLX-')) {
        return _replace(replaceWith)
    }
    else if (companyName && isMcrockDueDiligence(companyName)) {
        return _replace('Portfolio');
    } else if (companyName && isHondaHTC(companyName)) {
        // Special handling for Honda heatmap tooltip:
        // "your industry" => "Honda", not "your Honda"
        return replaceAll(str, ['your industry', 'industry', 'Industry'], 'Honda');
    }
    return str;
}

// TODO @jamie This is awful and proper re-write of Filters needs to be done to get rid of this.
export function mapChoiceLabel(str) {
    const genderMap = {
        'Male': 'Men',
        'male': 'men',
        'Female': 'Women',
        'female': 'women'
    };
    return genderMap[str] ? genderMap[str] : str
}

export function renameEthnicity(str) {
    const map = {
        'Ethnicity': 'Race and Ethnicity',
        'ethnicity': 'race and ethnicity'
    };
    return map[str] ? map[str] : str
}

export const getDepartmentLabel = (companyName) => {
    if (isCia(companyName)) {
        return "Practice";
    }
    if (isBessemerBriefPortfolio(companyName)) {
        return "Company"
    }
    return "Department";
}
