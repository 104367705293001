import React from 'react';
import Card from '../../utils/Card';
import './index.scss';
import CircularProgress from '@material-ui/core/CircularProgress';

const ILNnews = (props) => {
    const { news = [] } = props
    const formatDate = (prop) => {
        let dt = new Date(prop)
        const year  = dt.getFullYear();
        const month = (dt.getMonth() + 1).toString().padStart(2, "0");
        const day   = dt.getDate().toString().padStart(2, "0");
        const result = year + '/' + month + '/' + day
        return result
    }

    return (
        <div className="iln-news">
            <Card className="iln-news-card">
                <h2>News Feed</h2>
                {news.length > 1 ? news.map((row, index) => {
                    return (
                        <div key={index}>
                            <div className="news-container" key={index}>
                                <div className="news-header">
                                    <h5 className="news-site">{row.site}</h5>
                                    <h5>{formatDate(row.datetime)}</h5>
                                </div>
                                <h3>{row.title}</h3>
                                <p>{row.desc}</p>
                                <a href={"//" + row.link} target="_blank"rel="noopener noreferrer">
                                    Read Full Story
                                </a>
                            </div>
                            <hr />
                        </div>
                    )
                }) : 
                <div className="news-loading">
                    <CircularProgress style={{color: '#27829E'}} />
                </div>}
            </Card>
        </div>
    )
}

export default ILNnews;
