import {call, put} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {loadDiversityVCGoal,
    loadDiversityVCScore,
    loadDiversityVCBenchmark,
    updateDiversityVCGoal,
    postDiversityVCGoal,
    deleteDiversityVCGoal} from './network';

export default [
    [ActionTypes.GET_DIVERSITYVC_SCORE, getDiversityVCScore],
    [ActionTypes.GET_DIVERSITYVC_BENCHMARK, getDiversityVCBenchmark],
    [ActionTypes.GET_DIVERSITYVC_GOAL, getDiversityVCGoal],
    [ActionTypes.DO_UPDATE_DIVERSITYVC_GOAL, doPatchDiversityVCGoal],
    [ActionTypes.DO_CREATE_DIVERSITYVC_GOAL, doPostDiversityVCGoal],
    [ActionTypes.DO_DELETE_DIVERSITYVC_GOAL, doDeleteDiversityVCGoal]
];

function createError(id, timestamp, httpStatusCode, httpResponseBody) {
    return {id, timestamp, httpStatusCode, httpResponseBody}
}

function* getDiversityVCScore({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingDiversityvcScore());
    const response = yield call(loadDiversityVCScore);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedDiversityvcScore(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingDiversityvcScore(error));
    }
}

function* getDiversityVCBenchmark({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingDiversityvcBenchmark());
    const response = yield call(loadDiversityVCBenchmark);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedDiversityvcBenchmark(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingDiversityvcBenchmark(error));
    }
}

function* getDiversityVCGoal({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingDiversityvcGoal());
    const response = yield call(loadDiversityVCGoal);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedDiversityvcGoal(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingDiversityvcGoal(error));
    }
}

function* doPatchDiversityVCGoal({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.doingUpdateDiversityvcGoal());
    const response = yield call(updateDiversityVCGoal, payload);
    if (response.successful) {
        yield put(Actions.doneUpdateDiversityvcGoal(response.data));
        // Since order of other goals may change w/ update, re-fetch goals after update
        yield call(getDiversityVCGoal,{requestId})
    } else {
        yield put(Actions.failedUpdateDiversityvcGoal({id: requestId}))
    }
}


function* doPostDiversityVCGoal({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.doingCreateDiversityvcGoal());
    const response = yield call(postDiversityVCGoal, payload);
    if (response.successful) {
        yield put(Actions.doneCreateDiversityvcGoal(response.data));
        // Since order of other goals may change w/ update, re-fetch goals after update
        yield call(getDiversityVCGoal,{requestId})
    } else {
        yield put(Actions.failedCreateDiversityvcGoal({id: requestId}))
    }
}

function* doDeleteDiversityVCGoal({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.doingDeleteDiversityvcGoal());
    const response = yield call(deleteDiversityVCGoal, payload);
    if (response.successful) {
        yield put(Actions.doneDeleteDiversityvcGoal(response.data));
        // Since order of other goals may change w/ update, re-fetch goals after update
        yield call(getDiversityVCGoal,{requestId})
    } else {
        yield put(Actions.failedDeleteDiversityvcGoal({id: requestId}))
    }
}
