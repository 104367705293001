import React from 'react';
import classNames from "classnames";
import './index.scss';

const Rating = ({ question }) => {
    const { title, required, type, placeholder, choices } = question;

    return (
        <div className={classNames("question", "rating-question", { "required": required })}>
            <p className={"question-title"}>{title}<span className={"asterisk"}>*</span></p>
            <div className={"rating-question__rate-container"}>
                <p>No, not at all</p>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <p>Yes, definitely</p>
            </div>
        </div>
    );
};

export default Rating;
