import React from 'react';
import './index.scss';
import { ordinal } from "../utils/utils";
import _ from 'underscore';

const CircleStat = ({ score, title, percent, percentile, extraClass, verified, ...props}) => {
    return (
    <div className={`overview__circle_stat`}>
        <span className="overview__stat_title">{title || null}
        {percent && <span className="overview__title_percent">{`${percent}%` || null}</span>}
        </span>
        <div className="inner_circle">
        {
            (score || score === 0) ? (
                <span className="score-wrapper">
                <span className={ `${title === "Diversio Score" ? "purple_score" : "score"}`}>{score || 0}</span>
        <span className="outof">/100</span>
        </span>) :
        "-"
        }
        </div>
        <div className="overview__percentile">
            {percentile ? `${ordinal(percentile)} percentile` : null}
        </div>
    </div>
    )
}

const Overview = ({ selectedKey, scoreData, ...props}) => {
    let overall;
    let diversity;
    let inclusion;
    let commitment;
    let verified;

    let overall_p;
    let diversity_p;
    let inclusion_p;
    let commitment_p;

    if (!_.isEmpty(scoreData)) {
        overall = scoreData.overall_score;
        diversity = scoreData.diversity_score;
        inclusion = scoreData.inclusion_score; 
        commitment = scoreData.commitment_score;
        verified = false; // TODO add this to BE even tho not using

        overall_p = scoreData?.percentile_json?.overall_score;
        diversity_p = scoreData?.percentile_json?.diversity_score;
        inclusion_p = scoreData?.percentile_json?.inclusion_score;
        commitment_p = scoreData?.percentile_json?.commitment_score;


    } else {
        return null;
    }
    
    return (
        <div className="overview__wrapper">
            <div className="overview__diversio_score">
                <CircleStat title="Diversio Score" score={overall} percentile={overall_p} verified={verified}/>
            </div>
            <div className="overview__score">
                <CircleStat title="Diversity" score={diversity} percentile={diversity_p} percent={25}/>
            </div>
            <div className="overview__score">
                <CircleStat title="Inclusion" score={inclusion} percentile={inclusion_p} percent={50}/>
            </div>
            <div className="overview__score">
                <CircleStat title="Commitment" score={commitment} percentile={commitment_p} percent={25}/>
            </div>
        </div>
    );
}

export default Overview;
