import { ActionTypes } from '../actions';

const defaultDiversityvc = {
    score: {},
    benchmark: {},
    goals: []
};


export default (diversityvc= defaultDiversityvc, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADED_DIVERSITYVC_SCORE:{
                const score = {...diversityvc.score, ...action.payload};
                return {
                    ...diversityvc,
                    score
                };
            }
            case ActionTypes.LOADED_DIVERSITYVC_BENCHMARK:{
                const benchmark = {...diversityvc.benchmark, ...action.payload};
                return {
                    ...diversityvc,
                    benchmark
                };
            }
            case ActionTypes.LOADED_DIVERSITYVC_GOAL: {
                const goals = action.payload;
                return Object.assign({},diversityvc,{goals})
            }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultDiversityvc
                }
            default:
                return diversityvc;
        }
    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
        return diversityvc
    }
};
