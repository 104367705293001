/* Mapping file to link English string payload values from backend
to JSON keys for translation. */

// Home
//// DiversityComposition

export const GROUP = {
    "Gender": "GROUP_TYPES.GENDER",
    "Ethnicity":  "GROUP_TYPES.ETHNICITY",
    "Sexual Orientation":  "GROUP_TYPES.SEXUAL_ORIENTATION",
    "Disability":  "GROUP_TYPES.DISABILITY",
}

export const GROUP_MISC = {
    "Prefer not to answer": "GROUP_MISC.PNTS"
}

export const GROUP_BREAKDOWN = {
    "Male": "GROUP.GENDER.MALE",
    "Female": "GROUP.GENDER.FEMALE",
    "Intersex": "GROUP.GENDER.INTERSEX", 
    "Two-Spirit": "GROUP.GENDER.TWO_SPIRIT", 
    "Other": "GROUP.GENDER.OTHER",
    // ETHNICITY
    "Asian": "GROUP.ETHNICITY.ASIAN", 
    "Black": "GROUP.ETHNICITY.BLACK",
    "Indigenous": "GROUP.ETHNICITY.INDIGENOUS",
    "Latin / Hispanic": "GROUP.ETHNICITY.LATIN_HISPANIC",
    "Middle Eastern": "GROUP.ETHNICITY.MIDDLE_EASTERN",
    "Jewish": "GROUP.ETHNICITY.JEWISH",
    "Mixed Race": "GROUP.ETHNICITY.MIXED_RACE",
    "Other": "GROUP.ETHNICITY.OTHER",
    // SEXUAL_ORIENTATION
    'Two-Spirit': 'GROUP.SEXUAL_ORIENTATION.TWO_SPIRIT',
    'Gay': 'GROUP.SEXUAL_ORIENTATION.GAY',
    'Asexual': 'GROUP.SEXUAL_ORIENTATION.ASEXUAL',
    'Bisexual': 'GROUP.SEXUAL_ORIENTATION.BISEXUAL',
    'Other': 'GROUP.SEXUAL_ORIENTATION.OTHER',
    'Queer': 'GROUP.SEXUAL_ORIENTATION.QUEER',
    'Pansexual': 'GROUP.SEXUAL_ORIENTATION.PANSEXUAL',
    'Lesbian': 'GROUP.SEXUAL_ORIENTATION.LESBIAN',
    'Transgender(yes)': 'GROUP.SEXUAL_ORIENTATION.TRANSGENDER',
    // DISABILITY
    'People with Physical disability': 'GROUP.DISABILITY.PHYSICAL',
    'People with Mental health issue': 'GROUP.DISABILITY.MENTAL',
    'People with Cognitive disability': 'GROUP.DISABILITY.COGNITIVE',
    'People with multiple disabilities': 'GROUP.DISABILITY.MULTIPLE'
}

export const GROUP_DOM_NONDOM = {
    "Male": "GROUP.GENDER.DOM",
    "Female": "GROUP.GENDER.NONDOM",
    'Transgender(yes)': 'GROUP.SEXUAL_ORIENTATION.TRANSGENDER',
    "White": "GROUP.ETHNICITY.DOM",
    "POC": "GROUP.ETHNICITY.NONDOM",
    "Heterosexual": "GROUP.SEXUAL_ORIENTATION.DOM",
    "LGBTQ+": "GROUP.SEXUAL_ORIENTATION.NONDOM",
    "LGBTQ2+": "GROUP.SEXUAL_ORIENTATION.NONDOM",
    "Persons with disability": "GROUP.DISABILITY.NONDOM",
    "Persons without disability": "GROUP.DISABILITY.DOM",
}

// Home
//// HeatMap

export const METRIC = {
    "Inclusive culture": "METRIC.INCLUSIVE",
    "Culture": "METRIC.INCLUSIVE",
    "Unbiased feedback & reviews": "METRIC.UNBIASED",
    "Unbiased feedback": "METRIC.UNBIASED",
    "Feedback & reviews": "METRIC.UNBIASED",
    "Access to networks": "METRIC.NETWORKS",
    "Flexible working options": "METRIC.FLEXIBLE",
    "Flexibility": "METRIC.FLEXIBLE",
    "Flexible work": "METRIC.FLEXIBLE",
    "Safe work environment": "METRIC.SAFE",
    "Safe Work": "METRIC.SAFE",
    "Safe work": "METRIC.SAFE",
    "Recruiting & Hiring": "METRIC.RECRUITING",
    "Recruiting & hiring": "METRIC.RECRUITING",
}

export const METRIC_TO_TOOLTIP = {
    "Inclusive culture": "HOME.HEATMAP.TOOLTIPS.INCLUSIVE_CULTURE",
    "Culture": "HOME.HEATMAP.TOOLTIPS.INCLUSIVE_CULTURE",
    "Unbiased feedback & reviews": "HOME.HEATMAP.TOOLTIPS.UNBIASED_FEEDBACK",
    "Unbiased feedback": "HOME.HEATMAP.TOOLTIPS.UNBIASED_FEEDBACK",
    "Unbiased Community": "HOME.HEATMAP.TOOLTIPS.UNBIASED_FEEDBACK",
    "Feedback & reviews": "HOME.HEATMAP.TOOLTIPS.UNBIASED_FEEDBACK",
    "Access to networks": "HOME.HEATMAP.TOOLTIPS.ACCESS_TO_NETWORKS",
    "Flexible working options": "HOME.HEATMAP.TOOLTIPS.FLEXIBLE_WORK",
    "Flexibility": "HOME.HEATMAP.TOOLTIPS.FLEXIBLE_WORK",
    "Flexible work": "HOME.HEATMAP.TOOLTIPS.FLEXIBLE_WORK",
    "Workplace Flexibility": "HOME.HEATMAP.TOOLTIPS.FLEXIBLE_WORK",
    "Safe work environment": "HOME.HEATMAP.TOOLTIPS.SAFE",
    "Safe Environment": "HOME.HEATMAP.TOOLTIPS.SAFE",
    "Safe Work": "HOME.HEATMAP.TOOLTIPS.SAFE",
    "Safe work": "HOME.HEATMAP.TOOLTIPS.SAFE",
    "Recruiting & Hiring": "HOME.HEATMAP.TOOLTIPS.RECRUITING",
    "Recruiting & hiring": "HOME.HEATMAP.TOOLTIPS.RECRUITING",
    "Member onboarding": "HOME.HEATMAP.TOOLTIPS.RECRUITING",
}

export const HEATMAP_SIDEBAR = {
    "Industry Average": "HOME.HEATMAP.SIDE_HEADER.INDUSTRY_AVERAGE",
    "Your KPI score": "HOME.HEATMAP.SIDE_HEADER.YOUR_KPI"
}

export const ANALYZE_HEATMAP_SIDEBAR = {
    "Your KPI score": "HOME.HEATMAP.SIDE_HEADER.YOUR_KPI",
    "Dominant group": "ANALYZE.HEATMAP.SIDE_HEADER.DOMINANT_GROUP",
    "People with disabilities": "GROUP_TYPES.DISABILITY",
    "LGBTQ+": "GROUP.SEXUAL_ORIENTATION.NONDOM",
    "Women": "GROUP.GENDER.NONDOM",
}

export const HEATMAP_TOOLTIPS = {
    "An environment where everyone can share their perspective regardless of appearance or background.": "HOME.HEATMAP.TOOLTIPS.INCLUSIVE_CULTURE",
    "A review and promotion process that is objective, fair, and free from unconscious bias.": "HOME.HEATMAP.TOOLTIPS.UNBIASED_FEEDBACK",
    "Having access to formal and informal networks without regard to appearance or background.": "HOME.HEATMAP.TOOLTIPS.ACCESS_TO_NETWORKS",
    "Enabling parents and other caregivers to balance work and home care obligations.": "HOME.HEATMAP.TOOLTIPS.FLEXIBLE_WORK",
    "A working environment that is free from sexual, physical and mental harassment.": "HOME.HEATMAP.TOOLTIPS.SAFE",
    "Recruiting and Hiring": "HOME.HEATMAP.TOOLTIPS.RECRUITING",
    "Recruiting & Hiring": "HOME.HEATMAP.TOOLTIPS.RECRUITING"
}

export const HEATMAP_SIDE_TOOLTIPS = {
    "The average score for organizations in your industry per KPI": "HOME.HEATMAP.SIDETOOLTIPS.INDUSTRY",
    "A measure of your organization’s performance per KPI based on survey data": "HOME.HEATMAP.SIDETOOLTIPS.YOUR_SCORE",
}

export const FOCUS_AREA_NAME = {
    "Networks": "METRIC.NETWORKS",
    "Feedback": "METRIC.UNBIASED",
    "Safe Environment": "METRIC.SAFE",
    "Culture": "METRIC.INCLUSIVE",
    "Flexibility": "METRIC.FLEXIBLE",
    "Recruiting & Hiring": "METRIC.RECRUITING"
}

export const FOCUS_AREA_SUBLABEL = {
    "Networks": "HOME.HEATMAP.TOOLTIPS.ACCESS_TO_NETWORKS",
    "Feedback": "HOME.HEATMAP.TOOLTIPS.UNBIASED_FEEDBACK",
    "Safe Environment": "HOME.HEATMAP.TOOLTIPS.SAFE",
    "Culture": "HOME.HEATMAP.TOOLTIPS.INCLUSIVE_CULTURE",
    "Flexibility": "HOME.HEATMAP.TOOLTIPS.FLEXIBLE_WORK",
    "Recruiting & Hiring": "HOME.HEATMAP.TOOLTIPS.RECRUITING"
}

// Analyze
//// Diversity Composition by Role

export const ROLE = {
    "Individual Contributor": "ANALYZE.ROLES.ANALYST",
    "Associate": "ANALYZE.ROLES.ASSOCIATE",
    "People Manager or Management": "ANALYZE.ROLES.MANAGER",
    "Vice President": "ANALYZE.ROLES.VICE_PRESIDENT",
    "Executive": "ANALYZE.ROLES.EXECUTIVE"
}

export const FILTER_LABELS = {
    "Office / Region": "ANALYZE.FILTERS.OFFICE",
    "year": "ANALYZE.FILTERS.YEAR",
    "office": "ANALYZE.FILTERS.OFFICE",
    "level": "ANALYZE.FILTERS.LEVEL",
    "department": "ANALYZE.FILTERS.DEPARTMENT",
    "tenure": "ANALYZE.FILTERS.TENURE",
}

// Improve
 export const KPI_CODES = {
    "DEALFLOW_SOURCES": "KPIS.DEALFLOW_SOURCES",
    "HUMAN_RESOURCES": "KPIS.HUMAN_RESOURCES",
    "INCLUSIVE_CULTURE": "KPIS.INCLUSIVE_CULTURE",
    "INTERNAL_POLICY": "KPIS.INTERNAL_POLICY",
    "PORTFOLIO_GUIDANCE": "KPIS.PORTFOLIO_GUIDANCE",
    "RECRUITING_HIRING": "KPIS.RECRUITING_HIRING"
 }
