import React, {Component} from 'react';
import BarChart from './BarChart';
import Filters, {Group, Office, Level, Department, FilterContext, FiltersSelected} from '../Filters'
import Filterss from './Filters';
import Legend from './Legend';
import './index.scss';
import MainCard from "../../utils/MainCard";
import {withTranslation} from "react-i18next";
import {getTranslation} from "../../../translations/utils";

class InclusionBreakdown extends Component {
    state = {
        values: {},
        isIndustryAverageSelected: false
    };

    _onChange (values) {
        this.setState({
            ...this.state,
            values
        })
    }

    _toggleIndustryAverage () {
        this.setState({
            ...this.state,
            isIndustryAverageSelected: !this.state.isIndustryAverageSelected
        })
    }

    render() {
        const { companyName, t } = this.props;

        return (
            <MainCard title={t("ANALYZE.CARD_HEADERS.INCLUSION_BREAKDOWN.title")}
                      toolTipContent={getTranslation("ANALYZE.CARD_HEADERS.INCLUSION_BREAKDOWN.tooltip", companyName)}>
                <div id="inclusion-breakdown" className={"bootstrap-override"}>
                    <Filterss company={companyName} filters={this.props.filters} applyFilters={this.props.handleApplyFilters} onChange={this._onChange.bind(this)}/>
                    <FiltersSelected 
                    values={this.state.values} 
                    allFilters={this.props.filters}
                        companyName={this.props.companyName}
                    />
                    <Legend selectedFilters={this.state.values}
                            allFilters={this.props.filters}
                            isIndustryAverageSelected={this.state.isIndustryAverageSelected}
                            toggleIndustryAverage={this._toggleIndustryAverage.bind(this)}
                            companyName={"industry"}/>
                    <BarChart data={this.props.data.data} showMessage={this.props.showMessage} colors={this.props.colors}/>
                </div>
            </MainCard>
        );
    };
}

export default  withTranslation()(InclusionBreakdown);
