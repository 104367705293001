import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Actions} from '../actions';
import './App.scss';
import Analytics from "../components/Analytics";

class AnalyticsContainer extends Component {
    componentWillMount() {
        this.props.getAnalyticsUserPortfolio();
        this.props.getAnalyticsCompanyList();
        this.props.getAnalyticsKpiMap();
        this.props.getCompanyInformation();
        this.props.getIndexScores();
    }

    render() {
        return <Analytics { ...this.props } />;
    }
}

const mapStateToProps = state => {
    return {
        companyInformation: state.home.companyInformation,
        analytics: state.analytics,
        ...state.auth,
    };
};

export default connect(mapStateToProps, Actions)(AnalyticsContainer);
