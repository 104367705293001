import React, {Component} from 'react';
import './index.scss';
import _ from 'underscore';
import {Button} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import classNames from "classnames";
import Department from './Department';
import Kpis from './Kpis';
import Level from './Level';
import Office from './Office';
import Tenure from './Tenure';
import Year from './Year';
import FiltersSelected from "./FiltersSelected";

const FilterContext = React.createContext();
class Filters extends Component {
    state = {
        // component to backend data key mapping
        dataMap: {
            'Year': 'years',
            'Office': 'offices',
            'Level': 'levels',
            'Department': 'departments',
            'Tenure': 'tenures',
            'Kpis': 'kpis',
            'Group': 'groups'
        },
        valMap: {
            'Year': 'year',
            'Office': 'office',
            'Level': 'level',
            'Department': 'department',
            'Tenure': 'tenure',
            'Kpis': 'kpi',
            'Group': 'group'
        },
        values: {}
    };

    _onChange (type, value) {
        this.setState({
            values: {
                ...this.state.values,
                [type]: value
            }
        });

        // Send back the filters values on next tick
        setTimeout(() => this.props.onChange && this.props.onChange(this.state.values), 0);
    }

    _applyFilters () {
        this.props.applyFilters && this.props.applyFilters(this.state.values);

        this.props.filterWasApplied && this.props.filterWasApplied();
    }

    _clearFilters () {
        this.setState({
            ...this.state,
            values: {
                ...Object.keys(this.state.values).reduce((all, key) => {
                    all[key] = [];
                    return all;
                }, {})
            }
        });
        this.props.clearFilters && this.props.clearFilters(this.state.values);
    }

    render() {
        const { filters, submittable = true, hidden, t } = this.props;
        const { values, dataMap, valMap } = this.state;
        if (_.isEmpty(filters.years)) { return null; }

        return (
            <React.Fragment>
                <div className={classNames("filters-container bootstrap-override", { hidden: hidden })}>
                    <FilterContext.Provider value={{
                        data: filters,
                        metadata: filters.metadata,
                        onChange: this._onChange.bind(this),
                        values: values,
                        dataMap: dataMap,
                        valMap: valMap
                    }}>
                        { this.props.children }
                    </FilterContext.Provider>
                    {
                        submittable
                            ? <div className="filter-actions">
                                <Button variant="primary" className="apply-filter" onClick={this._applyFilters.bind(this)}>{ t("ANALYZE.FILTERS.SUBMIT") }</Button>
                                <Button variant="light" className="clear-filter" onClick={this._clearFilters.bind(this)}>{ t("ANALYZE.FILTERS.CLEAR") }</Button>
                            </div>
                            : null
                    }
                </div>


            </React.Fragment>
        );
    }
}

export default withTranslation()(Filters);
export {
    Department,
    Kpis,
    Level,
    Office,
    Tenure,
    FilterContext,
    FiltersSelected,
    Year
}
