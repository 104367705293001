import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './index.scss'
import {Column, Row} from "../utils/FlexBox";
import history from "../../history";
import AnalyticsSearch from './AnalyticsSearch';
import AnalyticsPortfolio from './AnalyticsPortfolio';

const AnalyticsHome = (props) => {
    const { pathname } = history.location;
    useEffect(() => {
        if (["/analytics", "/"].includes(pathname)) {
            history.replace("/analytics/search");
        }
    }, [pathname]);


    let headerTitle = "Portfolio Analytics";
    let subtitle = null;
    const { loggedinUserId } = props;
    if (loggedinUserId === 72) /* sometimescasey: super ugly hack for demo. remove this when done */ {
        headerTitle = "Diversio Public Insights"
        subtitle = "Begin by searching for a company to view their Diversio Inclusion score and DEI metrics."
    }


    const renderComp = (pathname) => {
        switch(pathname) {
            case '/analytics/search':
                return <AnalyticsSearch { ...props } subtitle={subtitle} />;
            case '/analytics/portfolio':
                return <AnalyticsPortfolio { ...props } />;
        }
    };
    

    return (
        <div>
            <div className="App">
                <Row>
                    <Column grow={1}>
                        <h1 className={"transparent-header"}>{headerTitle}</h1>
                    </Column>
                </Row>
                <Row>
                    <Column grow={1}>
                        <div className={"bootstrap-override"}>
                            <Nav className={"analytics-nav"} justify variant="tabs" defaultActiveKey="/analytics/search">
                                <Nav.Item>
                                    <Nav.Link active={history.location.pathname === "/analytics/search"} as={Link} to="/analytics/search">Search</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link active={history.location.pathname === "/analytics/portfolio"} as={Link} to="/analytics/portfolio">My Portfolio</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Column>
                </Row>

                {
                    renderComp(history.location.pathname)
                }
            </div>
        </div>
    );
};

export default AnalyticsHome;
