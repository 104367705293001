import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import { Row, Column } from '../components/utils/FlexBox';
import './App.scss';
import InclusionScore from '../components/PortfolioHome/InclusionScore';
import Certification from '../components/PortfolioHome/Certification';
import Overview from '../components/PortfolioHome/Overview';
import DiversityBreakdown from '../components/PortfolioHome/DiversityBreakdown';
import Benchmark from '../components/PortfolioHome/Benchmark';
import Portfolio from '../components/PortfolioHome/Portfolio';
import InclusiveInvesting from '../components/PortfolioHome/InclusiveInvesting';
import DiversitySnapshot from "../components/PortfolioHome/DiversitySnapshot";

class PortfolioHome extends Component {
    componentWillMount () {
        this.props.getInclusionScoreData(this.props.token);
        this.props.getInclusionMetricsData();
        this.props.getCompanyFilters();
        this.props.getDiversityBreakdownData({ data: { offices: "%", roles: "%" } });
    }

    _getDiversityBreakdownData (data) {
        this.props.getDiversityBreakdownData({ data });
    }

    render() {
        return (
            <div className="App">
                <Row>
                    <Column width="30%">
                        <InclusionScore data={this.props.inclusionScore} isDeploySurveyProcessing={this.props.isDeploySurveyProcessing} deploySurvey={this.props.doDeploySurvey} />
                    </Column>
                    <Column width="70%">
                        <Benchmark />
                    </Column>
                </Row>
                <Row>
                    <Column grow={1}>
                        <Overview />
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <InclusiveInvesting />
                    </Column>
                </Row>
                <Row>
                    <Column grow={1}>
                        {
                            this.props.diversityBreakdown.length > 0
                            ? <DiversitySnapshot colors={this.props.colors}
                                                 data={this.props.diversityBreakdown}
                                                 groups={this.props.extendedCategories}
                                                 filters={this.props.filters}
                                                 handleApplyFilters={this._getDiversityBreakdownData.bind(this)}
                                                 showMessage={this.props.showMessage}/>
                            : null
                        }
                    </Column>
                </Row>
                <Row>
                    <Column grow={1}>
                        <Portfolio />
                    </Column>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.analyze,
        ...state.home,
        colors: state.colors
    };
};

export default connect(mapStateToProps, Actions)(PortfolioHome);
