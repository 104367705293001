import React, { useContext } from 'react';
import Select from "../../utils/Select";
import { FilterContext } from "./index";

const Level = ({ label = "Level", valId = "level", dataId = "levels" }) => {
    const { data, onChange, values } = useContext(FilterContext);
    return <Select options={data[dataId]} label={ label } multiple={true} onChange={value => onChange(valId, value)} selectedValues={values[valId] || data[dataId]}/>
};

Level.className = "Level";

export default Level;