import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {demographicMap, demographicToName} from "../../../../utils/demographicMapping";
import './index.scss';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

// chose random number for department/office value b.c. unlikely that users will have a department w/ this name
const DEPARTMENT_ALL_VALUE = 148969243482;
const OFFICE_ALL_VALUE = 148969243484;

class TargetModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal1: false,
            showModal2: false,
            showModal3: false,
            targetChosen: this.props.isShowing,
            disable: this.props.isShowing,
            isOnTrack: null,
            form : {
                "target_demographic": this.props.data.target_demographic,
                "year": this.props.data.target_year,
                "level": this.props.data.level,
                "department": this.props.data.department===null||this.props.data.department===''?DEPARTMENT_ALL_VALUE:this.props.data.department,
                "office": this.props.data.office===null||this.props.data.office===''?OFFICE_ALL_VALUE:this.props.data.office,
                "goal": this.props.data.target_percent,
                "current": this.props.data.current_percent,
                "on_track": this.props.data.isOnTrack
            }
        }
    }

    _getRenderValue = (value) => {
        return <MenuItem className="menu-item" value={value}>{value}</MenuItem>
    }


    handleSave = () => {  
        let level = this.props.filterMap.levels.find(level=>level.label===this.state.form.level);
        level = (level===undefined || this.state.form.level===null||this.state.form.level==='All')?null:level.id;
        let department = this.state.form.department===DEPARTMENT_ALL_VALUE?'':this.state.form.department
        let office = this.state.form.office===OFFICE_ALL_VALUE?'':this.state.form.office

        if (this.props.isEdit) {
            this.props.handleUpdate({
                "id": this.props.data.id,
                "target_year": Number(this.state.form.year),
                "target_demographic": this.state.form.target_demographic,
                "level": level,
                department,
                "office": office,
                "target_percent": Number(this.state.form.goal)
            })
            this.props.closeModal();
        } else {
            if (!(this.state.form.department && this.state.form.goal && this.state.form.year && this.state.form.office && this.state.form.target_demographic)) {
                this.props.showMessage({type: "error", message: "Please fill in all fields."})
            } else {
                this.props.handleSave({
                    "target_year": Number(this.state.form.year),
                    "target_demographic": this.state.form.target_demographic,
                    "level": level,
                    department,
                    "office": office,
                    "target_percent": Number(this.state.form.goal)
                })
                this.props.closeModal();
            }
        }
    }

    handleDemographicSelect (e){
        let value = e.target.value.option;
        // TODO this is hacky and dangerous (eg. e.target.value not in demographic map) change at some point
        
        if (e.target.value.option === "Yes") {
            value = "Person with Disability";
        } else if (e.target.value.option === "No") {
            value = "Person without Disability";
        }
        this.setState({
            form: {
                ...this.state.form,
                'target_demographic': demographicMap[value],
            }
        });
    }

    handleSelect = (e) => {
        const name = e.target.name;
        let formName;
        let value;
        if (typeof(e.target.value) === 'object') {
            const formValue = e.target.value.option;
            if (e.target.value.label === 'Persons with disability' && e.target.value.option === 'Yes') {
                value = `Person with Disability`;
            } else if (e.target.value.label === 'Persons with disability' && e.target.value.option === 'No') {
                value = `Person without Disability`;
            } else {
                value = e.target.value.option;
            }
            
            if (e.target.value.key === 'genders') {
                formName = 'gender';
            } else if (e.target.value.key === 'orientations') {
                formName = 'sexual_orientation';
            } else if (e.target.value.key === 'disabilities') {
                formName = 'disability';
            } else if (e.target.value.key === 'ethnicities') {
                formName = 'ethnicity';
            }
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value,
                    [formName]: formValue
              }
            });
        } else {
            value = e.target.value

            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value,
              }
            });
        }
    }

    render() {
        const levels = ["All", ...this.props.filters.levels];

        return (
            <Dialog
                    open={this.props.isModalOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.closeModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-content"
                    maxWidth='sm'
                    id="target-modal"
                >
                    <DialogTitle id="form-dialog-title" className="target-header">Diversity Target</DialogTitle>
                    <DialogContent className="target-form-container">
                        <div className="target-form">
                            <div className="input-group-container">
                                <div className="input-group">
                                    <InputLabel>Target Group</InputLabel>
                                    <TextField
                                        required
                                        select
                                        name="target_demographic"
                                        value={demographicToName(this.state.form.target_demographic)}
                                        onChange={(e) => this.handleDemographicSelect(e)}
                                        margin="normal"
                                        className="text-field"
                                        SelectProps={{
                                            renderValue: selected => this._getRenderValue(selected)
                                        }}
                                    >   
                                        {
                                            this.props.demographicTarget.map((option, id) => {
                                                if (typeof(option) === "object") {
                                                    const key = option.key;
                                                    const label = option.label;
                                                    return option.options.reduce((allOptions, option, id) => {
                                                        if (id === 0) {
                                                            allOptions.push(<MenuItem value={label} disabled>{label}</MenuItem>)
                                                        }
                                                        allOptions.push(<MenuItem value={{key, label, option}}>{option}</MenuItem>)
                                                        return allOptions;
                                                    }, [])
                                                }
                                            })
                                        }
                                    </TextField>
                                </div>
                                <div className="input-group">
                                    <InputLabel>Level</InputLabel>
                                    <TextField
                                        required
                                        select
                                        name="level"
                                        value={(this.state.form.level===null || this.state.form.level==='') ? 'All' : this.state.form.level}
                                        onChange={(e) => this.handleSelect(e)}
                                        margin="normal"
                                        className="text-field"
                                    >
                                        {
                                            levels.map((option, id) => (
                                                <MenuItem key={id} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </div>
                            </div>
                            <div className="input-group-container">
                                <div className="input-group">
                                    <InputLabel>Department</InputLabel>
                                    <TextField
                                        required
                                        select
                                        name="department"
                                        value={this.state.form.department === '' ? 'All' : this.state.form.department}
                                        onChange={(e) => this.handleSelect(e)}
                                        margin="normal"
                                        className="text-field"
                                    >
                                        {
                                            // Uhg, material UI default for empty string clears the field - giving placeholder value of 'All' then mapping in back to ''
                                            [{label:'All', value: DEPARTMENT_ALL_VALUE},...this.props.filters.departments.map(x=>({label:x,value:x}))].map((option, id) => (
                                                <MenuItem key={id} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </div>
                                <div className="input-group">
                                    <InputLabel>Office / Region</InputLabel>
                                    <TextField
                                        required
                                        select
                                        name="office"
                                        value={this.state.form.office === '' ? 'All' : this.state.form.office}
                                        onChange={(e) => this.handleSelect(e)}
                                        margin="normal"
                                        className="text-field"
                                    >
                                        {
                                            [{label:'All', value: OFFICE_ALL_VALUE},...this.props.filters.offices.map(x=>({label:x,value:x}))].map((option, id) => (
                                                <MenuItem key={id} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </div>
                            </div>
                            <div className="input-group-container">
                                <div className="input-group">
                                    <InputLabel>Enter Goal Percentage</InputLabel>
                                    <TextField
                                        required
                                        name="goal"
                                        label="e.g. 45%"
                                        value={this.state.form.goal}
                                        onChange={(e) => this.handleSelect(e)}
                                        margin="normal"
                                        variant="outlined"
                                        className="text-field"
                                    >
                                    </TextField>
                                </div>
                                <div className="input-group">
                                    <InputLabel>Target Year</InputLabel>
                                    <TextField
                                        required
                                        name="year"
                                        label="e.g. 2020"
                                        value={this.state.form.year}
                                        onChange={(e) => this.handleSelect(e)}
                                        margin="normal"
                                        variant="outlined"
                                        className="text-field"
                                    >
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="action-container">
                        <Button onClick={() => {this.handleSave()}} className="save-button">
                            Save
                        </Button>
                        <Button onClick={() => {this.props.handleCancel()}}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    };
};

export default TargetModal;
