import React, {Component} from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import _ from 'underscore';
import "./index.scss"
import { mapChoiceLabel } from "../../../utils/labels";

class SelectBox extends Component {
    state = {
        selectedValues: []
    };

    componentWillMount () {
        let { options, multiple, selectedValues } = this.props;
        let allOptions = options;

        if (!selectedValues) {
            selectedValues = multiple ? [] : "";
        }

        if (this._isNestedOptions()) {
            allOptions = allOptions.reduce((all, option) => {
                return [
                    ...all,
                    ...option.options.map(opt => `${option.key}:${opt}`)
                ]
            }, [])
        }

        this.setState({ selectedValues, allOptions });
        setTimeout(() => this.props.onChange && this.props.onChange(selectedValues));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.selectedValues !== prevProps.selectedValues) {
            this.setState({
                ...this.state,
                selectedValues: prevProps.selectedValues
            });
        }

        if (this.state.allOptions !== this.props.options) {
            this.setState({
                ...this.state,
                allOptions: this.props.options
            });
        }
    }

    handleChange = e => {
        let selectedValues = e.target.value;

        if (e.currentTarget.dataset.value === "selectall") {
            selectedValues = this.state.allOptions;
            if (this._isAllSelected()) {
                selectedValues = [];
            }
        }

        this.setState({ selectedValues });
        this.props.onChange && this.props.onChange(selectedValues);
    };

    mapChoiceLabel (option) {
        if (this.props.mapChoiceLabel) {
            return this.props.mapChoiceLabel(option);
        } else {
            return mapChoiceLabel(option);
        }
    }

    _isNestedOptions () {
        return _.isObject(this.props.options[0]);
    }

    _isAllSelected () {
        const { allOptions, selectedValues } = this.state;
        return allOptions.length === selectedValues.length;
    }

    _getRenderValue (values) {
        if (this.props.renderValue) {
            return this.props.renderValue(values);
        }

        if (typeof values === "object") {
            return values.reduce((combined, value, index) => {
                combined += (index !== 0 ? ', ' : '');

                if (value.indexOf(':') === -1) {
                    combined += value;
                } else {
                    combined += value.split(':')[1]
                }

                return this.mapChoiceLabel(combined);
            }, '')
        } else {
            return this.mapChoiceLabel(values);
        }
    }

    render() {
        const { options = [], optionType = "checkbox", multiple = false, label = "", disabledOptions = [],
        extraClasses = null } = this.props;
        const MenuProps = {
            variant: "menu",
            getContentAnchorEl: null
        };

        return (
            <React.Fragment>
                <FormControl className={`select-box ${ extraClasses || null }`}>
                    <InputLabel htmlFor="select-multiple-checkbox">{ label || "Select Type" }</InputLabel>
                    <Select
                        multiple={multiple}
                        value={this.state.selectedValues}
                        onChange={e => this.handleChange(e)}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => this._getRenderValue(selected)}
                        MenuProps={MenuProps}
                    >
                        {
                            multiple
                                ? <MenuItem value={"selectall"}>
                                    <Checkbox color={"primary"} checked={this._isAllSelected()}/>
                                    <ListItemText primary={"Select all"} />
                                </MenuItem>
                                : null
                        }

                        {
                            options.map((option, id) => {
                                if (typeof option === "object") {
                                    const key = option.key;
                                    const label = option.label;
                                    return option.options.reduce((allOptions, option, id) => {
                                        if (id === 0) {
                                            allOptions.push(<MenuItem value={label} disabled>{ label }</MenuItem>);
                                        }
                                        if (optionType === "checkbox" && multiple) {
                                            allOptions.push(<MenuItem key={id} value={`${key}:${option}`} disabled={disabledOptions.indexOf(option) > -1}>
                                                <Checkbox color={"primary"} checked={this.state.selectedValues.indexOf(`${key}:${option}`) > -1} />
                                                <ListItemText primary={this.mapChoiceLabel(option)} />
                                            </MenuItem>);
                                        } else {
                                            allOptions.push(<MenuItem value={option} disabled={disabledOptions.indexOf(option) > -1}>{ this.mapChoiceLabel(option)}</MenuItem>);
                                        }

                                        return allOptions;
                                    }, [])
                                }

                                if (optionType === "checkbox") {
                                    return <MenuItem key={id} value={option} disabled={disabledOptions.indexOf(option) > -1}>
                                        <Checkbox color={"primary"} checked={this.state.selectedValues.indexOf(option) > -1} />
                                        <ListItemText primary={this.mapChoiceLabel(option)} />
                                    </MenuItem>
                                }

                                return <MenuItem key={id} value={option} disabled={disabledOptions.indexOf(option) > -1}>{ this.mapChoiceLabel(option)}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    }
}

export default SelectBox;
