import React, { Component } from 'react';
import './index.scss';
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';

const PNTS_STRING = "Prefer not to answer";

export const reorder = (compData) => {
    // temp: make sure PNTS is last
    let newCompData = [];
    let pntsItem = null;
    for (const d of compData) {
        if (d.name === PNTS_STRING) {
            pntsItem = d;
        } else {
            newCompData.push(d);
        }
    }
    if (pntsItem) {
        newCompData.push(pntsItem);
    }

    return newCompData;
}

export const DiversitySnapshotDetail = (props) => {
    const { compositionData, breakdownData, ndg, title, profile } = props;

    // move out to utils
    const mapListToObj = (breakdown) => {
        let breakdownObj = {};
        for (const dimension of breakdown) {
            const firstKey = Object.keys(dimension)[0];
            breakdownObj[firstKey] = dimension[firstKey];
        }
        return breakdownObj;
    }

    const reshapeDisability = (breakdown) => {
        // Shape of this payload is different: https://app.clickup.com/t/7ew52q
        // It is also sometimes not present for companies whose surveys did not include disability breakdowns
        if ((!breakdown || !breakdown[0]) || !breakdown[0]["name"]) {
            return null;
        }
        let disabilityBreakdown = {};
        for (var idx in breakdown) {

            const yesIndex = breakdownData[idx]["value"].findIndex(obj => (obj["name"] === "Yes"))
            disabilityBreakdown[breakdownData[idx]["name"]] = breakdownData[idx]["value"][yesIndex]["value"]
        }

        return disabilityBreakdown;
    }

    let breakdown;
    if (title === "Disability") {
        breakdown = reshapeDisability(breakdownData);
    } else {
        breakdown = breakdownData;
    }

    const getDomGroup = (compositionData, ndg) => {
        return compositionData.filter(d => (d.name !== ndg && d.name !== PNTS_STRING))[0].name; 
    }

    const getNdgList = (breakdown, compositionData, ndg) => {
        if (!breakdown) {
            return null;
        }
        // remove members of compositionData from breakdown to get NDG members
        let ndgList = {};
        const dg = getDomGroup(compositionData, ndg);
        for (const member of Object.keys(breakdown)) {
            if (member !== dg && member !== PNTS_STRING) {
                ndgList[member] = breakdown[member];
            }
        }
        return ndgList;
    }

    const ndgList = getNdgList(breakdown, compositionData, ndg);
    
    

    return (
        <div className="diversity-snapshot-detail">
            <div className="diversity-snapshot-detail__title">
                {title}
            </div>
            <div className="diversity-snapshot-detail__bullets">
                    {reorder(compositionData).map(d => {

                        return (
                        <>
                        <div className="diversity-snapshot-detail__detail-row">
                            <div className="diversity-snapshot-detail__detail-name">{
                                profile.email == 'amazing_investor_demo_2@diversio.com' && d.name == 'White'
                                ?
                                'Dominant group' :
                                mapT(m.GROUP_DOM_NONDOM, d.name)}</div>
                            <div className="diversity-snapshot-detail__detail-number">{`${d.value}%`}</div>
                        </div>
                        <>{ ((d.name === ndg && ndgList) && (
                            <div className="diversity-snapshot-detail__ndg-breakdown">
                                <div className="diversity-snapshot-detail__ndg-list-wrapper">
                                    <ul>
                                    {Object.keys(ndgList).map(k => (
                                        <li className="diversity-snapshot-detail__ndg-breakdown-item">
                                            <div className="diversity-snapshot-detail__ndg-breakdown-name">{mapT(m.GROUP_BREAKDOWN, k)}</div>
                                            <div className="diversity-snapshot-detail__ndg-breakdown-number">{`${ndgList[k]}%`}</div>
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                            </div>
                        )) }</>
                    </>
                    )
                    }
                     )}
            </div>
            <div className="diversity-snapshot-detail__end-spacer">

            </div>
        </div>
    );
}
