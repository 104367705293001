import React from 'react';

export const OVERVIEW_TITLE = "Diversio Score";
export const OVERVIEW_CONTENT = (
    <span>
        The Diversio Score is calculated as a weighted combination of the following:
        <ul>
            <li>25% - Diversity Score</li>
            <li>50% - Inclusion Score</li>
            <li>25% - Commitment Score</li>
        </ul>
        This weighting hedges against the possibility of assigning a high ranking to a company in a scenario where a company is diverse, but employees from underrepresented backgrounds are subject to racism, bias or harassment.
    </span>);

export const EXECUTIVE_TITLE = "Executive Diversity"
export const EXECUTIVE_CONTENT = (
    <span>
        The Diversity Score is calculated based on gender and racial/ethnic diversity of a company’s executive team and board. Both data sources are used if available; otherwise the score is based on one or the other.
        <br/><br/>
        To get a perfect score, a company must have at least 50% women and 40% racial and ethnic minorities at the C-suite level.
    </span>
);

export const BOARD_TITLE = "Board Diversity"
export const BOARD_CONTENT = (
    <span>
        The Diversity Score is calculated based on gender and racial/ethnic diversity of a company’s executive team and board. Both data sources are used if available; otherwise the score is based on one or the other.
        <br/><br/>
        To get a perfect score, a company must have at least 50% women and 40% racial and ethnic minorities at the board level.
    </span>
);


export const INCLUSION_TITLE = "Inclusion Score and Pain Points"
export const INCLUSION_CONTENT = (
    <span>
        <strong>Inclusion Score</strong>
        <br/>
        The Inclusion Score is calculated based on 6 Key Performance Indicators (KPIs):
        <ul>
            <li>Inclusive Culture</li>
            <li>Fair Management</li>
            <li>Career Development</li>
            <li>Work-Life Balance</li>
            <li>Safe Work Environment</li>
            <li>Recruiting & Hiring</li>
        </ul>
        
        The score for each KPI is calculated by taking the average of its composite pain point scores. You can see the frequency of pain points under each KPI by selecting a KPI in the table header.
        <br/><br/>
        KPIs are centered such that a company performing at the median within their industry would receive a score of 5.
        <br/><br/>
        <strong>Pain Point Score</strong>
        <br/><br/>
        A <strong>higher</strong> pain score is better.

        <br/><br/>
        To detect pain points, Diversio scraped thousands of employee reviews available on public platforms such as Glassdoor. Diversio analyzed these reviews using proprietary Deep Learning algorithms to understand the frequency with which employees cited an inclusion pain point.
        <br/><br/>
        A KPI score is tied to (1) how often the underlying pain points appear for the company and (2) how often these pain points appear across the respective industry:
        <ul>
            <li>A perfect score (10) indicates that the company had the lowest frequency of this pain point within their industry</li>
            <li>A score of 5 indicates that the pain points appeared at the same frequency as the industry median</li>
            <li>A score of 0 indicates that the pain points appeared more frequently at this company than any other company in the industry</li>
        </ul>
        <br/>
        The impact of a pain point on the overall KPI depends on the range observed for that painpoint; i.e. a pain point which is only slightly below industry median may nevertheless contribute to a high KPI score if the observed range is very narrow.
    </span>
);

export const COMMITMENT_TITLE = "Commitment Score"
export const COMMITMENT_CONTENT = (
    <span>
        To calculate the Commitment Score we assess 4 areas of the organization and measure each on a 0-3 scale based on how much progress they have made in that area. 
<ol>
<li>Policies in place to track, review, and improve effectiveness on DEI initiatives</li>
<li>Talent acquisition strategy to recruit diverse talent and increase minority representation</li>
<li>Employee engagement to support diverse talent and ensure a safe and inclusive workplace</li>
<li>Commitment of transparency and data disaggregation to disclose organizational diversity</li>
</ol>
        If this level of detail is not present, then the Commitment Score is binary placeholder as follows:
        <ul>
        <li>If a company has made a public commitment or CEO pledge to diversity & inclusion, it receives 100%.</li>
        <li>If a company has not made a commitment or pledge, it receives 0%.</li>
        </ul>
    </span>
);
