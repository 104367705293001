import { ActionTypes } from '../actions';

const defaultState = {
    isLoading: false,
    isCompleted: false,
    isSurveyCompleted: false,
    email: "",
    questions: [],
    isEmailResponseLoaded: false
    // questions: [
    //     {
    //         "question": "What was your revenue in 2018? (Millions $) #blank",
    //         "type": "number",
    //         "label": "revenue",
    //         "createdAt": 1547535509411,
    //         "updatedAt": 1547535509411,
    //         "id": 385
    //     },
    //     {
    //         "question": "What was your profit in 2018? (Millions $) #blank",
    //         "type": "number",
    //         "label": "profit",
    //         "createdAt": 1547535509411,
    //         "updatedAt": 1547535509411,
    //         "id": 386
    //     },
    //     {
    //         "question": "What is your sector? #options",
    //         "limit": 1,
    //         "options": [
    //             "Consumer goods",
    //             "Energy",
    //             "Basic materials",
    //             "Financial services",
    //             "Food & beverage",
    //             "Health care & pharma",
    //             "Manufacturing",
    //             "Utilities",
    //             "Technology",
    //             "Telecom"
    //         ],
    //         "label": "sector",
    //         "createdAt": 1547535509411,
    //         "updatedAt": 1547535509411,
    //         "id": 387
    //     },
    //     {
    //         "question": "How many employees do you have? #options",
    //         "limit": 1,
    //         "options": [
    //             "0-1,000",
    //             "1,000-5,000",
    //             "5,000-10,000",
    //             "10,000+"
    //         ],
    //         "label": "employees",
    //         "createdAt": 1547535509411,
    //         "updatedAt": 1547535509411,
    //         "id": 388
    //     },
    //     {
    //         "question": "What region do you operate in? #options",
    //         "limit": 1,
    //         "options": [
    //             "Latin America",
    //             "China",
    //             "Sub-Saharan Africa",
    //             "North America",
    //             "Oceania",
    //             "Middle East and North Africa",
    //             "South Asia (excl India)",
    //             "Western Europe",
    //             "Eastern Europe and Central Asia",
    //             "East and Southeast Asia (excl China)"
    //         ],
    //         "label": "region",
    //         "createdAt": 1547535509411,
    //         "updatedAt": 1547535509411,
    //         "id": 389
    //     },
    //     {
    //         "question": "What are the top 3 ways diversity would improve your bottom line? #options",
    //         "limit": 3,
    //         "options": [
    //             "Attract top talent",
    //             "Reduce employee turnover",
    //             "Improve productivity and knowledge-sharing",
    //             "Reduce workplace harassment",
    //             "Drive innovation",
    //             "Enhance decision-making",
    //             "Improve reputation",
    //             "Reduce fraud, corruption and bribery",
    //             "Improve corporate social responsibility",
    //             "Identify new market opportunities",
    //             "Improve customer service"
    //         ],
    //         "label": "improvement",
    //         "createdAt": 1547535509411,
    //         "updatedAt": 1547535509411,
    //         "id": 390
    //     }
    // ]
};

export default (state = defaultState, action) => {
    try {

        switch(action.type) {

            case ActionTypes.LOADING_QUESTIONS:
                return {
                    ...state,
                    isLoading: true
                };

            case ActionTypes.LOADED_QUESTIONS:
                return {
                    ...state,
                    isLoading: false,
                    questions: action.payload
                };

            case ActionTypes.SET_SURVEY_COMPLETED:
                return {
                    ...state,
                    isSurveyCompleted: action.payload
                };

            case ActionTypes.DONE_SUBMIT_RESPONSE:
                return {
                    ...state,
                    isSurveyCompleted: action.payload,
                    response: action.payload
                };

            case ActionTypes.HANDLE_EMAIL_CHANGE:
                return {
                    ...state,
                    email: action.payload
                };

            case ActionTypes.DONE_SAVE_EMAIL:
                return {
                    ...state,
                    isCompleted: true
                };

            case ActionTypes.LOADED_EMAIL_RESPONSE:
                return {
                    ...state,
                    isEmailResponseLoaded: true,
                    response: action.payload
                };

            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }

};