import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { loadPainpointData, loadDiversityCompositionData, loadMetricOverviewData
    , loadFocusAreaData, loadInclusionScoreData, loadCompanyInformation,
    loadExecSummaryData, deploySurvey, informPurchase, } from './network';

// All data sagas to add to middleware.
export default [
    [
        ActionTypes.GET_PAINPOINT_DATA
        , getPainpointData
    ],
    [
        ActionTypes.GET_DIVERSITY_COMPOSITION_DATA
        , getDiversityCompositionData
    ],
    [
        ActionTypes.GET_METRIC_OVERVIEW_DATA
        , getMetricOverviewData
    ],
    [
        ActionTypes.GET_FOCUS_AREA_DATA
        , getFocusAreaData
    ],
    [
        ActionTypes.GET_INCLUSION_SCORE_DATA
        , getInclusionScoreData
    ],
    [
        ActionTypes.GET_EXEC_SUMMARY_DATA
        , getExecSummaryData
    ],
    [
        ActionTypes.DO_DEPLOY_SURVEY,
        doDeploySurvey
    ],
    [
        ActionTypes.DO_INFORM_PURCHASE,
        doInformPurchase
    ],
    [
        ActionTypes.GET_COMPANY_INFORMATION,
        getCompanyInformation
    ]
];

function * getPainpointData ({payload}) {
    yield put(Actions.loadingPainpointData());

    const response = yield call(loadPainpointData, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedPainpointData(response.data));
    }
}

function * getDiversityCompositionData ({payload}) {
    yield put(Actions.loadingDiversityCompositionData());

    const response = yield call(loadDiversityCompositionData, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedDiversityCompositionData(response.data));
    }
}

function * getMetricOverviewData ({payload}) {
    yield put(Actions.loadingMetricOverviewData());

    const response = yield call(loadMetricOverviewData, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedMetricOverviewData(response.data));
    }
}


function * getFocusAreaData ({payload}) {
    yield put(Actions.loadingFocusAreaData());

    const response = yield call(loadFocusAreaData, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedFocusAreaData(response.data));
    }
}


function * getInclusionScoreData ({payload}) {
    yield put(Actions.loadingInclusionScoreData());

    const response = yield call(loadInclusionScoreData, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedInclusionScoreData(response.data));
    }
}

function * getExecSummaryData ({payload}) {
    yield put(Actions.loadingExecSummaryData());

    const response = yield call(loadExecSummaryData, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedExecSummaryData(response.data));
    }
}

function * doDeploySurvey () {
    yield put(Actions.doingDeploySurvey());

    const response = yield call(deploySurvey);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
        yield put(Actions.doneDeploySurvey());
    } else {
        yield put(Actions.doneDeploySurvey());
        yield put(Actions.showMessage({type: "success", message: "All set! you will be contacted by our executive soon"}));
    }
}

function * doInformPurchase () {
    yield put(Actions.doingInformPurchase());

    const response = yield call(informPurchase);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
        yield put(Actions.doneInformPurchase());
    } else {
        yield put(Actions.doneInformPurchase());
    }
}

function * getCompanyInformation () {
    yield put(Actions.loadingCompanyInformation());

    const response = yield call(loadCompanyInformation);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedCompanyInformation(response.data));
    }
}
