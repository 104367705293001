import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { makeStyles } from '@material-ui/core/styles';
import MainCard from '../../utils/MainCard';
import {Column, Row} from "../../utils/FlexBox";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'underscore';
import './index.scss';
import { safeMixpanelTrack } from '../utils/utils';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

import {
  loadAnalyticsPortfolioCsv,
  loadAnalyticsAllCsv } from '../../../sagas/network';


function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Company' },
        { id: 'overall_score', numeric: true, disablePadding: false, label: 'Diversio Score' },
        { id: 'diversity_score', numeric: true, disablePadding: false, label: 'Diversity' },
        { id: 'inclusion_score', numeric: true, disablePadding: false, label: 'Inclusion' },
        { id: 'commitment_score', numeric: true, disablePadding: false, label: 'Commitment' },
    ];

    return (
        <TableHead className="analytics__portfolio-table-head">
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Select All' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell />
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const PortfolioTable = ({ data, removeCallback, toggleCallback, loadedAnalyticsCompaniesData, failedLoadingAnalyticsCompaniesData }) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const rows = data.map(d => ({
        overall_score: d.data?.score_data?.overall_score ?? "--",
        diversity_score: d.data?.score_data?.diversity_score ?? "--",
        inclusion_score: d.data?.score_data?.inclusion_score ?? "--",
        commitment_score: d.data?.score_data?.commitment_score ?? "--",
        name: d.name,
        id: d.id,
        isChecked: d.isChecked
    }))

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            data.filter(d => !d.isChecked).map(d => {
                toggleCallback(d.id);
            });
        } else {
            data.map(d => toggleCallback(d.id));
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onClickRemove = (companyName) => {
        removeCallback({
          company: companyName,
          action: "REMOVE"
        })
    }

    const renderTableBody = () => {
        if (!loadedAnalyticsCompaniesData) {
            return <TableRow key={1}><TableCell colspan={"100%"} style={{textAlign: "center"}}>Loading portfolio scores...</TableCell></TableRow>
        }

        if (_.isEmpty(data) || failedLoadingAnalyticsCompaniesData) {
            return <TableRow key={1}><TableCell colspan={"100%"} style={{textAlign: "center"}}>Your portfolio is empty.</TableCell></TableRow>
        }

        return (
            <TableBody>
                {
                    stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                            <TableRow key={`${row.name}_${row.id}`}>
                                <TableCell padding={"checkbox"}>
                                    <Checkbox checked={row.isChecked}
                                            inputProps={{ 'aria-labelledby': row.id }}
                                            onClick={() => toggleCallback(row.id)}/>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Link to={{
                                        pathname: '/analytics/search',
                                        state: { companyStr: row.name }
                                    }}>{row.name}</Link>
                                </TableCell>
                                <TableCell align="right">{ row.overall_score }</TableCell>
                                <TableCell align="right">{ row.diversity_score }</TableCell>
                                <TableCell align="right">{ row.inclusion_score }</TableCell>
                                <TableCell align="right">{ row.commitment_score }</TableCell>
                                <TableCell align="right">
                                {
                                    <span className="clickable analytics__portfolio-remove-button" onClick={() => onClickRemove(row.name)}>
                                    Remove
                                    </span>
                                }
                                </TableCell>
                            </TableRow>
                        )
                    }
                )}
                </TableBody>
        )

    }

  return (
    <>
        <TableContainer component={Paper}>
                    <Table className="analytics__portfolio-table" aria-label="simple table">
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={data.filter(d => d.isChecked).length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        {renderTableBody()}
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}/>
                </TableContainer>
            
    </>
  )
}

const AnalyticsPortfolio = (props) => {
  const [ csvData, setCsvData ] = useState([["empty"]]);

  // status and error handling for Portfolio download
  const [ downloadWaitingP, setDownloadWaitingP ] = useState(null);
  const [ downloadErrorP, setDownloadErrorP ] = useState(null);
  const [ premiumErrorP, setPremiumErrorP ] = useState(null);

  // status and error handling for All download
  const [ downloadWaitingA, setDownloadWaitingA ] = useState(null);
  const [ downloadErrorA, setDownloadErrorA ] = useState(null);
  const [ premiumErrorA, setPremiumErrorA ] = useState(null);
  const [companiesData, setCompaniesData] = useState([]);
  const [overallScore, setOverallScore] = useState({});

  const csvLink = useRef();
  const user_portfolio = props.analytics ? props.analytics.user_portfolio : []
  const companies_data = props.analytics?.companies_data ?? [];
  const indexScores = props.analytics?.index_scores ?? [];
  const forceUpdate = useForceUpdate();

  const { loadedAnalyticsCompaniesData, failedLoadingAnalyticsCompaniesData } = props.analytics

  useEffect(() => {
    safeMixpanelTrack("View Page", {
      name: "Analytics Portfolio",
    });
  }, []);

  useEffect(() => {
    props.getAnalyticsCompaniesData({data: { name: user_portfolio.join(";") } })
  }, [user_portfolio])

  useEffect(() => {
      const portfolioCompaniesWithData = user_portfolio.map((company, id) => {
          return {
              name: company,
              data: _.findWhere(companies_data, { "company_name": company }),
              isChecked: true,
              id
          }
      });
      setOverallScore(calculateOverallScore(portfolioCompaniesWithData));
      setCompaniesData(portfolioCompaniesWithData);
  }, [companies_data]);

  const clearAllStates = () => {
    setDownloadWaitingP(null);
    setDownloadErrorP(null);
    setPremiumErrorP(null);

    setDownloadWaitingA(null);
    setDownloadErrorA(null);
    setPremiumErrorA(null);
  }

  const renderDownloadButton = (downloadType = "portfolio") => {

    let premiumError;
    let downloadError;
    let downloadWaiting;

    if (downloadType == "all") {
      premiumError = premiumErrorA;
      downloadError = downloadErrorA;
      downloadWaiting = downloadWaitingA;
    } else {
      premiumError = premiumErrorP;
      downloadError = downloadErrorP;
      downloadWaiting = downloadWaitingP;
    }

    if (downloadType !== "all" && user_portfolio.length == 0) {
      return null;
    }

    if (premiumError) {
      return (
        <span className="analytics__error-message">
        {
          downloadType == "all" ?
          "Exporting all data is a premium feature. Please contact Diversio for details!"
          :
          "CSV download is a premium feature. Please contact Diversio for details!"
        }
        </span>
      );
    } else if (downloadError) {
      return (
        <span className="analytics__error-message">
          Error downloading CSV. Please try again later.
        </span>
      );

    }

    return (
      <div className="analytics__download-portfolio">
        <span onClick={() => {
          downloadCSV(downloadType)
        }}>
          Download CSV
        </span>
        {
          downloadWaiting ?
          <span className="analytics__download-progress">
            <CircularProgress size={12} style={{color: '#6221EA'}} />
          </span> : null
        }
        <CSVLink
          data={csvData}
          filename='portfolio.csv'
          className='analytics__hidden-csv-link'
          ref={csvLink}
          target='_blank'
        />
      </div>
    );
  }

  const downloadCSV = async (downloadType="portfolio") => {

    clearAllStates();

    let setPremiumError;
    let setDownloadError;
    let setDownloadWaiting;
    let downloadFunc;

    if (downloadType == "all") {
      setPremiumError = setPremiumErrorA;
      setDownloadError = setDownloadErrorA;
      setDownloadWaiting = setDownloadWaitingA;
      downloadFunc = loadAnalyticsAllCsv;
    } else {
      setPremiumError = setPremiumErrorP;
      setDownloadError = setDownloadErrorP;
      setDownloadWaiting = setDownloadWaitingP;
      downloadFunc = loadAnalyticsPortfolioCsv;
    }

    setDownloadWaiting(true);

    downloadFunc().then(
      async (response) => {

        setDownloadWaiting(false);

        if (response.code !== 200) {
          if (response.code == 403) {
            // Note: using 403 Forbidden because the front end logs the user out
            // upon getting 401
            setPremiumError(true);

            safeMixpanelTrack("CSV download", {
              "type": downloadType,
              "response_code": response.code
            })

            return;
          }
          setDownloadError(true);
          console.log("Error fetching CSV response.");

          safeMixpanelTrack("CSV download", {
            "type": downloadType,
            "response_code": response.code
          })

          return;
        }

        safeMixpanelTrack("CSV download", {
          "type": downloadType,
          "response_code": response.code
        })

        await setCsvData(response.data);
        try {
          csvLink.current.link.click();
        } catch (e) {
          setDownloadError(true);
          console.log("CSV download error: " + e);
        }

      }
    );
  }

  const calculateOverallScore = (companies) => {
      let totalOverallScore = 0, totalDiversityScore = 0, totalInclusionScore = 0, totalCommitmentScore = 0;
      const consideredCompanies = companies.filter(company => company.isChecked && !_.isEmpty(company.data?.score_data))
      consideredCompanies.map(company => {
          totalOverallScore += Number(company.data.score_data.overall_score) ?? 0;
          totalDiversityScore += Number(company.data.score_data.diversity_score) ?? 0;
          totalInclusionScore += Number(company.data.score_data.inclusion_score) ?? 0;
          totalCommitmentScore += Number(company.data.score_data.commitment_score) ?? 0;
      });

      return {
          "overall_score": (totalOverallScore / consideredCompanies.length).toFixed(1),
          "diversity_score": (totalDiversityScore / consideredCompanies.length).toFixed(1),
          "inclusion_score": (totalInclusionScore / consideredCompanies.length).toFixed(1),
          "commitment_score": (totalCommitmentScore / consideredCompanies.length).toFixed(1)
      }
  }

  const setChecked = id => {
      let portfolioCompaniesWithData = companiesData;
      portfolioCompaniesWithData = portfolioCompaniesWithData.map(company => {
          if (company.id === id) {
              company.isChecked = !company.isChecked;
          }
          return company;
      });
      setCompaniesData(portfolioCompaniesWithData);
      setOverallScore(calculateOverallScore(portfolioCompaniesWithData));
      forceUpdate();
  }

  return (
      <>
        <div className={"cards-container"}>
            <Row>
                <Column>
                    <Paper elevation={0}>
                        <h5>Overall Portfolio Score</h5>
                        <div className="score_section">
                            <h1>{overallScore.overall_score === "NaN" ? "--" : overallScore.overall_score }</h1>
                            <div className={"index-details"}>
                                {
                                    indexScores.filter(index => index.name != "S&P 100" && index.name != "Fortune 500").map(index => <div className="index-scores">
                                        <p className="index-name">{ index.name }</p>
                                        <p className="index-score">{ index.overall_score?.toFixed(1) ?? "--" }</p>
                                    </div>)
                                }
                            </div>
                        </div>
                    </Paper>
                </Column>
                <Column>
                    <Paper elevation={0}>
                        <h5>Diversity</h5>
                        <div className="score_section">
                            <h1>{overallScore.diversity_score === "NaN" ? "--" : overallScore.diversity_score}</h1>
                            <div className={"index-details"}>
                                {
                                    indexScores.filter(index => index.name != "S&P 100" && index.name != "Fortune 500").map(index => <div className="index-scores">
                                        <p className="index-name">{ index.name }</p>
                                        <p className="index-score">{ index.diversity_score?.toFixed(1) ?? "--" }</p>
                                    </div>)
                                }
                            </div>
                        </div>
                    </Paper>
                </Column>
                <Column>
                    <Paper elevation={0}>
                        <h5>Inclusion</h5>
                        <div className="score_section">
                            <h1>{overallScore.inclusion_score === "NaN" ? "--" : overallScore.inclusion_score}</h1>
                            <div className={"index-details"}>
                                {
                                    indexScores.filter(index => index.name != "S&P 100" && index.name != "Fortune 500").map(index => <div className="index-scores">
                                        <p className="index-name">{ index.name }</p>
                                        <p className="index-score">{ index.inclusion_score?.toFixed(1) ?? "--" }</p>
                                    </div>)
                                }
                            </div>
                        </div>
                    </Paper>
                </Column>
                <Column>
                    <Paper elevation={0}>
                        <h5>Commitment</h5>
                        <div className="score_section">
                            <h1>{overallScore.commitment_score === "NaN" ? "--" : overallScore.commitment_score}</h1>
                            <div className={"index-details"}>
                                {
                                    indexScores.filter(index => index.name != "S&P 100" && index.name != "Fortune 500").map(index => <div className="index-scores">
                                        <p className="index-name">{ index.name }</p>
                                        <p className="index-score">{ index.commitment_score?.toFixed(1) ?? "--" }</p>
                                    </div>)
                                }
                            </div>
                        </div>
                    </Paper>
                </Column>
            </Row>
        </div>
        <Row>
            <Column grow={1}>
                <div className="analytics">
                    <PortfolioTable 
                        data={_.deepClone(companiesData)}
                        removeCallback={(payload) => {props.doUpdateAnalyticsUserPortfolio(payload)}}
                        toggleCallback={setChecked}
                        loadedAnalyticsCompaniesData={loadedAnalyticsCompaniesData}
                        failedLoadingAnalyticsCompaniesData={failedLoadingAnalyticsCompaniesData}
                    />
                </div>
            </Column>
        </Row>
        <Row>
            <Column grow={1}>
                <MainCard
                  title={"Download All Company Data"}
                  >
                    <div className="analytics">
                        Export a .csv of all data that you have download access to.
                        <div className="analytics__download-all-button">
                        { renderDownloadButton("all") }
                        </div>
                    </div>
                </MainCard>
            </Column>
        </Row>
      </>
    );
}

export default AnalyticsPortfolio;
