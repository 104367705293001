import React, {Component} from 'react';
import Overview from "../../containers/DiversityVC/Overview";
import './index.scss'
import {Route, Link, Redirect} from "react-router-dom";
import About from "./About";


const pages = {
    overview:'overview',
    about:'about'
};


class DiversityVC extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page:pages.Overview
        }
    }

    render() {
        const path = this.props.match.path;
        const isAbout = this.props.location.pathname.endsWith(pages.about);
        return (
            <div className={'diversity-vc'}>
                <div className='navigation'>
                    <h1>VC D&I Standard</h1>
                    <div className="navigation-options">
                        <Link to={`${path}/${pages.about}`}><div className={isAbout?"selected":""}>About</div></Link>
                        <Link to={`${path}/${pages.overview}`}><div className={isAbout?"":"selected"}>Your Results</div></Link>
                    </div>
                </div>
                    <Route exact path={`${path}/${pages.overview}`} component={Overview}/>
                    <Route exact path={`${path}/${pages.about}`} component={About}/>
                    <Route exact path={path} render={(props) => (
                            <Redirect
                                to={{
                                    pathname: `${path}/${pages.about}`
                                }} />
                    )} />
            </div>
        );
    }
}

export default DiversityVC;
