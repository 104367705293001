import React, { Component } from "react";
import "./index.scss";
import Card from "../../utils/Card";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text } from "recharts";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { capitalizeString } from "../../../utils/index"

const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <Text className="axis-style" x={x} y={y} width={50} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>)
}

const CustomizedYAxisTick = (props) => {
  const formatter = (value) => `$${value}B`;
  const { x, y, payload } = props;
  let newY = y - 5
  let newX = x - 40
  return (
    <Text className="axis-style" x={newX} y={newY} width={50} textAnchor="middle" verticalAnchor="start">{formatter(payload.value)}</Text>)
}

class AUMBreakdown extends Component {
  state = {
    filterIndexMember: "All Members",
    memberList: [],
    data: [{ columnKey: "", total: 0 }]
  }

  componentDidUpdate(prevState) {
    if (this.props.investments !== prevState.investments) {
      const { investments } = this.props
      const memberList = this.getMember(investments)
      this.showBreakdown("All Members")
      this.setState({
        memberList: memberList
      })
    }
  }

  handleMemberChange = (e) => {
    const filterIndex = e.target.value
    this.setState({
      filterIndexMember: filterIndex
    })
  }

  showBreakdown = (member) => {
    let investments = this.props.investments
    let columns = []
    let result = []
    let columnKeys = []

    if (member === "All Members") {
      for (var i in investments) {
        columns.push(investments[i].asset_class)
      }
      columnKeys = [...new Set(columns)]

      for (var y in columnKeys) {
        let total = 0
        for (var z in investments) {
          if (investments[z].asset_class === columnKeys[y]) {
            total = total + investments[z].amount
          }
        }
        const capitalize = capitalizeString(columnKeys[y])
        result.push({ columnKey: capitalize, total: total })
      }
    } else {
      // Filter by name first
      const arr = investments.filter(i => i.member === member)
      // Get column keys to show on graph x -axis

      for (var j in arr) {
        columns.push(arr[j].asset_class)
      }
      columnKeys = [...new Set(columns)]

      // Filtering magic happens here
      // For each columnKeys, loop through the arr array
      // Add the investment sum if arr asset_class is equal to the columnKeys
      
      for (var y in columnKeys) {
        let total = 0
        for (var z in arr) {
          if (arr[z].asset_class === columnKeys[y]) {
            total = total + arr[z].amount
          }
        }
        const capitalize = capitalizeString(columnKeys[y])
        result.push({ columnKey: capitalize, total: total })
      }
    }
    this.setState({
      data: result
    })
  }

  getMember = (investments) => {
    let filter = []
    filter.push("All Members")
    for (var i in investments) {
      filter.push(investments[i].member)
    }
    const memberList = [...new Set(filter)]
    return memberList
  }


  render() {
    const { memberList } = this.state

    return (
      <Card className="iln-card">
        <h2 className="iln-aum-h2">AUM Breakdown</h2>
        <div className="iln-aum-container">
          <div className="iln-aum">
            <div className="iln-aum-text">
              <div className="iln-dropdown">
                <h4 className="h4-aum">Member</h4>
                <TextField
                  required
                  select
                  value={this.state.filterIndexMember}
                  onChange={this.handleMemberChange.bind(this)}
                  margin="normal"
                  className="text-field"
                >
                  {
                    memberList.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      )
                    })
                  }
                </TextField>
              </div>
              <div className="iln-aum-btn">
                <Button onClick={() => this.showBreakdown(this.state.filterIndexMember)}>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="iln-aum-barchart">
          <ResponsiveContainer width="100%" height={400} padding={100}>
            <BarChart
              width={1100}
              height={300}
              data={this.state.data}
              margin={{
                top: 5, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="columnKey" interval={0} tick={<CustomizedXAxisTick />} height={100} />
              <YAxis tick={<CustomizedYAxisTick />} />
              <Tooltip formatter={(value) => Math.round(value * 100) / 100} />
              <Bar dataKey="total" fill="#27829E" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>
    )
  }
}

export default AUMBreakdown
