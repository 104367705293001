import React, { useEffect, useState } from 'react';
import './index.scss';
import Legend from "./Legend";
import _ from 'underscore';
import mixpanel from "mixpanel-browser";

import { safeMixpanelTrack } from '../utils/utils';


import { Bar, BarChart, Rectangle, CartesianGrid, 
    LabelList, Cell, ResponsiveContainer, XAxis, 
    YAxis, Text, Tooltip } from "recharts";

const COLOR1 = "#6221EA";
const COLOR2 = "#D0BEF7";
const COLOR3 = "#f6f6f6";
const NA_DASH = "#b1abbd"
const BAR_LABEL = "#322352";

const BAR_GAP = 40
const BAR_C_GAP = 80

const GEN_DOM = "Men";
const GEN_NONDOM = "Women";
const ETH_DOM = "White";
const ETH_NONDOM = "Minorities";


const CustomTooltip = ({ dataType, fullPayload, companyName, active, payload, label }) => {

    if (active) {
        if (dataType && fullPayload) {
            let innerContent = null;

            if (dataType === "board gender") {
                if (fullPayload["board_men_count"] && fullPayload["board_women_count"])
                innerContent = (
                    <>
                    <div>
                    <div className="analytics__tooltip-title">{`Board - ${companyName || null }`}</div>
                    {`${GEN_NONDOM}: ${fullPayload["board_women_count"]}`}
                    </div>
                    <div>
                    {`${GEN_DOM}: ${fullPayload["board_men_count"]}`}
                    </div>
                    </>

                );
            }
            else if (dataType === "executive gender") {
                if (fullPayload["exec_men_count"] && fullPayload["exec_women_count"])
                innerContent = (
                    <>
                    <div>
                    <div className="analytics__tooltip-title">{`Executive - ${companyName || null }`}</div>
                    {`${GEN_NONDOM}: ${fullPayload["exec_women_count"]}`}
                    </div>
                    <div>
                    {`${GEN_DOM}: ${fullPayload["exec_men_count"]}`}
                    </div>
                    </>

                );
            }
            else if (dataType === "executive ethnic") {
                if (fullPayload["exec_white_count"] && fullPayload["exec_poc_count"])
                innerContent = (
                    <>
                    <div>
                    <div className="analytics__tooltip-title">{`Executive - ${companyName || null }`}</div>
                    {`${ETH_NONDOM}: ${fullPayload["exec_poc_count"]}`}
                    </div>
                    <div>
                    {`${ETH_DOM}: ${fullPayload["exec_white_count"]}`}
                    </div>
                    </>

                );
            }
            else if (dataType === "board ethnic") {
                if (fullPayload["board_white_count"] && fullPayload["board_poc_count"])
                innerContent = (
                    <>
                    <div>
                    <div className="analytics__tooltip-title">{`Board - ${companyName || null }`}</div>
                    {`${ETH_NONDOM}: ${fullPayload["board_poc_count"]}`}
                    </div>
                    <div>
                    {`${ETH_DOM}: ${fullPayload["board_white_count"]}`}
                    </div>
                    </>

                );
            } else {
                return null;
            }

            if (!innerContent) {
                return null;
            }

            return (
                <div className="analytics__tooltip">
                 { innerContent }

                 {/* uncomment below for debug */}
                 {/* { JSON.stringify(dataType) }
                 { JSON.stringify(fullPayload) } */}
                 </div>
             );
        }
        
    }

    return null;
};

const getPath = (x, y, width, height) => 
    `M${x},${y + height}
          L${x }, ${y}
          L${x + width}, ${y}
          L${x + width}, ${y + height}
          Z`;

const CustomTick = (props) => {
    const { x, y, width, companyName } = props;
    const className = `org-div__custom_tick`;
    const x_offset = (width - (BAR_C_GAP * 2) - BAR_GAP) / 4 + (BAR_GAP/2)

      return (
        <g transform={`translate(${x},${y})`}>
          <Text
            width={200}
            x={-x_offset}
            y={0}
            dy={16}
            verticalAnchor="middle"
            textAnchor="middle"
            className={className}
          >
            {companyName || "Company"}
           </Text>
           <Text
            width={200}
            x={x_offset}
            y={0}
            dy={16}
            verticalAnchor="middle"
            textAnchor="middle"
            className={className}
          >
            {"Industry"}
           </Text>
        </g>
      );
}

export const ConditionalRectangleBar = ({ value, fill, x, y, width, height, payload, dataKey, ...rest }) => {
    // Annoyingly complex, but necessary due to
    // https://github.com/recharts/recharts/issues/1265

    let widthToSet = 0;
    let xToSet = x;

    if (dataKey === "industry") {
        xToSet = x - (width);
    } else if (dataKey === "na") {
        xToSet = x - (2 * width);
    }

    if (payload.hasOwnProperty(dataKey)) {
      widthToSet = width * 3; // force bar to take all space
    }

    let stroke = "none";
    let strokeDasharray = null;

    if (dataKey === "na") {
        stroke = NA_DASH;
        strokeDasharray = "4 1";
    }

    return (
        <>
        {dataKey === "na" ? null : <text x={xToSet + width} y={y} dy={-8} fill={fill}
        fontSize={12} textAnchor="center">{value}</text>}
        <path d={getPath(xToSet, y, widthToSet, height)}
        stroke={stroke} strokeDasharray={strokeDasharray} fill={fill} />
        </>
        );
  };

const CompBarChart = ({ data, dataType, fullPayload, companyName, ...props}) => {

    if (!data) {
        return null;
    }

 return (
    <div className="org-div__single_chart">
        <ResponsiveContainer height={260} width="100%">
        <BarChart barGap={BAR_GAP} barCategoryGap={BAR_C_GAP} data={data}
            onMouseEnter={() => {
                safeMixpanelTrack(
                    "Chart hover", {
                        "name": dataType
                    }
                )
            }}
        >
            <CartesianGrid stroke="#EBECF0" strokeWidth="2" vertical={false} />
            <XAxis
                 xAxisId={0}
                 height={40}
                 interval={0}
                 fontSize={11}
                 fontFamily="Lato"
                 dataKey="type"
                 tickSize={0}
                 axisLine={true}
                 tickLine={true}
                 tickMargin={15}
                 tick={<CustomTick companyName={companyName}/>}
                 />
            <XAxis
                dataKey="challenge"
                xAxisId={1}
                 hide/>
             <YAxis 
             width={24}
             padding={{ top: 30 }}
            tickCount={6} 
            fontSize={11} fontFamily="Lato" orientation='left' tick={{fill: "#322352"}} axisLine={false} tickLine={false} tickMargin={5}

             />
             <Tooltip 
             position={{ x: 50, y: 100 }}
             content={(props) => <CustomTooltip 
                dataType={dataType} 
                fullPayload={fullPayload} 
                companyName={companyName}
                {...props} />}/>
            <Bar xAxisId={0} dataKey="company" fill={COLOR1} 
            isAnimationActive={false} >
                    {data.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLOR1} />
                    ))}
                    <LabelList fill={BAR_LABEL} dataKey="company" position="top" content={(c) => `${c.value}%`}/>
            </Bar>
            <Bar xAxisId={0} dataKey="industry" fill={COLOR2} 
            isAnimationActive={false} >
                    {data.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLOR2} />
                    ))}
                    <LabelList fill={BAR_LABEL} dataKey="industry" position="top" content={(c) => `${c.value}%`}/>
            </Bar>
            <Bar xAxisId={1} dataKey="company_na" >
                    {data.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLOR3}
                        stroke={NA_DASH}
                        strokeDasharray="4 1" />
                    ))}
            </Bar>
            <Bar xAxisId={1} dataKey="industry_na" >
                    {data.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLOR3}
                        stroke={NA_DASH}
                        strokeDasharray="4 1" />
                    ))}
            </Bar>
            
         </BarChart>
     </ResponsiveContainer>
    
 </div>
 );
}

const _fillNa = (data) => {
    if (!data.company) {
        data = {...data, "company_na": 20}
    }
    if (!data.industry) {
        data = {...data, "industry_na": 20}
    }

    return data;
}

const DiversityCharts = ( { 
        companyName, 
        activeCompany,
        divType="executive",
        genderString, 
        raceString, 
        ...props} ) => {

    if (_.isEmpty(activeCompany)) {
        return null;
    }

    let gender;
    let i_gender;
    let ethnic;
    let i_ethnic;

    if (activeCompany.company_diversity && activeCompany.i_diversity) {
        gender = activeCompany.company_diversity[`${divType}_gender_percent`];
        i_gender = activeCompany.i_diversity[`${divType}_gender_percent`];
        ethnic = activeCompany.company_diversity[`${divType}_ethnic_percent`];
        i_ethnic = activeCompany.i_diversity[`${divType}_ethnic_percent`];
    }

    const reshapeData = (gender, i_gender, ethnic, i_ethnic) => {
        return {
            "gender": [
                {
                    "type": "Company",
                    "company": gender || null,
                    "industry": i_gender || null,
                },
            ],
            "ethnic": [
                {
                    "type": "Company",
                    "company": ethnic || null,
                    "industry": i_ethnic || null,
                },
            ],
        }
    }

    const reshapedData = reshapeData(gender, i_gender, ethnic, i_ethnic);
    const genderData = [_fillNa(reshapedData.gender[0])];
    const ethnicData = [_fillNa(reshapedData.ethnic[0])];
    
    return (
        
        <div className="org-div__wrapper">
            <div className="org-div__legend_wrapper">
                <Legend activeCompanyName={companyName}/>
            </div>
            <div className="org-div__charts_wrapper">
                <div className="org-div__chart">
                    <div className="org-div__type">
                        {genderString}
                    </div>
                    <div className="org-div__nondom">
                        % Women
                    </div>
                    <CompBarChart 
                        data={genderData} 
                        fullPayload={activeCompany.company_diversity || null} 
                        dataType={`${divType} gender`} 
                        companyName={companyName} />
                </div>
                <div className="org-div__spacer">

                </div>
                <div className="org-div__spacer">

                </div>
                <div className="org-div__chart">
                    <div className="org-div__type">
                        {raceString}
                    </div>
                    <div className="org-div__nondom">
                        % Minorities
                    </div>
                    <CompBarChart data={ethnicData} 
                    fullPayload={activeCompany.company_diversity || null} 
                    dataType={`${divType} ethnic`} 
                    companyName={companyName} />
                </div>
            </div>
        </div>

        
    );
}

export default DiversityCharts;
