import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from '../../../utils/Card'
import DeleteIcon from '../../../../images/delete';
import './index.scss'

class NextSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.company_solutions[0] ? this.props.company_solutions[0].id : null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If after update selected soln no longer exists here, set selected to first soln
        if (this.props.company_solutions.length && !this.props.company_solutions.map(x => x.id).includes(this.state.selected)) {
            this.setState({
                selected: this.props.company_solutions[0].id
            });
        }
    }

    deleteSolution(id) {
        // Set selected to previous soln in list.
        const index = this.props.company_solutions.findIndex(x=>x.id===id);
        const nextSelectedIndex = index===0?1:index-1;
        const selected = this.props.company_solutions[nextSelectedIndex] ? this.props.company_solutions[nextSelectedIndex].id : null;

        // After new selected is chosen, remove this soln from 'Next Steps' (is_next_steps=false).
        this.setState({selected}, () =>{
            const payload = {
                id,
                is_next_steps: false
            };
            this.props.doUpdateCompanySolutions(payload);
        });
    }

    addToInternalProgramming() {
        const payload = {
            id:this.state.selected,
            is_my_solution: true
        };
        this.props.doUpdateCompanySolutions(payload)
    }

    solutionToListItem(soln, index) {
        return (
            <div
                key={soln.id}
                className={this.state.selected === soln.id ? "solution selected" : "solution"}
                onClick={()=>{this.setState({selected:soln.id})}}
            >
                <div className={'vertical-center'}>
                    <div className={'red-color'}>{index+1}</div>
                    <div className={'soln-title'}>{soln.solution.title}</div>
                    <DeleteIcon onClick={(e) => {
                        e.stopPropagation(); // don't set to soln' thats about to be deleted
                        this.deleteSolution.call(this, soln.id)
                    }}/>
                </div>
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="content">
                Loading....
            </div>
        )
    }

    renderNoNextSteps(){
        return (
            <p style={{marginTop:"20px"}}>
                No next steps currently selected. <a href="#top-solution">see your recommendations</a> to add to next steps.
            </p>
        )
    }

    renderLoaded() {
        const selectedSolution = this.props.company_solutions.find(x => x.id === this.state.selected);

        return (
            <div className="content">
                <div className="solutions">
                    <h2>Solutions</h2>
                    <div className={'solution-list'}>
                        {this.props.company_solutions.map(this.solutionToListItem.bind(this))}
                    </div>
                </div>
                <div className="selected-solution">
                    <div style={{height:'100%'}}>
                        <h3>{selectedSolution.solution.title}</h3>
                        <div>{selectedSolution.solution.description}</div>
                        <h3>Relevant KPI</h3>
                        <div className={'kpis'}>
                            {selectedSolution.solution.kpis.map((x) => <div key={x.id}>{x.name}</div>)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        const content = this.props.company_solutions.length>0 ?
            (this.state.selected === null? this.renderLoading() : this.renderLoaded()) :
            this.renderNoNextSteps();


        return (
            <Card className={'next-steps'}>
                <h1>
                    Next Steps
                </h1>
                <p>
                    Review your selected recommendations to see what programmes you saved.
                </p>
                {content}
            </Card>
        );
    }
}

NextSteps.propTypes = {
    company_solutions: PropTypes.array
};

export default NextSteps;
