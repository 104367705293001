import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form } from "react-bootstrap";
import './index.scss';
import { safeMixpanelTrack } from "../utils/utils";

import mixpanel from "mixpanel-browser";
import _ from 'underscore';


const AnalyticsSearchBar = ({ selected, setSelected, companyList, companyName, ...props }) => {
  const [ currentInput, setCurrentInput ] = useState("");

  // avoid sending tracking events when user is in the middle of typing
  const debouncedSetInput = _.debounce((newInput) => {
    setCurrentInput(newInput)
  }, 1500)

  const debouncedTrack = _.debounce((currentInput) => {
    // avoid sending tracking events when user is in the middle of typing

    safeMixpanelTrack("Company search had no match", {
      name: currentInput
    });

  },
  1500);

    const options = companyList;

    const noMatchFoundHandler = () => {

        if (currentInput.length > 0) {
          debouncedTrack(currentInput);
        }

      return `No matches found.`
    }

    return (
        <div className={"analytics_search__wrapper"}>
        <Form.Group className="analytics_search__form_group">
          <Typeahead
            onChange={setSelected}
            onInputChange={value => debouncedSetInput(value)}
            emptyLabel={noMatchFoundHandler()}
            options={options}
            maxResults={20}
            paginate={true}
            placeholder="Search for a company..."
            id="typeahead_search"
          />
        </Form.Group>
        </div>
    );
  };

export default AnalyticsSearchBar;
