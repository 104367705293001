import React, { useState, useEffect } from 'react';
import { Nav, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './index.scss'
import {Column, Row} from "../../utils/FlexBox";
import Card from '../../utils/Card';
import AnalyticsSearchBar from '../AnalyticsSearchBar';
import Overview from '../Overview';
import DiversityCharts from '../DiversityCharts';
import Frequency from '../Frequency';
import Commitment from '../Commitment';
import CompanyInfo from '../CompanyInfo';
import MainCard from '../../utils/MainCard';
import LastUpdatedStamp from '../LastUpdatedStamp';
import { FEATURE_FLAGS } from "../utils/featureFlags";
import * as modalText from '../modal_text';

import Modal from "react-bootstrap/Modal";

import mixpanel from "mixpanel-browser";
import { safeMixpanelTrack } from '../utils/utils';
import _ from 'underscore';

const AnalyticsSearch = (props) => {
    const [ showModal, setShowModal ] = useState(false);
    const [ modalContent, setModalContent ] = useState(null);
    const [ modalTitle, setModalTitle ] = useState(null);
    const [activeCompany, setActiveCompany] = useState(null);
    const [currentPortfolio, setCurrentPortfolio] = useState([]);
    const history = useHistory();

    useEffect(() => {
        safeMixpanelTrack("View Page", {
          name: "Analytics Main Search Page",
        });
      }, []);
    
    useEffect(() => {
        if (!_.isEqual(props.analytics.user_portfolio, currentPortfolio)) {
            setCurrentPortfolio(props.analytics.user_portfolio);
        }

        if (history.location.state && history.location.state.companyStr) {
            const companyStr = history.location.state.companyStr;
            history.replace();
            handleChooseCompany([companyStr]);
        } else if (activeCompany == null) {
            if (props.analytics.company_list && props.analytics.company_list[0]) {
                handleChooseCompany([props.analytics.company_list[0]], true);
            }
        }
    });

    const handleChooseCompany = (input, isDefault=false) => {
        if (_.isEmpty(input) || _.isEqual(activeCompany, input)) {
            // do nothing
        } else {
            props.getAnalyticsCompanyData({data: { name: input[0] } });
            setActiveCompany(input);

            if (!isDefault) {
                // Ignore the "default" company that gets chosen on load
                safeMixpanelTrack("Choose company", {
                    name: input[0],
                });
            }
            
        }  
    }

    const moreInfo = (title, text) => {
        return (
            <span onClick={(e) => { 
                e.preventDefault();
                setShowModal(!showModal);
                setModalContent(text);
                setModalTitle(title);
                 }}>
                    <img className="icon icon-question-mark"/>
                </span>
        );
    }

    const moreInfoAdd = (title, text) => {
        return (
            <div className="more-info-add">
            <span className="add-remove-button">{addRemovePortfolioButton()}</span>
            <span onClick={(e) => { 
                e.preventDefault();
                setShowModal(!showModal);
                setModalContent(text);
                setModalTitle(title);
                 }}>
                    <img className="icon icon-question-mark"/>
                </span>
            </div>
        );
    }

    const onPortfolioButtonClick = () => {
        // TODO: can remove state updates within the component

        if (activeCompany) {

            const companyName = activeCompany[0];
            let payload = { company: companyName }

            if (currentPortfolio.includes(companyName)) { 
                payload.action = "REMOVE";
                props.doUpdateAnalyticsUserPortfolio(payload);
                
            } else {
                payload.action = "ADD";
                props.doUpdateAnalyticsUserPortfolio(payload);
                
            }

            safeMixpanelTrack("Add/remove to portfolio", {
                "name": companyName,
                "payload_action": payload.action
            })
        }

    }

    const addRemovePortfolioButton = () => {

        if (activeCompany) {
            if (currentPortfolio.includes(activeCompany[0])) {

                return (
                    <span className="analytics__add_to_port clickable">
                        <span onClick={onPortfolioButtonClick}>
                        Remove from Portfolio
                        </span>
                    </span>
                )
            } else {
                return (
                    <span className="analytics__add_to_port clickable">
                        <span onClick={onPortfolioButtonClick}>
                        + Add to Portfolio
                        </span>
                    </span>
                )
            }
        }
        
    }

    const selectedCompanyTitle = (activeCompany) => {
        return (
            <>
            Company of interest: <span className="analytics__selected_company_title">{activeCompany}</span>
            {
                completeDataCheck(props.analytics?.active_company) ? null : <span className="analytics__incomplete-marker">Data update in progress</span>
            }
            </>
        );
    }

    const completeDataCheck = (activeCompany) => {
        /* Returns true if all pieces of data are present.
        
        If any piece of data is missing; return false.
        
        We expect Board and Commitment details to be missing much of the time. */

        let isComplete = true;

        // check commitment
        if (_.isEmpty(activeCompany?.commitment_detail)) {
            isComplete = false;
        }

        // check pain point scores (inclusion)
        if (_.isEmpty(activeCompany?.pain_point_scores)) {
            isComplete = false;
        }

        // check diversity
        const DIVERSITY_CHECK_FIELDS = [
            "board_ethnic_percent",
            "board_gender_percent",
            "executive_ethnic_percent",
            "executive_gender_percent",
        ];

        for (const field of DIVERSITY_CHECK_FIELDS) {
            if (activeCompany?.company_diversity?.[field] == null) {
                isComplete = false;
            }
        }

        return isComplete;
    }

    let errorBody = null;
    if (props.analytics.active_company_error) {

        let title = "Error fetching data";
        let body = "If this error persists, please contact Diversio for assistance."

        if (props.analytics.active_company_error.error = "UNAUTHORIZED") {
            title = "Resource not permissioned"
            body = "Please search for a company within the scope of your access. If you believe you should have permission to access this resource, please contact Diversio for assistance."
        }

        errorBody = (
            <div>
            <div className="App">
                <Row>
                    <Column grow={1}>
                        <MainCard title={title}
                        customRighthand={null}>
                            {body}
                        </MainCard>
                    </Column>
                </Row>
            </div>
            </div>
        )
    }

    /* sometimescasey: hack for demo, remove */
    const subtitle = props.subtitle || "View potential investments to understand their Diversity & Inclusion performance.";
    
    return (
        <div>
            <div className="App">

            <Modal show={showModal}>
                <Modal.Header>{modalTitle}</Modal.Header>
                <Modal.Body>{modalContent}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setShowModal(false);
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

                <Row>
                    <Column grow={1}>
                        <div className={"bootstrap-override"}>
                            <p className="analytics__subtitle">
                                {subtitle}
                            </p>
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column grow={1}>
                        <p className={"analytics__company_search"}>Company Search</p>
                        <Card className={"analytics__search_bar"}>
                            Search
                            <AnalyticsSearchBar 
                            selected={activeCompany}
                            setSelected={handleChooseCompany}
                            companyName={props.companyInformation.name}
                            companyList={props.analytics.company_list}
                            />
                        </Card>
                    </Column>
                </Row>
                { (!_.isEmpty(activeCompany) && _.isEmpty(props.analytics.active_company_error)) && 
                (<>
                    <Row>
                    <Column grow={1}>
                        <MainCard title={activeCompany ? (
                            selectedCompanyTitle(activeCompany)) : "Please select a company"}
                        customRighthand={moreInfoAdd(modalText.OVERVIEW_TITLE, modalText.OVERVIEW_CONTENT)}>
                            <Overview 
                                selectedKey={activeCompany} // TODO: clean up
                                scoreData={props.analytics.active_company.score_data}/>
                        </MainCard>
                    </Column>
                </Row>
                {
                    (props.analytics && props.analytics.active_company) && (
                        <Row>
                    <Column grow={1}>
                        <MainCard title={"Board Diversity"}
                        customRighthand={moreInfo(modalText.BOARD_TITLE, modalText.BOARD_CONTENT)}>
                            <DiversityCharts

                            companyName={props.analytics.active_company.company_name}
                            genderString="Board Gender Diversity" raceString="Board Racial & Ethnic Diversity"
                                activeCompany={props.analytics.active_company}
                                divType="board"
                            />
                            {FEATURE_FLAGS.showLastUpdated && 
                                <LastUpdatedStamp lastUpdatedStamp={props.analytics.active_company?.company_diversity?.last_updated}/>
                            }
                            
                        </MainCard>
                        </Column>
                </Row>
                    )
                }
                {(props.analytics && props.analytics.active_company) && (
                    <Row>
                    <Column grow={1}>
                        <MainCard title={"Executive Diversity"}
                        customRighthand={moreInfo(modalText.EXECUTIVE_TITLE, modalText.EXECUTIVE_CONTENT)}>
                            <DiversityCharts 
                            companyName={props.analytics.active_company.company_name}
                            genderString="Executive Gender Diversity"
                            raceString="Executive Racial & Ethnic Diversity"
                            activeCompany={props.analytics.active_company}
                            divType="executive"
                            />
                            {FEATURE_FLAGS.showLastUpdated && 
                                <LastUpdatedStamp lastUpdatedStamp={props.analytics.active_company?.company_diversity?.last_updated}/>
                            }
                        </MainCard>
                        </Column>
                </Row>

                )}
                {(props.analytics && props.analytics.active_company) && (
                    <Row>
                    <Column grow={1}>
                        <MainCard title={"Inclusion Score"}
                        customRighthand={moreInfo(modalText.INCLUSION_TITLE, modalText.INCLUSION_CONTENT)}>
                            <Frequency selectedKey={activeCompany}
                            kpis={props.analytics.active_company.kpis}
                            companyName={props.analytics.active_company.company_name}
                            painPoints={props.analytics.active_company.pain_points}
                            painPointScores={props.analytics.active_company.pain_point_scores}
                            iPainPoints={props.analytics.active_company.i_pain_points}
                            kpiMap={props.analytics.pp_kpi_map}
                            />
                            {FEATURE_FLAGS.showLastUpdated && 
                                <LastUpdatedStamp lastUpdatedStamp={props.analytics.active_company?.pain_points?.last_updated}/>
                            }
                        </MainCard>
                        </Column>
                </Row>
                )}
                {(props.analytics && props.analytics.active_company) && (
                    <Row>
                        <Column grow={1}>
                            <MainCard title={"Commitment Score"}
                            customRighthand={moreInfo(modalText.COMMITMENT_TITLE, modalText.COMMITMENT_CONTENT)}>
                                <Commitment 
                                scoreData={props.analytics.active_company.score_data}
                                commitmentDetail={props.analytics.active_company.commitment_detail}
                                getAnalyticsPdfLink={props.getAnalyticsPdfLink}
                                pdfLink={props.analytics.pdf_link}
                                />
                                {FEATURE_FLAGS.showLastUpdated && 
                                    <LastUpdatedStamp lastUpdatedStamp={props.analytics.active_company?.commitment_detail?.last_updated}/>
                                }
                            </MainCard>
                        </Column>
                    </Row>
                )}
                {(props.analytics && props.analytics.active_company) && (
                    <Row>
                        <Column grow={1}>
                            <MainCard title={"Company Info"}
                            customRighthand={null}>
                                <CompanyInfo
                                    industry={props.analytics.active_company?.industry_name}
                                    indices={props.analytics.active_company?.indices}
                                />
                            </MainCard>
                        </Column>
                    </Row>
                )}
                
                </>)
                }
                {
                    (!_.isEmpty(props.analytics.active_company_error)) && errorBody
                }
            </div>
        </div>
    );
};

export default AnalyticsSearch;
