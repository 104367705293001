/*
Simple no-nonsense dictionary to control some feature flags.

Usage: import and wrap a specific feature within the render method: 

(FEATURE_FLAGS.featureName && <RenderFeature/>)
*/

export const FEATURE_FLAGS = {
	"showLastUpdated": true
}
