// Sagas are middleware that describe asynchronous actions or side effects in
// the app - for example, creating a new schedule or getting a list of schedules
// from the server. Sagas either run at init or listen for actions to initiate
// asynchronous behaviors. Sagas are an alternative to thunks and other
// async middleware commonly used in Redux docs and tutorials.
//
// NOTE: Don't put side effects in actions, reducers, or components! Keep side
// effects in sagas or utility functions used by sagas.
//
// https://yelouafi.github.io/redux-saga/
import createSagaMiddleware, {takeEvery} from 'redux-saga';
import dataSagas from './data';
import questionSagas from './question';
import calculatorSagas from './calculator';
import analyzeSagas from './analyze';
import authSagas from './auth';
import homeSagas from './home';
import adminSagas from './admin';
import messageSagas from './message';
import improveSagas from './improve';
import roadmapSagas from './roadmap';
import connectSagas from './connect';
import onePagerSagas from './lso';
import communicationsSagas from './communications'
import diversityvcSagas from "./diversityvc";
import analyticsSagas from "./analytics";
import surveySagas from './survey';
import ilndashboardSagas from "./ilndashboard";
import * as Sentry from '@sentry/browser';

const onError = (error) => {
    Sentry.captureException(error);
};

// Saga middleware
export let sagaMiddleware = createSagaMiddleware({onError});

// Run all the sagas
export let sagas = [
    ...dataSagas,
    ...questionSagas,
    ...calculatorSagas,
    ...communicationsSagas,
    ...analyzeSagas,
    ...authSagas,
    ...homeSagas,
    ...adminSagas,
    ...messageSagas,
    ...improveSagas,
    ...roadmapSagas,
    ...connectSagas,
    ...onePagerSagas,
    ...diversityvcSagas,
    ...analyticsSagas,
    ...surveySagas,
    ...ilndashboardSagas
].map(createWatcher);

// For each action/saga pair, the action should trigger the saga each time
function createWatcher(actionSagaPair) {
    return function* () {
        yield* takeEvery(...actionSagaPair);
    };
}
