import React, { useState } from "react";
import Card from '../../utils/Card';
import "./index.scss";

import MapChart from "./mapChart";

const ILNRegional = ({data}) => {
  const [content, setContent] = useState("");

  const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const showRegionalBreakdown = (query) => {
    const filterContinents = data.filter(function (location) {
      if (location.continent.toLowerCase().indexOf(query.toLowerCase()) === -1) {
        return
      }
      return location.continent.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    })

    let totalSum = 0
    for (var i in filterContinents) {
      totalSum = totalSum + filterContinents[i].amount
    }

    let roundedNum = Math.round(totalSum)
    let numToDisplay;

    if(roundedNum > 999) {
      const formatNum = (roundedNum / 1000).toFixed(3);
      numToDisplay = `$${formatNum} T`
    } else {
      numToDisplay = `$${roundedNum} B`
    }
    const result = () =>{
      return ( 
       <div>
         <h3>{numToDisplay}</h3>
         <p>{toTitleCase(query)}</p>
       </div>
      )
    }

    if (query === '') {
      setContent('')
    } else {
      setContent(result)
    }
  }

  return (
    <Card className="iln-card" >
      <div className="iln-regional-title">
        <h2 className="iln-h2-regional">Regional Breakdown</h2>
        <p>Hover over the continent region to view ILN’s 2021 diversity & inclusion investment.</p>
      </div>
      <div className="iln-map-container">
        <div className="iln-map">
          <MapChart 
            setTooltipContent={(text) => showRegionalBreakdown(text)}
            toolTipText={content} />
        </div>
      </div>
    </Card>
  );
}

export default ILNRegional
