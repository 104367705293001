import React from 'react';
import {Button, Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import history from "../../history";
import classNames from "classnames";

const Progress = ({ selected, handleNext, handleBack, handleReset, draftSurveys }) => {
    const { pathname } = history.location;
    const steps = [
        "init",
        "setup",
        "create",
        "review",
        "approved",
        "draft",
        "requires-updates",
        "launch",
        "live"
    ];
    let currentStep = steps.indexOf(pathname.split('/')[2]) + 1;

    return (
        <div id={"survey-progress"}>
            <Container>
                <ul>
                    <li className={classNames("progress-step progress-step-setup", { selected: ["setup", "create", "review", "live"].includes(selected) })}>Setup</li>
                    <li className={classNames("progress-step progress-step-create", { selected: ["create", "review", "live"].includes(selected) })}>Survey Creation</li>
                    <li className={classNames("progress-step progress-step-review", { selected: ["review", "live"].includes(selected) })}>Review & Approve</li>
                    <li className={classNames("progress-step progress-step-live", { selected: ["live"].includes(selected) })}>Survey is Live</li>
                    {/*{*/}
                    {/*    currentStep < steps.length && <li><Link to={`/survey/${steps[currentStep]}`}><Button>Next</Button></Link></li>*/}
                    {/*}*/}
                    <li><Button onClick={handleNext}>Next</Button></li>
                    {/*<li><Link to={`/survey/${steps[currentStep - 2]}`}>Draft</Link></li>*/}
                    { draftSurveys &&  <li><Link onClick={draftSurveys}>Draft</Link></li> }
                    { handleReset && <li><Link onClick={() => {window.confirm("Are you sure you want to exit the survey?") && handleReset()}}>Cancel</Link></li> }
                </ul>
            </Container>
        </div>
    );
};

export default Progress;
