import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import classNames from "classnames";
import _ from 'underscore';
import Filters, {Year, Office, Level, Department, Tenure, FiltersSelected, FilterContext} from '../Filters';
import "./index.scss";
import {industryOrRegional, mapChoiceLabel} from "../../../utils";
import { _makeCell } from "../../Home/HeatMap/utils";
import {
    getOfficeRegionLabel,
    mapMetaKpiLabels,
} from "../../../utils/labels";
import { withTranslation } from 'react-i18next';
import MainCard from "../../utils/MainCard";
import Legend from './Legend';
import HeatmapSnapshot from "./Snapshot";
import { DashboardTooltip } from '../../utils/DashboardTooltip';
import * as m from '../../../translations/mapping';
import { mapT } from '../../../translations/utils';

/* Table columns in desired order of appearance */
const COLUMN_KEYS = [
    'inclusive_culture_average',
    'review_feedback_average',
    'access_to_network_average',
    'flexibility_weight_average',
    'safety_average',
    'recruiting_and_hiring_average'
];

class HeatMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHover: 'normal',
            row: "-1",
            column: '-1',
            selectedFilters: {},
            tab: "overview"
        };
    };

    onHover = (r, c) => {
        this.setState({
            isHover: 'hover',
            row: r,
            column: c
        })
    };

    offHover = () => {
        this.setState({
            isHover: 'hoverEnter',
            row: "-1",
            column: "-1"
        })
    };

    _resetData () {
        this.props.getInclusionMetricsData({data: {year: "", level: "", office: "", department: ""}});
    }

    componentDidMount() {
        this._resetData();
    }

    selectTab = tab => {
        this.setState({
            ...this.state,
            tab
        });
        if (tab === "overview") {
            // TODO @sahanarula this can't be called w empty payload
            // not sure how to refer to selected filters here
            this._resetData();
        } else {
            this.props.getNonOverviewMetricsData({ data: { key: tab } });
        }
    };

    handleApplyFilters = data => {
        if (this.state.tab === "overview") {
            this.props.getInclusionMetricsData({ data });
        } else {
            this.props.getNonOverviewMetricsData({ data: { ...data, key: this.state.tab } });
        }
    };

    handleClearFilters = data => {
        this._resetData();
    }

    hoverEnter = (row, column) => {
        this.onHover(row, column);
    };

    render() {
        const { metadata = [], companyName, t, data, prevData, overall, previousOverall } = this.props;
        const { tab } = this.state;

        const disableMyKpi = (tab !== "overview");

        return (
            <MainCard title={t("ANALYZE.CARD_HEADERS.HEATMAP.title")}
                      toolTipContent={t("ANALYZE.CARD_HEADERS.HEATMAP.tooltip")}>
            <div id={"heat-map"} className={"bootstrap-override"}>
                <Filters
                    hidden={tab !== "overview"}
                    filters={this.props.allFilters}
                    applyFilters={this.handleApplyFilters.bind(this)}
                    clearFilters={this.handleClearFilters.bind(this)}
                    companyName={companyName}>
                    <Year label={t("ANALYZE.FILTERS.YEAR")}/>
                    <Office label={getOfficeRegionLabel(companyName)}/>
                    <Level label={t("ANALYZE.FILTERS.LEVEL")}/>
                    <Department label={t("ANALYZE.FILTERS.DEPARTMENT")}/>
                    <Tenure label={t("ANALYZE.FILTERS.TENURE")}/>
                    {
                        <FilterContext.Consumer>
                            {data => {
                                if (this.state.selectedFilters !== data.values) {
                                    this.setState({
                                        ...this.state,
                                        selectedFilters: data.values
                                    })
                                }
                            }}
                        </FilterContext.Consumer>
                    }
                </Filters>
                <HeatmapSnapshot title={tab}
                                 companyName={companyName}
                                 data={data}
                                 prevData={prevData}
                                 overall={overall}
                                 previousOverall={previousOverall}/>
                <div>
                    <Table className={"datatable"}>
                        <thead className="datatable__scrollable-table-head">
                            <tr className={"datatable-filters"}>
                                <th onClick={() => this.selectTab("overview")} className={classNames({"selected": tab === "overview"})}>{t("ANALYZE.FILTERS.OVERVIEW")}</th>
                                <th onClick={() => this.selectTab("department")} className={classNames({"selected":  tab === "department"})}>{t("ANALYZE.FILTERS.DEPARTMENT")}</th>
                                <th onClick={() => this.selectTab("office")} className={classNames({"selected":  tab === "office"})}>{getOfficeRegionLabel(companyName)}</th>
                                <th onClick={() => this.selectTab("level")} className={classNames({"selected":  tab === "level"})}>{t("ANALYZE.FILTERS.LEVEL")}</th>
                                <th onClick={() => this.selectTab("year")} className={classNames({"selected":  tab === "year"})}>{t("ANALYZE.FILTERS.YEAR")}</th>
                                <th onClick={() => this.selectTab("tenure")} className={classNames({"selected":  tab === "tenure"})}>{t("ANALYZE.FILTERS.TENURE")}</th>
                                <th className={"empty-cell"}></th>
                            </tr>
                            <tr className={"datatable-border"}></tr>
                            <tr className={"datatable-metric"}>
                                <th>
                                {
                                    data.length ? null : "No results."
                                }
                                </th>
                                {   data.length ?
                                    metadata.map((meta, id) =>
                                    (<th key={id}>

                                    <DashboardTooltip content={mapT(m.METRIC_TO_TOOLTIP, meta.name, companyName)}>
                                        <span>
                                        {
                                            mapT(m.METRIC, mapMetaKpiLabels(companyName, meta.name), companyName)
                                        }
                                        </span>
                                    </DashboardTooltip>

                                        </th>)) :
                                null
                                }
                            </tr>
                        </thead>
                        
                        
                        <tbody className="datatable__scrollable-table-body">
                        {data.length ? data.map((metric, id) => {
                            const title = mapT(m.ANALYZE_HEATMAP_SIDEBAR, mapChoiceLabel(industryOrRegional(metric.title,this.props.companyName)), companyName)
                            const formatTitle = (title) => {
                               if (_.isEmpty(title)) { return null; }
                               return title.replace("/", " / ")
                            }
                            return (
                                <tr id="xLabel-cell" key={id}>
                                    <td>
                                        {
                                            formatTitle(title)
                                        }
                                    </td>
                                    {COLUMN_KEYS.map(key => (_makeCell(
                                        metric,
                                        prevData && prevData[id] ? prevData[id] : null,
                                        key,
                                        id,
                                        disableMyKpi)))}
                                </tr>
                            )
                        }) : null }
                        </tbody>
                        <tfoot>
                            <Legend/>
                            {
                                tab === "overview" && <FiltersSelected 
                                values={this.state.selectedFilters}  
                                allFilters={this.props.allFilters}
                                    companyName={this.props.companyName}
                                />
                            }
                        </tfoot>
                    </Table>
                </div>
            </div>
            </MainCard>
        );
    }
};

export default withTranslation()(HeatMap);
