import React from 'react';
import {Container, Form} from "react-bootstrap";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import classNames from 'classnames';
import Progress from "./Progress";

const Setup = ({ handleSetupNext, handleSetupBack, handleReset }) => {
    const setupSchema = yup.object().shape({
        deployingWith: yup.string().required("Please choose an option"),
        language: yup.array().required("Please choose at least one language"),
    });

    const {register: setup, handleSubmit, errors} = useForm({
        resolver: yupResolver(setupSchema)
    });

    const _handleNext = () => {
        handleSubmit(handleSetupNext)();
    };

    const _handleBack = () => {
        handleSetupBack();
    };

    return (
        <div className={"survey-setup"}>
            <Progress selected={"setup"} handleReset={handleReset} handleNext={_handleNext} handleBack={_handleBack}/>
            <Container className={"survey-setup__content-container"}>
                <h5 className={"centered"}>Survey Setup</h5>
                <ol>
                    <li>
                        <p className={"question-title"}>1. Are you deploying with your:</p>
                        <ul>
                            <li>
                                <Form.Check
                                    label={"Employees"}
                                    type="radio"
                                    name={"deployingWith"}
                                    ref={setup}
                                    value={"EMPL"}
                                    id={"deployingWithEmployees"} />
                            </li>
                            <li>
                                <Form.Check
                                    label={"Members"}
                                    type="radio"
                                    ref={setup}
                                    name={"deployingWith"}
                                    value={"MEM"}
                                    id={"deployingWithMembers"} />
                            </li>
                            <li>
                                <Form.Check
                                    label={"Portfolio Companies"}
                                    type="radio"
                                    name={"deployingWith"}
                                    ref={setup}
                                    value={"PRT"}
                                    id={"deployingWithPortfolioCompanies"} />
                            </li>
                            <li>
                                <Form.Control.Feedback
                                    type={"invalid"}
                                    className={classNames({ "d-block": errors.deployingWith })}>
                                    {errors.deployingWith && errors.deployingWith.message}
                                </Form.Control.Feedback>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className={"question-title"}>2. What language(s) are you looking to deploy your survey?</p>
                        <ul>
                            <li>
                                <Form.Check
                                    label={"English"}
                                    value={"en"}
                                    type="checkbox"
                                    ref={setup}
                                    name={"language"}
                                    id={"english"} />
                            </li>
                            <li>
                                <Form.Check
                                    label={"French"}
                                    value={"fr"}
                                    type="checkbox"
                                    ref={setup}
                                    name={"language"}
                                    id={"french"} />
                            </li>
                            <li>
                                <Form.Check
                                    label={"Spanish"}
                                    value={"esp"}
                                    type="checkbox"
                                    ref={setup}
                                    name={"language"}
                                    id={"spanish"} />
                            </li>
                            <li>
                                <Form.Control.Feedback
                                    type={"invalid"}
                                    className={classNames({ "d-block": errors.language })}>
                                    {errors.language && errors.language.message}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please select an option!</Form.Control.Feedback>
                            </li>
                        </ul>
                    </li>
                </ol>
            </Container>
        </div>
    );
};

export default Setup;
