import React, {Component} from 'react';
import Card from '../../utils/Card';
import './index.scss';

// At one point these were going to have links, left these in jic they are added again.
const programs = [
    {
        title: "Recruiting & HR",
        items: [
            {label: "Interview Quotas", link: ''},
            {label: "Inclusive Mentorship Programmes", link: ''},
            {label: "Family Leave Policy", link: ''},
            {label: "Returnship Programme", link: ''},
            {label: "Flexible Work Policies", link: ''},
            {label: "Emergency Care Options", link: ''},
            {label: "Equal Pay Analysis", link: ''},
            {label: "Training and Support Programmes for Underrepresented Hires", link: ''},
        ]
    }, {
        title: "Internal Policy & Culture",
        items: [
            {label: "Harassment and Discrimination Policies", link: ""},
            {label: "Organisational Culture Surveys And Tracking Progress", link: ""},
            {label: "Inclusive Office Spaces", link: ""},
            {label: "Leadership Training", link: ""},
            {label: "Anti-Harassment Training", link: ""},
            {label: "Promotions & Attritions Tracking", link: ""},
            {label: "Employee Resource Groups or Circles", link: ""},
            {label: "Harassment Reporting System", link: ""},
            {label: "Cultural Competency Training", link: ""}
        ]
    }, {
        title: "Dealflow Sources",
        items: [
            {label: "Partnerships with D&I Organisations", link: ""},
            {label: "Unbiased Assessment Process", link: ""},
            {label: "Tracking Diversity of Founders Who Have Been Referred", link: ""},
            {label: "Diversity Goals in Investment Strategy", link: ""},
            {label: "Sponsorship Programme", link: ""},
            {label: "D&I Due Diligence Questions", link: ""}
        ]
    }, {
        title: "Portfolio Guidance",
        items: [
            {label: "Additional Guidance for Underrepresented Portfolio Founders", link: ""},
            {label: "Diversity on Boards of Directors", link: ""},
            {label: "Standard Code of Conduct & Zero-Tolerance Policy for Portfolio", link: ""},
            {label: "Founder Training", link: ""},
            {label: "Mental Health Support", link: ""},
            {label: "Independent Ombudsman", link: ""}
        ]
    }
];


const l1Requirements = [
    'Organisational D&I Statement and Strategy',
    'Chief D&I Officer or Champion',
    'Measure D&I as a metric (KPI)',
    'Policies Widening Applicant Pool Beyond Traditional Sources*',
    'Policies Reducing Biases in Hiring Processes*',
    'Tracking and Reporting Dealflow Demographics*'
];

const l2Requirements = [
    <span>All <b>Level 1</b> Requirements</span>,
    'Diverse Investment Strategy*',
    'Portfolio Inclusion Assessment*'
];

const ProgramList = ({title, items}) => {
    return (
        <div className="program-list">
            <h3>{title}</h3>
            <ol className="red-number-list">
                {items.map(({label, link}, index) => {
                    return (
                        <li key={label}>
                            <div className={'list-number red-color'}>{index + 1}</div>
                            {label}</li>
                    )
                })}
            </ol>
        </div>
    )
};

const RedNumberList = ({items}) => {
    return (
        <ol className="red-number-list">
            {items.map((label, index) => {
                return (
                    <li key={index}>
                        <div className='list-number red-color'>{index + 1}</div>
                        {label}
                    </li>
                )
            })}
        </ol>
    )
};


class About extends Component {

    render() {
        return (
            <Card className="about">
                <div id="logos">
                    <img src="/images/DiversityVC/diversity_vc_logo.png"/>
                    <img style={{width:"30px",height:'30px'}} src="/images/DiversityVC/diversio_logo.png"/>
                    <img src="/images/DiversityVC/onetech_logo.png"/>
                </div>
                <div className="section" id="what-is-it">
                    <div>
                        <h1>What is the Diversity VC Standard?</h1>
                        <p>
An assessment and certification process that sets a benchmark for best practice on Diversity and Inclusion (D&I) in VC and sends a signal that a fund is a D&I leader.
</p>
<p>    
The Diversity VC Standard provides VCs with the tools and recommendations they need to open their networks and make funding available to underrepresented founders, as well as cultivate an environment where founders and colleagues from all backgrounds feel they belong in the industry and the ecosystem.
</p> 
<p>  
The Diversity VC Standard also evaluates where dealflow originates and how it is tracked and assessed. The Standard identifies what VCs can do to expand the demographics of founders in their pipeline and across their portfolios. This is done by both examining the investment process and providing practical recommendations for how existing portfolio companies can implement their own D&I policies.
</p>
<p>    
The assessment and recommendations that form the Diversity VC Standard are evidence-led and based on research from the Harvard Business Review, Deloitte, McKinsey, Diversity VC, and Diversio. The Standard has been developed in partnership with Diversio, the world’s leading D&I solution provider for investors, and One Tech. Diversio works with industry bodies including ILPA and the Investor Leadership Network (ILN) and is the technology provider for LPs and GPs globally. The Diversity VC Standard is strongly supported by the ILN and endorsed by The Rose Review. The project is powered by Diversio and OneTech with input from their respective specialists.
</p>
<p>    
To help funds implement the recommended practices and policies the Standard provides, Diversity VC and Diversio have curated a list of third party organisations who can advise on HR policies, leadership training, and more. These organisations have been chosen based on the quality and impact of their work. The team behind the Standard does not have commercial ties to any recommended providers.
</p>
                    </div>
                </div>
                <div className="section light" id="must-have">
                    <div className="columns">
                        <div>
                            <h2>To Receive a Level 1 Standard You Must Have:</h2>
                            <img src="/images/DiversityVC/level_1.png"/>
                            <RedNumberList items={l1Requirements}/>

                        </div>
                        <div>
                            <h2>To Receive a Level 2 Standard You Must Have:</h2>
                            <img src="/images/DiversityVC/level_2.png"/>
                            <RedNumberList items={l2Requirements}/>

                        </div>
                    </div>
                    <div className="columns">
                        <div className="additional">
                            <div className="large-red">+</div>
                            <div>An Additional <b>4</b> Programmes (1 from each section) From the List Below</div>
                        </div>
                        <div className="additional">
                            <div className="large-red center">+</div>
                            <div>An Additional <b>8</b> Programmes (2 from each section) From the List Below</div>
                        </div>
                    </div>
                </div>
                <div id="programs" className="section light">
                    <div className="columns">
                        {programs.map((program, index) => <ProgramList key={index}{...program}/>)}
                    </div>
                    <p>
                        *Participants have twelve months from initial assessment to implement programs marked with an
                        asterisk (*). Diversity VC reserves the right to audit VCs to ensure these criteria have been
                        met.
                    </p>
                </div>
            </Card>
        )
    }
}

About.propTypes = {};

export default About;
