import { ActionTypes } from '../actions';

const defaultAnalytics = {
    company_list: [],
    active_company: {},
    active_company_error: null,
    pp_kpi_map: {},
    user_portfolio: [],
    csv_download: {},
    pdf_link: {},
    companies_data: [],
    index_scores: [],
    isInaccuracySubmitting: false,
    isInaccuracySubmitted: false,
    loadedAnalyticsCompaniesData: false,
    failedLoadingAnalyticsCompaniesData: false,
};

export default (analytics= defaultAnalytics, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADED_ANALYTICS_USER_PORTFOLIO:{
                const user_portfolio = [...action.payload.user_portfolio];
                return {
                    ...analytics,
                    user_portfolio,
                };
            }
            case ActionTypes.LOADED_ANALYTICS_COMPANY_LIST:{
                const company_list = [...action.payload.company_list];
                return {
                    ...analytics,
                    company_list,
                };
            }
            case ActionTypes.LOADED_ANALYTICS_KPI_MAP:{
                const pp_kpi_map = {...action.payload.pp_kpi_map};
                return {
                    ...analytics,
                    pp_kpi_map,
                };
            }
            case ActionTypes.LOADED_ANALYTICS_COMPANY_DATA:{
                const active_company = {...action.payload};
                return {
                    ...analytics,
                    active_company_error: null,
                    active_company
                };
            }
            case ActionTypes.LOADING_ANALYTICS_COMPANIES_DATA:
                return {
                    ...analytics,
                    loadedAnalyticsCompaniesData: false,
                }
            case ActionTypes.LOADED_ANALYTICS_COMPANIES_DATA:
                return {
                    ...analytics,
                    companies_data: action.payload,
                    loadedAnalyticsCompaniesData: true,
                    failedLoadingAnalyticsCompaniesData: false
                }
            case ActionTypes.LOADED_ANALYTICS_PORTFOLIO_CSV:{
                const csv_download = {...action.payload};
                return {
                    ...analytics,
                    csv_download,
                };
            }
            case ActionTypes.LOADED_ANALYTICS_PDF_LINK:{
                const pdf_link = {...action.payload};
                return {
                    ...analytics,
                    pdf_link,
                };
            }
            case ActionTypes.CLEAR_ANALYTICS_PDF_LINK:{
                return {
                    ...analytics,
                    pdf_link: {},
                };
            }
            case ActionTypes.CLEAR_ANALYTICS_COMPANY_DATA:{
                const active_company = {};
                return {
                    ...analytics,
                    active_company
                };
            }
            case ActionTypes.FAILED_LOADING_ANALYTICS_COMPANY_DATA:{
                if (action.payload && action.payload.httpStatusCode == 403) {
                    return {
                        ...analytics,
                        active_company: {},
                        active_company_error: {
                            error: "UNAUTHORIZED"
                        }
                    }
                } else {
                    return {
                        ...analytics,
                        active_company: {},
                        active_company_error: {
                            error: "UNKNOWN"
                        }
                    }
                }
            }
            case ActionTypes.FAILED_LOADING_ANALYTICS_COMPANIES_DATA:{
                return {
                    ...analytics,
                    loadedAnalyticsCompaniesData: true,
                    failedLoadingAnalyticsCompaniesData: true
                }
            }
            case ActionTypes.LOADED_INDEX_SCORES:
                return {
                    ...analytics,
                    index_scores: action.payload
                }
            case ActionTypes.DOING_REPORT_INACCURACY:
                return {
                    ...analytics,
                    isInaccuracySubmitting: true,
                    isInaccuracySubmitted: false
                }
            case ActionTypes.DONE_REPORT_INACCURACY:
                return {
                    ...analytics,
                    isInaccuracySubmitting: false,
                    isInaccuracySubmitted: true
                }
            case ActionTypes.RESET_INACCURACY_STATE:
                return {
                    ...analytics,
                    isInaccuracySubmitting: false,
                    isInaccuracySubmitted: false
                }
            default:
                return analytics;
        }
    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
        return analytics
    }
};
