import React, { useState, useEffect, View } from 'react';
import { withTranslation } from 'react-i18next';
import { PiePlaceholder } from '../../utils/Placeholders';
import { Row, Column } from '../../utils/FlexBox';
import MainCard from '../../utils/MainCard';
import DiversityCompositionDetails from './DiversityCompositionDetails';
import { BChart } from './BChart';
import Legend from './Legend';
import {getTranslation} from "../../../translations/utils"
import { reshapeData } from './reshapeData';
import { isPortfolioCompany } from "../../../utils/companyChecks";
import ReactGA from 'react-ga';
import _ from 'underscore';

import './index.scss';

const DiversityComposition = (props) => {
    /* Combine data, industryData, and ndg, and expose via state for debugging */
    const [reshapedData, setReshapedData] = useState(null);

    /* Control the diversity dimension shown in right hand box */
    const [dimension, setDimension] = useState(0);

    let {breakdown, data, industryData, ndg, companyName, t} = props;

    useEffect(() => {
        // any time these incoming props change, null out the chart
        setReshapedData(null);
        setDimension(0);
    }, [breakdown, data, industryData, companyName]);
    

    const getSubheader = () => {
        return getTranslation("HOME.CARD_HEADERS.DIVERSITY_COMPOSITION.subheader", companyName)
    }

    

    const loaded = (data.length > 0 && Object.keys(industryData).length > 0 && Object.keys(ndg).length > 0);

    if (loaded && !reshapedData) {
        const combinedData = reshapeData(data, industryData, ndg, breakdown);
        setReshapedData(combinedData);
    }

    let chart = (!_.isEmpty(reshapedData)) 
    ? <BChart
        reshapedData={reshapedData}
        onMouseMove={state => {

            ReactGA.event({
            category: 'User',
            action: 'Graphic Hover',
            label: 'Diversity Composition bar graph'
            });

            state.isTooltipActive && setDimension(state.activeTooltipIndex);
            }}
        activeIndex={dimension}
        companyName={companyName} 
        />
    : <PiePlaceholder isLoading />;

    let detailData = (reshapedData)
    ? reshapedData[dimension]
    : null;
    
    return (
        <MainCard title={t("HOME.CARD_HEADERS.DIVERSITY_COMPOSITION.title")}
            subheader={getSubheader()}
            toolTipContent={getTranslation("HOME.CARD_HEADERS.DIVERSITY_COMPOSITION.tooltip", companyName)}>
            <Row>
                <Column width="65%">
                    <div className="diversity_composition__left_side_wrapper">
                        <div className="diversity_composition__chart">{chart}</div>
                        <div className="diversity_composition__legend">
                            {reshapedData ? <Legend companyName={companyName}/> : null}
                        </div>
                    </div>
                </Column>
                <Column width="35%">
                    {reshapedData ? <DiversityCompositionDetails companyName={companyName} detailData={detailData} profile={props.profile}/> : null}
                </Column>
            </Row> 
        </MainCard>
    )
};

export default withTranslation()(DiversityComposition);
