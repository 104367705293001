import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './index.scss'
import {Column, Row} from "../utils/FlexBox";
import history from "../../history";
import Diversity from "./Diversity";
import Inclusion from "./Inclusion";

import { _maybeMixin } from '../../utils';
import _ from 'underscore';

_maybeMixin();

const Analyze = (props) => {
    const { pathname } = history.location;
    useEffect(() => {
        if (pathname === "/analyze") {
            history.replace("/analyze/diversity");
        }
    }, [pathname]);

    const renderComp = (pathname) => {
        switch(pathname) {
            case '/analyze/diversity':
                return <Diversity { ...props } />;
            case '/analyze/inclusion':
                return <Inclusion { ...props } />;
        }
    };

    const isBVPBP = () => {
        const companyName = _.maybe(props, 'profile', 'company');
        if (companyName && companyName === "Bessemer Venture Partners Brief Portfolio") {
            return true;
        }
        return false;
    }

    return (
        <div>
            <div className="App">
                <Row>
                    <Column grow={1}>
                        <h1 className={"transparent-header"}>D&I Snapshot</h1>
                    </Column>
                </Row>
                <Row>
                    <Column grow={1}>
                        <div className={"bootstrap-override"}>
                            <Nav className={"analyze-nav"} justify variant="tabs" defaultActiveKey="/analyze/diversity">
                                <Nav.Item>
                                    <Nav.Link active={history.location.pathname === "/analyze/diversity"} as={Link} to="/analyze/diversity">Diversity</Nav.Link>
                                </Nav.Item>
                                {isBVPBP() ? null : 
                                <Nav.Item>
                                    <Nav.Link active={history.location.pathname === "/analyze/inclusion"} as={Link} to="/analyze/inclusion">Inclusion</Nav.Link>
                                </Nav.Item>
                                }
                            </Nav>
                        </div>
                    </Column>
                </Row>

                {
                    renderComp(history.location.pathname)
                }
            </div>
        </div>
    );
};

export default Analyze;
