import React, { Fragment } from 'react';
import classNames from 'classnames';
import './index.scss';
import Card from '../../utils/Card';
import {getIndustryCompType, isDecimal} from "../../../utils";
import { withTranslation } from 'react-i18next';
import bvpLogo from './bvp_logo.jpeg';

/*
BVP Brief Portfolio special.
*/

const InclusionScoreBVP = () => {

    return (
        <Card>
            <div className="inclusion-score">
                <h3 className="inclusion-score__card-title-bvp">BVP Portfolio</h3>
                <div className="inclusion-score__container">
                    <img src={bvpLogo} width="200px"/>
                </div>
            </div>
        </Card>
    )
};

export default withTranslation()(InclusionScoreBVP);
