import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import languageEN from './translations/en.json'

/*
Source: https://medium.com/@tariqul.islam.rony/internationalization-localization-with-react-js-65d6f6badd56

There are 5 ways to get the translations (t) reference in any component:
1. Any class based smart component which is connected to redux store using connect(mapStateToProps, Actions)(Component)
    t should be defined in the props by default

2. Any class based stateless component can be wrapped an HOC (Higher order component) in order to get the reference to { t } in props
    import { withTranslation } from 'react-i18next';
    export class  App extends React.Component {
       render() {
            const {t} = this.props
            return (<h1>{t('Welcome to React')}</h1>)
       }
    }
    export default withTranslation()(App);

3. Using render props by importing { Translation } from 'react-i18next'
    import React from 'react';
    import { Translation } from 'react-i18next';
    export default class App extends React.Component {

       render() {
           return (
              <Translation>
                 {t => <h1>{t('Welcome to React')}</h1>}
              </Translation>
           )
       }
    }

4. Any function based dumb react component can use react hooks to get the { t } reference in props
    import React from 'react';
    import { useTranslation } from 'react-i18next';
    function App () {
         const { t, i18n } = useTranslation();
         return <h1>{t('Welcome to React')}</h1>
    }

5. Using Trans Component - this is useful if the translated string has html tags that you also
   want to render in DOM. No more dangerouslySetInnerHTML
    import React from 'react';
    import { Trans } from 'react-i18next';
    export default App extends React.Component {
         render() {
           return (<Trans><H1>Welcome to React</H1></Trans>)
         }
    }
*/

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: languageEN,
            // Add other language resources here, if any
            // jp: languageJP
        },
        /* default language when load the website in browser */
        lng: "en",
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: "en",
        /* debugger For Development environment */
        debug: true,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        }
    });

export default i18n;