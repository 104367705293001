import React, { useContext } from 'react';
import Select from "../../utils/Select";
import { FilterContext } from "./index";

const yearQuarterParser = (yyyyQn) => {
    const parsed = yyyyQn.match(/(\d{4})Q(\d)/);

    if ((parsed && parsed[1]) && parsed[2]) {
        return `${parsed[1]} Q${parsed[2]}`;
    }

    return yyyyQn;

}

const Year = ({ label = "Year", valId = "year", dataId = "years" }) => {
    const { data, onChange, values } = useContext(FilterContext);
    return <Select 
    renderValue={yearQuarterParser}
    options={data[dataId]} 
    label={label} 
    optionType={""} 
    onChange={value => onChange(valId, value)}
                   selectedValues={values[valId] || data[dataId][0]}/>
};

export default Year;
