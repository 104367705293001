import { ActionTypes } from '../actions';





const defaultState = {
    inclusionBreakdown: {
        data:[],
        metadata: {}
    },
    diversityBreakdown: [],
    diversityComposition: [],
    employeeOverview: [],
    inclusionMetrics: [],
    previousInclusionMetrics: [],
    inclusionTracker: [],
    diversityBreakdownByRole: {
        data: {},
        labels: {}
    },
    filters: {
        levels:[],
        departments:[],
        tenures: [],
        genders: {},
        years: [],
        quarters: [],
        offices: [],
        non_dominant_group: {},
        metadata: {},
        target_demographics: {},
    },
    freeText: [],
    filterMap: {}
};

const getKpis = meta => {
    return meta.kpi_keys.map(kpi => {
        switch (kpi) {
            case "inclusive_culture_average":
                return {
                    name: "Inclusive culture",
                    helperText: meta.kpi_keys_help[kpi]
                };

            case "review_feedback_average":
                return {
                    name: "Unbiased feedback & reviews",
                    helperText: meta.kpi_keys_help[kpi]
                };

            case "access_to_network_average":
                return {
                    name: "Access to networks",
                    helperText: meta.kpi_keys_help[kpi]
                };

            case "flexibility_weight_average":
                return {
                    name: "Flexible working options",
                    helperText: meta.kpi_keys_help[kpi]
                };

            case "safety_average":
                return {
                    name: "Safe work environment",
                    helperText: meta.kpi_keys_help[kpi]
                };
            case "recruiting_and_hiring_average":
                return {
                    name: "Recruiting & Hiring",
                    helperText: meta.kpi_keys_help[kpi]
                };
        }
    })
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADED_INCLUSION_BREAKDOWN_DATA:{
                return {
                    ...state,
                    inclusionBreakdown: action.payload.inclusion_breakdown
                };
            }
            case ActionTypes.LOADING_COMPANY_FILTERS:{
                return {
                    ...state,
                    isLoading: true
                };
            }
            case ActionTypes.LOADED_COMPANY_FILTERS:{
                const years = action.payload.quarters?
                    action.payload.quarters.map(quarter => `${quarter[0]}Q${quarter[1]}`):
                    [];
                return {
                    ...state,
                    isLoading: false,
                    filters: {...state.filters, ...action.payload, years}
                };
            }
            case ActionTypes.LOADED_DIVERSITY_BREAKDOWN_DATA:{
                return {
                    ...state,
                    diversityComposition: action.payload.diversity_composition,
                    diversityBreakdown: action.payload.diversity_breakdown
                };
            }
            case ActionTypes.LOADING_INCLUSION_METRICS_DATA: {
                return {
                    ...state
                }
            }
            case ActionTypes.LOADED_INCLUSION_METRICS_DATA:{
                return {
                    ...state,
                    inclusionMetrics: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].data : [],
                    previousInclusionMetrics: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].data : [],
                    overall: {
                        industry: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].industry_overall_score : [],
                        company: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].overall_score : [],
                        date: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].date : [],
                    },
                    previousOverall: {
                        industry: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].industry_overall_score : [],
                        company: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].overall_score : [],
                        date: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].date : [],
                    },
                    metadata: getKpis(action.payload.metadata)
                };
            }
            case ActionTypes.LOADED_NON_OVERVIEW_METRICS_DATA:
                return {
                    ...state,
                    inclusionMetrics: action.payload.inclusion_metrics,
                    previousInclusionMetrics: action.payload.previous_inclusion_metrics,
                    metadata: getKpis(action.payload.metadata)
                };
            case ActionTypes.LOADED_EMPLOYEE_OVERVIEW_DATA:
                return {
                    ...state,
                    employeeOverview: action.payload,
                };
            case ActionTypes.LOADED_INCLUSION_TRACKER_DATA:
                return {
                    ...state,
                    inclusionTracker: action.payload.inclusion_metrics
                };
            case ActionTypes.LOADED_DIVERSITY_TRACKER_DATA:
                return {
                    ...state,
                    diversityTracker: action.payload
                };
            case ActionTypes.LOADED_DIVERSITY_BREAKDOWN_BY_ROLE_DATA:
                return {
                    ...state,
                    diversityBreakdownByRole: action.payload
                };
            case ActionTypes.LOADED_FREE_TEXT:{
                return {
                    ...state,
                    freeText: action.payload
                };
            }
            case ActionTypes.LOADED_FILTER_MAP:
                return {
                    ...state,
                    filterMap: action.payload
                }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                }
            default:
                return state;

        }
    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }
};
