import React from 'react';
import classNames from "classnames";
import './index.scss';

const OpenText = ({ question }) => {
    const { title, required, type, placeholder, choices } = question;

    return (
        <div className={classNames("question", "ot-question", { "required": required })}>
            <p className={"question-title"}>{title} <span className={"asterisk"}>*</span> </p>
            <div className={"ot-question__text-container"}>
                <textarea placeholder="Description" rows="10" disabled></textarea>
            </div>
        </div>
    );
};

export default OpenText;
