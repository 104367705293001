import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/browser";

export const safeMixpanelTrack = (name, details) => {
	/*
	Wrap mixpanel.track calls in try/catch to ensure that tracking errors
	never compromise app functionality, and send errors to console
	*/
	try {
		mixpanel.track(name, details);
	  } catch (err) {
		  console.log(`[mixpanel] error: ${err}`);
		  Sentry.captureException(err);
	  }
}

export const convertTimestampToMonthYear = (djangoTimestamp) => {
	/*
	djangoTimestamp: last_updated value from Django: "2021-10-13T16:57:38.151131Z"
	returns: "Oct 2021"
	*/

	if (!djangoTimestamp) {
		return null;
	}

	const date = new Date(djangoTimestamp);
	return date.toLocaleString('en-us',{month:'short', year:'numeric'});
}

// credit: https://stackoverflow.com/a/57518703/3700490
const english_ordinal_rules = new Intl.PluralRules("en", {type: "ordinal"});

const suffixes = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th"
};

export function ordinal(number) {
    const suffix = suffixes[english_ordinal_rules.select(number)];
    return (number + suffix);
}
