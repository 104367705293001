import React, {useContext} from 'react';
import Select from "../../utils/Select";
import {FilterContext} from "./index";

const Kpis = ({ label = "KPIs", valId = "kpi", dataId = "kpis" }) => {
    const { data, onChange, values } = useContext(FilterContext);
    return <Select options={data[dataId]} label={label} multiple={true} onChange={value => onChange(valId, value)} selectedValues={values[valId] || data[dataId]}/>;
};

Kpis.className = "Kpis";

export default Kpis;