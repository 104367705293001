import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import Card from '../../../utils/Card';
import { DashboardTooltip } from '../../../utils/DashboardTooltip';
import './index.scss';
import PropTypes from 'prop-types';

class Score extends Component {

    labels = {
        recruiting: "Recruiting & HR",
        culture: "Internal Policy & Culture",
        dealflow_sources: "Dealflow Sources",
        portfolio_guidance: "Portfolio Guidance"
    };

    scoreToComponent(score) {
        return (
            <div>
                <div className="number">{this.props[score]}%</div>
                <p>{this.labels[score]}</p>
            </div>
        )
    }

    render() {
        const overallTitle = this.props.isIndustry ? "Industry Score" : "Your Score";

        return (
            <div className="score">
                <div className="overall">{overallTitle} <span className={'red-color'}>{this.props.overall}%</span></div>
                <div>
                    <table className={'details'}>
                        <tbody>
                        <tr>
                            <td>{this.scoreToComponent('recruiting')}</td>
                            <td>{this.scoreToComponent('culture')}</td>
                        </tr>
                        <tr>
                            <td>{this.scoreToComponent('dealflow_sources')}</td>
                            <td>{this.scoreToComponent('portfolio_guidance')}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

Score.propTypes = {
    recruiting: PropTypes.number,
    culture: PropTypes.number,
    dealflow_sources: PropTypes.number,
    portfolio_guidance: PropTypes.number,
    overall: PropTypes.number,
};


class Header extends Component {

    render() {
        
        const { score, benchmark, t } = this.props;

        return (
            <div className={'summary'}>
                <Card className={'certification'}>
                <div className="title-tooltip-wrapper">
                        <div className="your-certification-title">
                            <h1>Your Certification</h1>
                        </div>
                        <div className="icon-question-mark-wrapper">
                            <DashboardTooltip content={t("DIVERSITY_VC.CERTIFICATION.TOOLTIP")}>
                                <img className="icon icon-question-mark"/>
                            </DashboardTooltip>
                        </div>
                </div>
                    {
                        this.props.score && this.props.score.level !== undefined ?
                            <img src={`/images/DiversityVC/level_${this.props.score.level}.png`}
                                className="certification-image"
                            /> :
                            null
                    }
                    <a href={'#top-solution'}>See Your Recommendations</a>
                </Card>
                <Card className={'overview'}>
                    <div className="title-tooltip-wrapper">
                        <div>
                            <h1 style={{display:'inline-block'}}>Overview</h1>
                        </div>
                        <div className="icon-question-mark-wrapper">
                            <DashboardTooltip content={t("DIVERSITY_VC.OVERVIEW.TOOLTIP")}>
                                <img className="icon-question-mark"/>
                            </DashboardTooltip>
                        </div>
                    </div>
                    <p>Percentage of recommended best practices your fund has in place.</p>
                    <div className="score-container">
                        <Score
                            recruiting={score.recruiting}
                            culture={score.culture}
                            dealflow_sources={score.dealflow_sources}
                            portfolio_guidance={score.portfolio_guidance}
                            overall={score.overall}
                        />
                        <Score
                            isIndustry
                            recruiting={benchmark.recruiting}
                            culture={benchmark.culture}
                            dealflow_sources={benchmark.dealflow_sources}
                            portfolio_guidance={benchmark.portfolio_guidance}
                            overall={benchmark.overall}
                        />
                    </div>
                </Card>
            </div>
        );
    }
}

export default withTranslation()(Header);
