import React from 'react';
import './index.scss';

import { convertTimestampToMonthYear } from "../utils/utils";

const LastUpdatedStamp = ({ lastUpdatedStamp }) => {
	
	if (!lastUpdatedStamp) {
		return null;
	}

	return (
		<div className="analytics-last-updated">
			{`Last updated: ${convertTimestampToMonthYear(lastUpdatedStamp)}`}
		</div>
	);
}

export default LastUpdatedStamp;
