import {ActionTypes} from '../actions';

const defaultState = {
    painpoint: [],
    diversityComposition: [],
    industryDiversityComposition: [],
    diversityCompositionBreakdown: [],
    metricOverview: [],
    focusArea: [],
    inclusionScore: undefined,
    execSummary: undefined,
    isDeploySurveyProcessing: false,
    isDeploySurveyProcessed: false,
    isInformingPurchase: false,
    companyInformation: {
        company_permission: undefined
    },
    purchased: false
};


export default (state = defaultState, action) => {
    try {
        switch (action.type) {
            case ActionTypes.LOADED_PAINPOINT_DATA: {
                return {
                    ...state,
                    painpoint: action.payload
                };
            }
            case ActionTypes.LOADED_DIVERSITY_COMPOSITION_DATA: {
                return {
                    ...state,
                    diversityComposition: action.payload.diversity_composition,
                    industryDiversityComposition: action.payload.industry_diversity_composition,
                    diversityCompositionBreakdown: action.payload.diversity_breakdown
                };
            }
            case ActionTypes.LOADED_METRIC_OVERVIEW_DATA: {
                return {
                    ...state,
                    metricOverview: action.payload.metric_overview
                };
            }
            case ActionTypes.LOADED_FOCUS_AREA_DATA: {
                return {
                    ...state,
                    focusArea: action.payload
                };
            }
            case ActionTypes.LOADED_INCLUSION_SCORE_DATA: {
                return {
                    ...state,
                    inclusionScore: action.payload
                };
            }
            case ActionTypes.LOADED_EXEC_SUMMARY_DATA: {
                return {
                    ...state,
                    execSummary: action.payload
                };
            }
            case ActionTypes.DOING_DEPLOY_SURVEY:
                return {
                    ...state,
                    isDeploySurveyProcessing: true
                };
            case ActionTypes.DONE_DEPLOY_SURVEY:
                return {
                    ...state,
                    isDeploySurveyProcessing: false,
                    isDeploySurveyProcessed: true
                };
            case ActionTypes.DOING_INFORM_PURCHASE:
                return {
                    ...state,
                    isInformingPurchase: true
                };
            case ActionTypes.LOADED_COMPANY_INFORMATION:
                return {
                    ...state,
                    companyInformation: {
                        ...Object.assign({}, state.companyInformation, action.payload),
                        users: [], /* obfuscate users list in this product because it can reveal info about users from other companies. */
                    }
                };
            case ActionTypes.DO_CLEAR_COMPANY_INFORMATION:
                return {
                    ...state,
                    companyInformation: {
                        company_permission: undefined
                    }
                }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                }
            default:
                return state;

        }
    } catch (error) {
        console.error(`Error in appState reducer: ${error.message || error.code || error}`, error);
    }
};
