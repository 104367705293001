import i18n from "../i18n";
import {isPortfolioCompany, isOrganizationCompany, isMcrockDueDiligence} from "../utils/companyChecks"

/* Do translation based on mapping.js, return original string if mapping not found */
export const mapT  = (map, str, companyName=null) => {
    if (map && map[str]) {
        let constituentString = "employees";

        if (companyName) {
            constituentString = mapCompanyToConstituent(companyName);
        }

        const output = i18n.t(map[str]).replaceAll("[client_constituent]", constituentString);
        return output;
    }
    return str;
}

export const getTranslation = (str, companyName=null) => {
    let constituentString = "employees";

    if (companyName) {
        constituentString = mapCompanyToConstituent(companyName);
    }

    if (str) {
        return i18n.t(str).replaceAll("[client_constituent]", constituentString);
    }

    return str;
        
}

const mapCompanyToConstituent = (companyName) => {
    if(isPortfolioCompany(companyName))
        return "portfolio respondents";
    else if(isOrganizationCompany(companyName))
        return "member employees";
    else if(isMcrockDueDiligence(companyName))
        return "prospect company's employees"
    else
        return "employees";
}
