import { KEY_MAP } from './utils';
import { _maybeMixin } from '../../../utils';
import _ from 'underscore';

_maybeMixin();

/* Rearrange data objects into format required by Recharts.
See ./testReshapeData.js for more details. */
export const reshapeData = (data, iData, ndg, breakdown) => {
    const reshapedData = data.map((dimension, i) => {
        const dimName = Object.keys(dimension)[0];
        let ndList;

        if (!(breakdown[i] && breakdown[i][dimName])){
            ndList = []
        } else {
            if (dimName == "Disability") {
                // Shape of this payload is different: https://app.clickup.com/t/7ew52q
                // It is also sometimes not present for companies whose surveys did not include disability breakdowns
                ndList = breakdown[i][dimName].map(i => i["name"]);
            } else {
                ndList = Object.keys(breakdown[i][dimName]);
            }
        }

        let companyData;

        // returned value is [] if threshold not met
        // or all 0; i.e.
        // [{"name":"White","value":0},{"name":"POC","value":0},{"name":"Prefer not to answer","value":0}]
        if (dimension[dimName].length == 0 || 
            dimension[dimName].map(i => i.value).reduce((a,b) => (parseFloat(a)+parseFloat(b))) == 0) {
            companyData = {
                    th: 100,
                }
        } else {
            let dimNames = dimension[dimName].map((obj) => (obj.name));
            const ndLabel = ndg[KEY_MAP[dimName]];
            const pntsLabel = "Prefer not to answer";
            const dLabel = dimNames.filter((v) => {return (v != ndLabel) && (v != pntsLabel)})[0];
            
            companyData = {
                d: _.maybe(dimension[dimName].filter(o => o.name===dLabel)[0],"value"),
                nd: _.maybe(dimension[dimName].filter(o => o.name===ndLabel)[0], "value"),
                pnts: _.maybe(dimension[dimName].filter(o => o.name===pntsLabel)[0], "value")
            } 
        }
        
        return {
            "type": dimName,
            "data": companyData,
            "ndList": ndList,
        }
    });

    let newList = [];
    
    for (const dim of reshapedData) {
        const dimName = dim.type;
        let ndList = dim.ndList;

        if (!iData[dimName]) {
            continue;
        }
        
        let dimNames = iData[dimName].map(obj => obj.name);
        const ndLabel = ndg[KEY_MAP[dimName]];
        const pntsLabel = "Prefer not to answer";
        const dLabel = dimNames.filter((v) => {return (v != ndLabel) && (v != pntsLabel)})[0];

        if (dimName == "Disability") {
            // Shape of this payload is differnet: https://app.clickup.com/t/7ew52q
        } else {
            ndList = ndList.filter(v => v != dLabel && v != pntsLabel);
        }
        

        let industryData = {
            d: iData[dimName].filter(o => o.name===dLabel)[0].value,
            nd: iData[dimName].filter(o => o.name===ndLabel)[0].value,
            pnts: iData[dimName].filter(o => o.name===pntsLabel)[0].value
        }

        let domKey = {
            d: dLabel,
            nd: ndLabel,
            pnts: pntsLabel,
        }

        newList.push(
            {...dim,
                "idata": industryData,
                "domKey": domKey,
                "ndList": ndList,
                });
    }

    return newList;
}
