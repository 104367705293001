import {call, put} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import { loadAnalyticsCompanyList, 
    loadAnalyticsCompanyData,
    loadAnalyticsKpiMap,
    loadAnalyticsUserPortfolio,
    updateAnalyticsUserPortfolio,
    loadAnalyticsPortfolioCsv,
    loadAnalyticsPdfLink,
    loadIndexScores,
    reportInaccuracy
} from './network';
import {stringifyQueryParams} from "../utils";

export default [
    [ActionTypes.DO_UPDATE_ANALYTICS_USER_PORTFOLIO, doUpdateAnalyticsUserPortfolio],
    [ActionTypes.GET_ANALYTICS_USER_PORTFOLIO, getAnalyticsUserPortfolio],
    [ActionTypes.GET_ANALYTICS_COMPANY_LIST, getAnalyticsCompanyList],
    [ActionTypes.GET_ANALYTICS_KPI_MAP, getAnalyticsKpiMap],
    [ActionTypes.GET_ANALYTICS_COMPANY_DATA, getAnalyticsCompanyData],
    [ActionTypes.GET_ANALYTICS_COMPANIES_DATA, getAnalyticsCompaniesData],
    [ActionTypes.CLEAR_ANALYTICS_COMPANY_DATA, clearAnalyticsCompanyData],
    [ActionTypes.GET_ANALYTICS_PORTFOLIO_CSV, getAnalyticsPortfolioCsv],
    [ActionTypes.GET_ANALYTICS_PDF_LINK, getAnalyticsPdfLink],
    [ActionTypes.GET_INDEX_SCORES, getIndexScores],
    [ActionTypes.DO_REPORT_INACCURACY, doReportInaccuracy]
];

function createError(id, timestamp, httpStatusCode, httpResponseBody) {
    return {id, timestamp, httpStatusCode, httpResponseBody}
}

// Analytics
function* getAnalyticsCompanyList({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingAnalyticsCompanyList());
    const response = yield call(loadAnalyticsCompanyList);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedAnalyticsCompanyList(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingAnalyticsCompanyList(error));
    }
}

function* getAnalyticsKpiMap({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingAnalyticsKpiMap());
    const response = yield call(loadAnalyticsKpiMap);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedAnalyticsKpiMap(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingAnalyticsKpiMap(error));
    }
}

function* getAnalyticsCompanyData({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingAnalyticsCompanyData());
    const response = yield call(loadAnalyticsCompanyData, stringifyQueryParams(payload.data));
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedAnalyticsCompanyData(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingAnalyticsCompanyData(error));
    }
}

function* getAnalyticsCompaniesData({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingAnalyticsCompaniesData());
    const response = yield call(loadAnalyticsCompanyData, stringifyQueryParams(payload.data));
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedAnalyticsCompaniesData(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingAnalyticsCompaniesData(error));
    }
}

function* clearAnalyticsCompanyData() {
    yield put(Actions.clearAnalyticsCompanyData());
}

function* getAnalyticsUserPortfolio({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingAnalyticsUserPortfolio());
    const response = yield call(loadAnalyticsUserPortfolio);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedAnalyticsUserPortfolio(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingAnalyticsUserPortfolio(error));
    }
}

function* doUpdateAnalyticsUserPortfolio({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.doingUpdateAnalyticsUserPortfolio());
    const response = yield call(updateAnalyticsUserPortfolio, payload);
    if (response.successful) {
        yield put(Actions.doneUpdateAnalyticsUserPortfolio(response.data));
        // re-fetch portfolio after update
        yield call(getAnalyticsUserPortfolio,{requestId})
    } else {
        yield put(Actions.failedUpdateAnalyticsUserPortfolio({id: requestId}))
    }
}

function* getAnalyticsPortfolioCsv({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingAnalyticsPortfolioCsv());
    const response = yield call(loadAnalyticsPortfolioCsv);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedAnalyticsPortfolioCsv(response.data));
        // yield response;
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingAnalyticsPortfolioCsv(error));
        // yield null;
    }
}

function* getAnalyticsPdfLink({payload}) {
    /* Get pre-signed pdf link from S3 */
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingAnalyticsPdfLink());
    const response = yield call(loadAnalyticsPdfLink, stringifyQueryParams(payload.data));
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedAnalyticsPdfLink(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingAnalyticsPdfLink(error));
    }
}

function* getIndexScores() {
    yield put(Actions.loadingIndexScores());
    const response = yield call(loadIndexScores);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedIndexScores(response.data));
    } else {
        yield put(Actions.failedLoadingIndexScores(response.data?.error));
    }
}

function* doReportInaccuracy({ payload }) {
    yield put(Actions.doingReportInaccuracy());
    const response = yield call(reportInaccuracy, payload);
    if (response.successful && !response.data.error) {
        yield put(Actions.doneReportInaccuracy(response.data));
    } else {
        yield put(Actions.failedReportInaacuract(response.data?.error));
    }
}
