import {ActionTypes} from '../actions';

const defaultProfile = {
};

export default (profile= defaultProfile, action) => {
    try{
        if(action.type === ActionTypes.LOADED_PROFILE){
            return Object.assign({}, profile, action.payload);
        } else if (action.type === ActionTypes.DONE_UPDATE_PROFILE){
            return Object.assign({}, profile, action.payload);
        }
        return profile
    } catch (e){
        console.warn("Error in profile reducer");
        console.warn(e);
        return profile
    }
};
