import React, { useState, Component } from 'react';
import {
    ResponsiveContainer,
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';
import { DATA_COLOR, IDATA_COLOR, BAR, GRID } from './barColors';
import CustomizedAxisTick from '../../../utils/CustomizedAxisTick';
import './index.scss';

const legendFormatter = (value) => {
  return mapT(m.GROUP, value);
}

export const BChart = ({ reshapedData, activeIndex, onMouseMove }) => {
    return (
        <ResponsiveContainer width="100%" height={240} className="bchart">
            <BarChart layout="horizontal"
                      data={reshapedData}
                      barSize={BAR.size}
                      barGap={BAR.gap}
                      onMouseMove={onMouseMove}>
                 <Tooltip cursor={true} wrapperStyle={{ display: "none" }} />
                 <CartesianGrid
                    vertical={false}
                    stroke={GRID.line}
                    strokeWidth={GRID.lineWidth}
                />
                <YAxis type="number" 
                width={30} 
                fontSize="12" 
                tick={{fill: DATA_COLOR.min_thresh_stroke}} 
                tickCount={6}
                domain={[0,100]}
                allowDataOverflow={true}
                allowDecimals={false} dx={-8}/>
                <XAxis type="category" dataKey="type" interval={0} tick={<CustomizedAxisTick activeIndex={activeIndex} legendFormatter={legendFormatter}/>} dy={16}/>
                <Bar dataKey="data.d" fill={DATA_COLOR.dom} fillOpacity={IDATA_COLOR.fill_opacity} stackId="company" />
                <Bar dataKey="data.nd" fill={DATA_COLOR.nondom} fillOpacity={IDATA_COLOR.fill_opacity} stackId="company" />
                <Bar dataKey="data.pnts" fill={DATA_COLOR.pnts} fillOpacity={IDATA_COLOR.fill_opacity} stackId="company" />
                <Bar dataKey="data.th" fill={DATA_COLOR.min_thresh} fillOpacity={DATA_COLOR.fill_opacity}
                    stroke={DATA_COLOR.min_thresh_stroke} strokeOpacity={DATA_COLOR.min_thresh_stroke_opacity}
                    strokeWidth="2" strokeDasharray="4" stackId="company" />           
                <Bar dataKey="idata.d" fill={IDATA_COLOR.dom} fillOpacity={IDATA_COLOR.fill_opacity} stackId="industry" />
                <Bar dataKey="idata.nd" fill={IDATA_COLOR.nondom} fillOpacity={IDATA_COLOR.fill_opacity} stackId="industry" />
                <Bar dataKey="idata.pnts" fill={IDATA_COLOR.pnts} fillOpacity={IDATA_COLOR.fill_opacity} stackId="industry" />
                <Bar dataKey="idata.th" fill={IDATA_COLOR.min_thresh} fillOpacity={IDATA_COLOR.fill_opacity}
                    stroke={IDATA_COLOR.min_thresh_stroke} strokeOpacity={IDATA_COLOR.min_thresh_stroke_opacity}
                    strokeWidth="2" strokeDasharray="4" stackId="industry" />
            </BarChart>
        </ResponsiveContainer>
    ) 
}
