import React, { useState, useEffect } from 'react';
import { Nav, Tab, Row, Col, Dropdown } from 'react-bootstrap';
import './index.scss';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Button} from "react-bootstrap";
import _ from 'underscore';
import {getDepartmentLabel, getOfficeRegionLabel} from "../../../../utils/labels";
import { withTranslation } from 'react-i18next';
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';

const Filters = ({ company, filters, applyFilters, onChange, t }) => {
    const [dataMap] = useState({
        'Year': 'years',
        'Office': 'offices',
        'Level': 'levels',
        'Department': 'departments',
        'Tenure': 'tenures',
        'Kpis': 'kpis',
        'Group': 'groups'
    });
    const [valMap] = useState({
        'Year': 'year',
        'Office': 'office',
        'Level': 'level',
        'Department': 'department',
        'Tenure': 'tenure',
        'Kpis': 'kpi',
        'Group': 'group'
    });
    const ogState = {
        office: [],
        level: [],
        department: [],
        group_a: ["genders:Male"],
        group_b: ["genders:Female"]
    };
    const [values, setValues] = useState(ogState);

    useEffect(() => {
        onChange(values);
    }, []);

    const _clearFilters = () => {
        setValues(ogState);
        onChange(ogState);
        setTimeout(() => { applyFilters({ ogState }) }, 0)
    };

    const _setValues = (key, val) => {
        let clonedValues = {};
        if (_.indexOf(values[key], val) > -1) {
            clonedValues = {...values, [key]: _.without(values[key], val)};
        } else {
            clonedValues = {...values, [key]: [...values[key], val] };
        }

        setValues(clonedValues);
        onChange(clonedValues);
    };

    const _getCheckbox = (name, label, checked, onChange) => {
        return (
            <>
                <Dropdown.ItemText as={"span"}>
                    <FormControlLabel
                        className={"option-label"}
                        control={
                            <Checkbox
                                color={"primary"}
                                name={name}
                                checked={checked}
                                onChange={onChange}
                            />
                        }
                        label={mapT(m.GROUP_BREAKDOWN, label)}
                    />
                </Dropdown.ItemText>
            </>
        )
    };

    return (
        <div id={"inclusion_breakdown-filters__container"}>
            <div className={"filters-container"}>
                <div className={"filter-actions"}>
                    <p>{t("ANALYZE.INCLUSION_BREAKDOWN.FILTERS_TITLE")}</p>
                    <div className={"filter-actions-btns"}>
                        <Button variant={"primary"} className={"apply-filter"} onClick={() => applyFilters({ ...values })}>{t("ANALYZE.FILTERS.SUBMIT")}</Button>
                        <Button variant={"light"} className={"clear-filter"} onClick={_clearFilters}>{t("ANALYZE.FILTERS.CLEAR")}</Button>
                    </div>
                </div>
            </div>
            <div className={"inclusion_breakdown-filters"}>
                <Tab.Container defaultActiveKey="group_a">
                    <Nav>
                        <Nav.Item>
                            <Nav.Link eventKey="group_a">{t("ANALYZE.FILTERS.GROUP_A")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="group_b">{t("ANALYZE.FILTERS.GROUP_B")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="office">{getOfficeRegionLabel(company)}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="level">{t("ANALYZE.FILTERS.LEVEL")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="department">{getDepartmentLabel(company)}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="group_a">
                            <Row>
                                {
                                    _.keys(filters.groups).map((group, i) => {
                                        return (
                                            <Col key={i} className={"options-column"}>
                                                <Dropdown>
                                                    <Dropdown.Toggle as={"h6"} id={`dropdown-group-a-${i}`}>
                                                        {filters.metadata.groups[group]}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {
                                                            filters.groups[group].map((option, i) => {
                                                                return _getCheckbox(group, option,
                                                                    values.group_a.indexOf(`${group}:${option}`) > -1,
                                                                    () => _setValues("group_a", `${group}:${option}`))
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="group_b">
                            <Row>
                            {
                                _.keys(filters.groups).map((group, i) => {
                                    return (
                                        <Col key={i} className={"options-column"}>
                                            <Dropdown>
                                                <Dropdown.Toggle as={"h6"} id={`dropdown-group-b-${i}`}>
                                                    {filters.metadata.groups[group]}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {
                                                        filters.groups[group].map((option, i) => {
                                                            return _getCheckbox(group, option,
                                                                values.group_b.indexOf(`${group}:${option}`) > -1,
                                                                () => _setValues("group_b", `${group}:${option}`))
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    )
                                })
                            }
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="office">
                            <Row>
                                <Col className={"options-column"}>
                                    <Dropdown>
                                        <Dropdown.Toggle as={"h6"} id={`dropdown-office`}>
                                            {getOfficeRegionLabel(company)}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {
                                                filters.offices.map((office, i) => _getCheckbox("office", office,
                                                    values.office.indexOf(office) > -1,
                                                    () => _setValues("office", office)))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="level">
                            <Row>
                                <Col className={"options-column"}>
                                    <Dropdown>
                                        <Dropdown.Toggle as={"h6"} id={`dropdown-level`}>
                                            {t("ANALYZE.FILTERS.LEVEL")}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {
                                                filters.levels.map((level, i) => _getCheckbox("level", level,
                                                    values.level.indexOf(level) > -1,
                                                    () => _setValues("level", level)))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="department">
                            <Row>
                                <Col className={"options-column"}>
                                    <Dropdown>
                                        <Dropdown.Toggle as={"h6"} id={`dropdown-department`}>
                                            {getDepartmentLabel(company)}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {
                                                filters.departments.map((department, i) => _getCheckbox("department", department,
                                                    values.department.indexOf(department) > -1,
                                                    () => _setValues("department", department)))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};

export default withTranslation()(Filters);
