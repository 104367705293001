import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import history from "./history";
import PrivateRoute from './containers/PrivateRoute';
import AdminRoute from './containers/AdminRoute';
import Home from './containers/Home';
import Analyze from './containers/Analyze';
import Analytics from './containers/Analytics';
import Improve from './containers/Improve';
import UploadCSV from './containers/UploadCSV';
import DavosPDF from './containers/DavosPDF';
import Portfolio from './containers/PortfolioHome';
import Connect from './containers/Connect';
import LSO from "./containers/LSO";
import Account from "./containers/Account";
import Communications from './containers/Communications';
import DiversityVC from "./containers/DiversityVC/DiversityVC";
import Oauth from './components/Oauth';
import SurveyContainer from "./containers/Survey";
import ILNHome from './containers/ILNDashboard/ILNHome';
import ILNImpact from './containers/ILNDashboard/ILNImpact';

// Map from Permission tag to appropriate URL (these match exactly what BE company_information api returns)
export const urlMap = {
    home: '/home',
    analyze: '/analyze',
    analytics: '/analytics',
    // improve: '/improve',
    // diversity_vc: '/diversity_vc',
    // connect: '/connect',
    // lso: '/overview',
    // portfolio: '/portfolio',
    // voyager: '/communications',
    // survey: '/survey',
    // iln_dashboard_home: '/iln-dashboard/home',
    // iln_dashboard_impact: '/iln-dashboard/impact'
};

const DashboardRoutes = ({ permissions }) => {
    return (
        <div className={"content-container"}>
            <Router history={history}>
                <Switch>
                    <PrivateRoute key="home" exact path={"/"} isActive={true} permissions={permissions} component={permissions.analytics ? Analytics : Home}/>
                    <PrivateRoute key="home" exact path={urlMap.home} isActive={permissions.home} permissions={permissions} component={Home}/>
                    <PrivateRoute key="analyze" path={urlMap.analyze} isActive={permissions.analyze} permissions={permissions} component={Analyze}/>
                    <PrivateRoute key="analytics" path={urlMap.analytics} isActive={permissions.analytics} permissions={permissions} component={Analytics}/>
                    {/* <PrivateRoute key="inclusion" path={urlMap.analyze} isActive={permissions.analyze} permissions={permissions} component={Analyze}/>
                    <PrivateRoute key="improve" path={urlMap.improve} isActive={permissions.improve} permissions={permissions}  component={Improve}/>
                    <PrivateRoute key={'diversity_vc'} path={urlMap.diversity_vc} isActive={permissions.diversity_vc} permissions={permissions}  component={DiversityVC}/>
                    <PrivateRoute key="connect" path={urlMap.connect} isActive={permissions.connect} permissions={permissions}  component={Connect}/>
                    <PrivateRoute key="lso" path={urlMap.lso} isActive={permissions.lso} permissions={permissions}  component={LSO}/>
                    <PrivateRoute key="portfolio"  exact path={urlMap.portfolio} isActive={permissions.portfolio} permissions={permissions}  component={Portfolio}/>
                    <PrivateRoute key={'voyager'} exact path={urlMap.voyager} isActive={permissions.voyager} permissions={permissions}  component={Communications}/> */}
                    <PrivateRoute path='/oauth' permissions={permissions} component={Oauth}/>
                    <PrivateRoute path='/account' permissions={permissions} component={Account}/>
                    {/* <PrivateRoute path='/survey' permissions={permissions} isActive component={SurveyContainer}/> */}
                    <AdminRoute path="/admin/upload" component={UploadCSV}/>
                    {/* <PrivateRoute exact path='/davos/pdf' permissions={permissions} component={DavosPDF}/> */}
                    {/* <PrivateRoute exact path="/iln-dashboard/home" permissions={permissions} component={ILNHome} /> */}
                    {/* <PrivateRoute exact path="/iln-dashboard/impact" permissions={permissions} component={ILNImpact} /> */}
                </Switch>
            </Router>
        </div>
    )
};

export default DashboardRoutes;
