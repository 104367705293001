import React, {Component} from 'react';
import './index.scss'
import {Link, Route, Redirect} from "react-router-dom";
import Profile from './Profile'
import Password from "./Password";
import Tokens from "./Tokens";
import {Row, Column} from "../utils/FlexBox";
import Card from '../utils/Card'

const tabMap = {
    'profile': {component: Profile, name: "Edit Profile"},
    'password': {component: Password, name: "Password"},
    // 'notifications': {component: Notifications, name: "Notifications"},
};

class Account extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getCompanyInformation();
        // this.props.getProfile();
    }

    getLinkComponent(name, path) {

        return (
            <li key={path} className={this.props.location.pathname === path ? 'selected' : null}>
                <Link to={path}>{name}</Link>
            </li>
        )
    }

    getTabMap(){
        const tabs = {
            // 'profile': {component: Profile, name: "Edit Profile"},
            'password': {component: Password, name: "Password"},
            // 'notifications': {component: Notifications, name: "Notifications"},
        };
        // if(this.props.company_permission.voyager){
        //     tabs.tokens = {component:Tokens, name:"Tokens"}
        // }
        return tabs
    }


    render() {
        if (this.props.company_permission === undefined) {
            return null;
        }
        const tabs = this.getTabMap();
        const components = Object.keys(tabs).map(relativePath => {
            const section = tabs[relativePath];
            const path = `${this.props.match.path}/${relativePath}`;
            const Component = section.component;
            return {
                link: this.getLinkComponent(section.name, path),
                route: <Route exact key={`${path}_r`} path={path}
                              component={props => <Component {...this.props} {...props}/>}/>
            }
        });

        return (
            <div id={'account'}>
                <h1>Account Settings</h1>
                <Row>
                    <Column width={450}>
                        <Card>
                            <div>
                                <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 10C4 11.11 3.11 12 2 12C1.60444 12 1.21776 11.8827 0.88886 11.6629C0.559962 11.4432 0.303617 11.1308 0.152242 10.7654C0.000866562 10.3999 -0.0387401 9.99778 0.0384303 9.60982C0.115601 9.22186 0.306082 8.86549 0.585787 8.58579C0.865492 8.30608 1.22186 8.1156 1.60982 8.03843C1.99778 7.96126 2.39992 8.00087 2.76537 8.15224C3.13082 8.30362 3.44318 8.55996 3.66294 8.88886C3.8827 9.21776 4 9.60444 4 10ZM3 0V6H1V0H3ZM1 20V14H3V20H1ZM23 4V16C23 17.11 22.11 18 21 18H9C8.46957 18 7.96086 17.7893 7.58579 17.4142C7.21071 17.0391 7 16.5304 7 16V12L5 10L7 8V4C7 3.46957 7.21071 2.96086 7.58579 2.58579C7.96086 2.21071 8.46957 2 9 2H21C22.11 2 23 2.89 23 4ZM21 4H9V8.83L7.83 10L9 11.17V16H21V4ZM11 7H19V9H11V7ZM11 11H17V13H11V11Z" fill="black" fillOpacity="0.54"/>
                                </svg>
                                <h2 style={{display:'inline-block',marginLeft:'10px'}}>Activity</h2>
                            </div>
                            <ul className={'navbar_list'}>
                                {components.map(x => x.link)}
                            </ul>
                        </Card>
                    </Column>
                    <Column width={'100%'}>
                        <Card>
                            <div className={'content'}>
                                {components.map(x => x.route)}
                            </div>
                        </Card>
                    </Column>
                </Row>
                <Route exact path={this.props.match.path} component={() => <Redirect
                    to={{pathname: `${this.props.match.path}/${Object.keys(tabs)[0]}`}}/>}/>
            </div>
        );
    }
}

export default Account;
