// Actions are payloads of informations that are used to modify the
// application's Redux state. They may also act as signals and information
// payloads to sagas when asynchronous operations need to occur. This app uses
// [Flux Standard Actions](https://github.com/acdlite/flux-standard-action) to
// make sure actions have a uniform schema.
//
// http://redux.js.org/docs/basics/Actions.html
import { createAction } from 'redux-actions';
import { upperSnakeCase } from './utils';
import camelCase from 'lodash/camelCase';

// The action type names for this app's Redux/Flux actions. Used to generate
// `ActionTypes` and `Actions` used throughout the app.
// actionName -> ActionType ('action name' -> 'ACTION_NAME')
// actionName -> ActionCreator ('action name' -> 'actionName')
let actionNames = normalize(
    // Data load resource actions
    ...loadAll(
        'questions',
        'inclusion breakdown data',
        'diversity breakdown data',
        'employee overview data',
        'inclusion metrics data',
        'non overview metrics data',
        'exec summary data',
        'email response',
        'painpoint data',
        'diversity composition data',
        'metric overview data',
        'focus area data',
        'inclusion score data',
        'company filters',
        'targets',
        'recommendations',
        'action plans',
        'company information',
        'target options',
        'road map checklist',
        'inclusion tracker data',
        'diversity tracker data',
        'diversity breakdown by role data',
        'road map checklist',
        'free text',
        'profile',
        'all posts',
        'comments',
        'bookmark',
        'filtered posts',
        'all kpis',
        'filter map',
        'all solutions',
        'one pager',
        'communications tokens',
        'communications slack analytics',
        'communications github analytics',
        'diversityvc score',
        'diversityvc benchmark',
        'diversityvc goal',
        'analytics company list',
        'analytics kpi map',
        'analytics company data',
        'analytics companies data',
        'analytics user portfolio',
        'analytics portfolio csv',
        'analytics pdf link',
        'core surveys',
        'all surveys',
        'iln members',
        'iln milestones',
        'iln initiatives',
        'iln investment regions',
        'iln investment class',
        'iln news',
        'index scores'
    ),
    ...doAll(
        'submit response',
        'save email',
        'login',
        'registration',
        'refresh token',
        'upload csv',
        'deploy survey',
        'apply target',
        'signout',
        'create action plan',
        'inform purchase',
        'delete action plan',
        'delete target',
        'update road map checklist',
        'update profile',
        'upload profile image',
        'create post',
        'edit post',
        'delete post',
        'create comment',
        'edit comment',
        'delete comment',
        'like post',
        'like comment',
        'update bookmark',
        'update target',
        'update company solutions',
        'delete solution',
        'update budget',
        'create new solution',
        'create new company solution',
        'send onepager ping',
        'change password',
        'request reset password',
        'reset password',
        'update communications tokens',
        'delete communications tokens',
        'create diversityvc goal',
        'update diversityvc goal',
        'delete diversityvc goal',
        'update analytics user portfolio',
        'save survey info',
        'save surveys',
        'edit surveys',
        'clear company information',
        'report inaccuracy'
    ),
    'initialize app',
    'set survey completed',
    'mark solution selected',
    'unmark solution selected',
    'add target',
    'toggle targets is committed',
    'show message',
    'set global message',
    'reset global message',
    'set survey completed',
    'handle email change',
    'handle field change',
    'reset auth',
    'request reset password fail',
    'reset password fail',
    'clear analytics company data',
    'clear analytics pdf link',
    'update survey questions',
    'set selected survey',
    'set current selected survey page',
    'handle survey reset',
    'reset inaccuracy state'
);

// Create multiple load action types (get, loading and loading)
function loadAll (...names) {
    return [].concat(...names.map(load));
}

// Create multiple load action types (do, doing and done)
function doAll(...names) {
    return [].concat(...names.map(doit))
}

// Create load action type variants with do, doing and done prefixed
function doit (resourceName) {
    return [
        `do ${resourceName}`,
        `doing ${resourceName}`,
        `done ${resourceName}`,
        `failed ${resourceName}`
    ];
}

// Create load action type variants with get, loading and loading prefixed
function load (resourceName) {
    return [
        `get ${resourceName}`,
        `loading ${resourceName}`,
        `loaded ${resourceName}`,
        `failed loading ${resourceName}`
    ];
}

// Uper snake case names
function normalize (...names) {
    return names.map(upperSnakeCase);
}

// The app's redux action types. Each key is the same as the string action type.
// For each action name, the type is generated by upper snake-casing the phrase.
// assert.equal(ActionTypes.EAT_CAKE, 'EAT_CAKE');
export const ActionTypes = actionNames.reduce((actionTypes, actionName) => {
    actionTypes[actionName] = actionName;
    return actionTypes;
}, {});

// Action creators for the app (functions that return Redux/Flux actions).
// let action = Actions.wingardiumLeviosa('ferret');
// assert.deepEqual(action, { type: 'WINGARDIUM_LEVIOSA', payload: 'ferret' });
export const Actions = actionNames.reduce((actions, actionName) => {
    let actionCreatorName = camelCase(actionName);
    actions[actionCreatorName] = createAction(actionName, x => x, (x, y) => y);
    return actions;
}, {});
