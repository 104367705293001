import React from 'react';
import './index.scss';
import _ from 'underscore';


const CompanyInfo = (props) => {
	let {
		industry,
		indices
	} = props;

	if (!industry) {
		// fallback
		industry = "None specified";
	}

	if (_.isEmpty(indices)) {
		// fallback
		indices = ["None specified"];
	}
    
    return (
		<>
        <div className="companyInfo">
            <div className="companyInfo__industry-wrap">
				<div className="companyInfo__header">
				Industry
				</div>
				<div className="companyInfo__bubble-wrapper">
					<div className="companyInfo__bubble">
						{industry}
					</div>
				</div>
			</div>
            <div className="companyInfo__index-wrap">
				<div className="companyInfo__header">
				Index
				</div>
				<div className="companyInfo__bubble-wrapper">
					{indices.map(i => {
						return (
							<div className="companyInfo__bubble" key={i}>
								{i}
							</div>
						);
					})}
				</div>
			</div>
        </div>
		</>
    );
}

export default CompanyInfo;
