import React from 'react';
import {Column, Row} from "../utils/FlexBox";
import DiversitySnapshot from "./DiversitySnapshot";
import DiversityRoleBreakdown from "./DiversityRoleBreakdown";

const Diversity = (props) => {
    return (
        <div>
            <Row>
                <Column grow={1}>
                    <DiversitySnapshot colors={props.colors}
                                       ndg={props.analyze.filters.non_dominant_group}
                                       composition={props.analyze.diversityComposition}
                                       breakdown={props.analyze.diversityBreakdown}
                                       groups={props.extendedCategories}
                                       companyName={props.home.companyInformation.name}
                                       filters={props.analyze.filters}
                                       handleApplyFilters={data => props.getDiversityBreakdownData({ data })}
                                       showMessage={props.showMessage}
                                       profile={props.profile}
                                       />
                </Column>
            </Row>
            <Row>
                <Column grow={1}>
                    <DiversityRoleBreakdown 
                        diversityBreakdownByRole={props.analyze.diversityBreakdownByRole}
                        companyName={props.home.companyInformation.name}
                        filters={props.analyze.filters}
                        handleApplyFilters={data => props.getDiversityBreakdownByRoleData({ data })}
                        />
                </Column>
            </Row>
        </div>
    );
};

export default Diversity;
