import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {Form} from 'react-bootstrap';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import _ from 'underscore';
import classNames from 'classnames';
import "./report-inaccuracy.scss";

const steps = ["type", "details", "submitted"];
const industries = [
    "Board Diversity",
    "Executive Diversity",
    "Commitment Diversity",
    "Healthcare",
    "Industrial"
]

export default function ReportInaccuracy({isInaccuracySubmitted, isInaccuracySubmitting, reportInaccuracy, handleClose, resetInaccuracy}) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedTypes, setSelectedTypes] = React.useState([]);
    const [details, setDetails] = React.useState("");

    const handleNext = () => {
        if (activeStep === 2) {
            resetInaccuracy()
            handleClose();
        }
        if (activeStep === 1) {
            reportInaccuracy({ details, types: selectedTypes.join(", ") });
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    React.useEffect(() => {
        if (isInaccuracySubmitted) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }, [isInaccuracySubmitted])

    return (
        <div className="report-inaccuracy-content">
            {
                activeStep === 0 && <div className="report-inaccuracy-step industry">
                    {
                        industries.map(industry => <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedTypes.indexOf(industry) > -1}
                                    onChange={(e) => {
                                        e.target.checked
                                            ? setSelectedTypes([...selectedTypes, industry])
                                            : setSelectedTypes(_.without(selectedTypes, industry))}
                                    }
                                    name="industry"
                                    color="primary"
                                />
                            }
                            label={industry}
                        />)
                    }
                </div>
            }
            {
                activeStep === 1 && <div className="report-inaccuracy-step details">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Explain issue in detail</Form.Label>
                        <Form.Control as="textarea" rows={6} value={details} onChange={e => setDetails(e.target.value)}/>
                    </Form.Group>
                </div>
            }
            {
                activeStep === 2 && <div className="report-inaccuracy-step submitted">
                    <img src="/images/survey-submitted.svg"/>
                    <h3>Your Response has Been Submitted!</h3>
                    <p>Keep an eye on your inbox for updates from a Diversio representative.</p>
                </div>
            }
            <hr/>
            <div className="dialog-actions">
                {
                    activeStep !== 2 && <Button disabled={activeStep === 0 || isInaccuracySubmitting} onClick={handleBack}>
                        Back
                    </Button>
                }
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={isInaccuracySubmitting}
                >
                    {activeStep === steps.length - 1 ? 'Close' : 'Next'}
                </Button>
            </div>
        </div>
    );
}